import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { batch, useDispatch, useSelector } from 'react-redux';
import { openPopupWidget } from 'react-calendly';
import ResizeObserver from 'react-resize-observer';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import PropTypes from 'prop-types';
import corePackage from './components/presentational/packages';
import wearables from '../../shared/images/ss-hub/Wearables_gray.png';
import loadMonitoring from '../../shared/images/ss-hub/Load-monitoring_gray.png';
import sportCoach from '../../shared/images/ss-hub/Sports-coach_gray.png';
import recoveryRadar from '../../shared/images/ss-hub/Radar_gray.png';
import habitTrack from '../../shared/images/ss-hub/Consistency_gray.png';
import forcePlate from '../../shared/images/ss-hub/Force-plate_gray.png';
import ssBanner from '../../shared/images/ss-hub/ss-banner.png';
import bhmOnboardingImageOne from '../../shared/images/rr-onboarding-one.png';
import bhmOnboardingImageTwo from '../../shared/images/rr-onboarding-two.png';
import bhmOnboardingImageThree from '../../shared/images/rr-onboarding-three.png';
import bhmOnboardingImageFour from '../../shared/images/rr-onboarding-four.png';
import bhmOnboardingImageFive from '../../shared/images/rr-onboarding-five.png';
import htOnboardingImageOne from '../../shared/images/ht-onboarding-one.png';
import htOnboardingImageTwo from '../../shared/images/ht-onboarding-two.png';
import htOnboardingImageThree from '../../shared/images/ht-onboarding-three.png';
import htOnboardingImageFour from '../../shared/images/ht-onboarding-four.png';
import ModuleCardUnlocked from './components/presentational/ModuleCardUnlocked';
import ModuleCardLocked from './components/presentational/ModuleCardLocked';
import {
  fetchSubscriptionPackage,
  setIsLearnMoreModalShowing,
  setIsUpgradeModalShowing,
  setSelectedPackage,
} from './ducks/sportsScienceActions';
import {
  subscriptionFlowTracker,
  subscriptionFlowTrackerDemo,
} from '../../shared/utils/amplitudeHelper';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 44px 52px;
  width: 100%;
  @media screen and (max-width: 767px) {
    margin-bottom: 44px;
  }
`;

const InfoBanner = styled('div')`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${(props) => (props.unlocked ? '40px 52px' : '56px 52px')};
  background-image: linear-gradient(to right, #1e212e, transparent), url(${ssBanner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  gap: ${(props) => (props.unlocked ? '39px' : '32px')};
`;

const BannerHeader = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  max-width: 772px;
  .banner-title {
    font-size: ${(props) => (props.unlocked || props.containerWidth < 488 ? '34px' : '44px')};
    line-height: 150%;
    letter-spacing: ${(props) => (props.unlocked ? '0.72px' : '-0.444px')};
    font-weight: ${(props) => (props.unlocked || props.containerWidth < 488 ? 700 : 900)};
  }
  .banner-description {
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;

const BannerButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;

const LearnMoreButton = styled('button')`
  background: linear-gradient(270deg, #fc4a1a 0%, #f7b733 100%);
  color: #ffffff;
  border-radius: 10000px;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.12);
  border: none;
  height: 40px;
  min-width: 64px;
  padding: 0px 20px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 900;
  line-height: 16px;
  letter-spacing: 0.39px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const BookDemoButton = styled('button')`
  position: relative;
  background: #ffffff;
  color: #444444;
  border-radius: 10000px;
  border: none;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.12);
  height: 40px;
  padding: 0px 20px;
  min-width: 64px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 900;
  line-height: 16px;
  letter-spacing: 0.39px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex: 1 0 auto;
  z-index: 1;

  &:hover {
    opacity: 0.7;
  }

  /* Pseudo-element for gradient border inside the button's total size */
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: 3px;
    background: linear-gradient(270deg, #fc4a1a 0%, #f7b733 100%);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    z-index: -1;
  }
`;

const CardSection = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

const SectionHeaderAndDescription = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CardSectionHeader = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  .section-title {
    font-feature-settings: 'liga' off, 'clig' off;
    color: #444444;
    font-family: 'Nunito Sans';
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 29.9px */
    letter-spacing: -0.23px;
    margin-bottom: ${(props) => (props.containerWidth < 580 ? '12px' : '0')};
    margin-right: 32px;
  }
`;

const PurchasePackageButton = styled('button')`
  background: ${(props) => props.bgColor || '#444444'};
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10000px;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.12);
  border: none;
  color: #ffffff;
  font-family: 'Nunito Sans';
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const CardSectionDescription = styled('div')`
  display: flex;
  padding: 12px;
  gap: 32px;
  align-self: stretch;
  align-items: center;
  font-family: 'Nunito Sans';
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 28.5px */
  font-feature-settings: 'liga' off, 'clig' off;
  color: #444444;
  width: fit-content;
  background-color: #ffffff;
  padding: 12px;
  border-radius: 8px;
`;

const ModuleCards = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  gap: 20px;
  flex-wrap: wrap;
`;

const SectionDivider = styled('div')`
  width: 100%;
  height: 2px;
  align-self: stretch;
  border-radius: 10000px;
  background: #e0e0e0;
  margin: 44px 0;
`;

const CardGroup = ({
  title,
  purchaseButton = '',
  purchaseClickFunction = () => {},
  gradient = '',
  description = '',
  cards,
  containerWidth,
}) => (
  <CardSection>
    <SectionHeaderAndDescription>
      <CardSectionHeader containerWidth={containerWidth}>
        <div className='section-title'>{title}</div>
        {purchaseButton && (
          <PurchasePackageButton
            bgColor={gradient}
            onClick={purchaseClickFunction}
          >
            {purchaseButton}
          </PurchasePackageButton>
        )}
      </CardSectionHeader>
      {description && containerWidth > 700 && (
        <CardSectionDescription>{description}</CardSectionDescription>
      )}
    </SectionHeaderAndDescription>

    {cards.length > 0 && (
      <ModuleCards>
        {cards.map((card) => (card.unlocked ? (
          <ModuleCardUnlocked key={card.id} cardData={card} />
        ) : (
          <ModuleCardLocked
            key={card.id}
            cardData={card}
            purchaseButton={card.subscriptionName === 'Add On'}
            gradient={gradient}
          />
        )))}
      </ModuleCards>
    )}
  </CardSection>
);

CardGroup.propTypes = {
  title: PropTypes.string.isRequired,
  purchaseButton: PropTypes.string,
  purchaseClickFunction: PropTypes.func,
  gradient: PropTypes.string,
  description: PropTypes.string,
  cards: PropTypes.instanceOf(Array).isRequired,
  containerWidth: PropTypes.number.isRequired,
};

CardGroup.defaultProps = {
  purchaseButton: '',
  purchaseClickFunction: () => {},
  gradient: '',
  description: '',
};

const SportScienceHome = ({ isSideBarActive, flags }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const subscriptionStatus = useSelector(
    (state) => state.sportsScience.data.subscriptionStatus,
  );
  const isSideBarOpen = useSelector(
    (state) => state.sportsScience.data.isSideBarOpen,
  );
  const isSideBarExpanded = useSelector(
    (state) => state.sportsScience.data.isSideBarExpanded,
  );
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    const containerElement = containerRef.current;
    if (containerElement) {
      setTimeout(() => {
        setContainerWidth(containerElement.offsetWidth);
      }, 300);
    }
  }, [isSideBarOpen, isSideBarActive, isSideBarExpanded]);

  const coreSubscriptionUnlocked = !!subscriptionStatus
    ?.packageSubscriptionStatus?.isSubscribed;

  const addOnUnlocked = (addOnModuleId) => !!subscriptionStatus?.addOnsSubscriptionStatus?.some(
    (addOn) => !!addOn.isSubscribed && addOn.addOnId === addOnModuleId,
  );

  const moduleCardData = [
    {
      id: 1,
      title: 'Wearables Dashboard',
      legacyTitle: '',
      core: false,
      unlocked: true,
      description:
        'Quickly review biometric data collected by athlete wearables',
      icon: 'watch-heart-rate',
      activeTab: '/ams-lite/wearables-dashboard',
      backgroundImage: wearables,
      tagColor: '#444444',
      textColor: '#ffffff',
      circleColor: '#9e9e9e',
      subscriptionName: 'Included in your plan',
    },
    {
      id: 2,
      title: 'Load Monitoring Dashboard',
      legacyTitle: '',
      core: true,
      unlocked: !!coreSubscriptionUnlocked,
      sections: [
        {
          name: 'Monitoring',
          description:
            'Generate insights based on athlete workload and self-reported questionnaire data.',
          icon: 'chart-line',
        },
        {
          name: 'Readiness',
          description:
            'Determine athlete readiness by viewing data regarding sleep, fatigue, motivation, stress levels, and more.',
          icon: 'heart-rate',
        },
        {
          name: 'Recovery',
          description:
            'See how your athletes are recovering from competition and training sessions.',
          icon: 'circuit',
        },
      ],
      videoUrl: 'https://www.youtube.com/watch?v=soAvDS3obS0',
      description:
        'Compare athlete weight room load to questionnaire responses',
      icon: 'chart-line',
      activeTab: '/ams-lite/load-monitoring-dashboard',
      backgroundImage: loadMonitoring,
      tagColor: ' rgba(255, 133, 51, 0.40)',
      textColor: '#CC5200',
      circleColor: '#FF8533',
      subscriptionName: 'AMS Lite Core',
    },
    {
      id: 3,
      title: 'KPI Tracker',
      legacyTitle: 'Sport Coach Dashboard',
      core: true,
      unlocked: !!coreSubscriptionUnlocked,
      sections: [
        {
          name: 'Collaborate',
          description:
            'Share individual athlete workout data with Sport Coaches so they can see how their athletes are progressing.',
          icon: 'fist-bump-full-together',
        },
        {
          name: 'Monitor Progress',
          description:
            'Sport & Performance coaches can easily see when athletes hit a new max or personal best.',
          icon: 'clipboard',
        },
        {
          name: 'Team Trends',
          description:
            'See how each athlete is developing compared to the rest of the team.',
          icon: 'group',
        },
      ],
      videoUrl: 'https://www.youtube.com/watch?v=ZRtmL63e62c',
      description:
        'Group-based athlete performance report in an easy to share format',
      icon: 'clipboard',
      activeTab: '/ams-lite/kpi-tracker',
      backgroundImage: sportCoach,
      tagColor: ' rgba(255, 133, 51, 0.40)',
      textColor: '#CC5200',
      circleColor: '#FF8533',
      subscriptionName: 'AMS Lite Core',
    },
    ...(flags.bodyHeatMaps
      ? [
        {
          id: 4,
          title: 'Body Heat Map',
          legacyTitle: 'Recovery Radar',
          core: true,
          unlocked: !!coreSubscriptionUnlocked,
          sections: [
            {
              name: 'Collaborate',
              description:
                  'Share individual athlete workout data with Sport Coaches so they can see how their athletes are progressing.',
              icon: 'fist-bump-full-together',
            },
            {
              name: 'Monitor Progress',
              description:
                  'Sport & Performance coaches can easily see when athletes hit a new max or personal best.',
              icon: 'clipboard',
            },
            {
              name: 'Team Trends',
              description:
                  'See how each athlete is developing compared to the rest of the team.',
              icon: 'group',
            },
          ],
          content: [
            {
              videoUrl: 'https://www.youtube.com/watch?v=iJbmYLH90PE&t=161s',
              image: bhmOnboardingImageOne,
              title: 'Accessing Body Heat Map',
              description:
                  'Welcome to Body Heat Map, where athletes can log any pain or soreness they experience, enabling coaches to monitor needed adjustments. To get started, athletes log data on the TeamBuildr mobile app by navigating to Body Heat Map in the navigation drawer or by selecting “More” in the Workout Entry screen.',
              gradientType: 'primary',
            },
            {
              image: bhmOnboardingImageTwo,
              title: 'Reporting and Tracking Pain and Soreness',
              description:
                  'To log pain or soreness, athletes open Body Heat Map on the mobile app. On the body heat map, athletes tap a specific area to report, selecting either Pain or Soreness and choosing a severity level (1 to 7). Optional fields like Region and Status allow athletes to add context for coaches. Each entry saves automatically in the Areas Selected section, building a structured record that coaches can view and analyze.',
              gradientType: 'primary',
            },
            {
              image: bhmOnboardingImageThree,
              title: 'Overview of Recovery Data',
              description:
                  'In the Overview tab, coaches can view a high-level summary of pain and soreness data at a group and individual level. To begin, select a group from the dropdown menu in the top left corner. Use the date selector in the top right to choose a 7-day or 28-day range, showing trends for each body part. A line graph tracks changes over time, while a body map highlights frequently reported areas. Below, quick stat cards display Total Reported Items, Average Severity, and the number of Athletes to Review.',
              gradientType: 'primary',
            },
            {
              image: bhmOnboardingImageFour,
              title: 'Analyzing Athlete Recovery',
              description:
                  'To review individual reports, open the Group Analysis tab and select a group from the dropdown menu. The table shows each athlete’s reported body part, severity, 28-day average, and any notes. For a detailed view, click on an athlete’s name to open the Athlete History tab, which displays their body heat map and a list of reported issues over time.',
              gradientType: 'primary',
            },
            {
              image: bhmOnboardingImageFive,
              title: 'Athlete Alerts',
              description:
                  'Athlete Alerts help coaches quickly identify athletes with significant increases in average severity, allowing them to prioritize athletes needing immediate attention. Click "Review" in the red alert banner to filter these athletes into the Group Analysis table for closer monitoring.',
              gradientType: 'primary',
            },
          ],
          videoUrl: 'https://www.youtube.com/watch?v=iJbmYLH90PE&t=161s',
          description: 'Monitor athletes experiencing any pain or soreness',
          icon: 'target',
          activeTab: '/ams-lite/body-heat-map',
          backgroundImage: recoveryRadar,
          tagColor: ' rgba(255, 133, 51, 0.40)',
          textColor: '#CC5200',
          circleColor: '#FF8533',
          subscriptionName: 'AMS Lite Core',
        },
      ]
      : []),
    ...(flags.habitTracking
      ? [
        {
          id: 5,
          title: 'Habit Tracker',
          legacyTitle: 'Consistency Coach',
          core: true,
          unlocked: !!coreSubscriptionUnlocked,
          sections: [
            {
              name: 'Create Consistency',
              description:
                  'Create, manage, and assign custom habits for athletes to complete throughout the day or week.',
              icon: 'checkmark',
            },
            {
              name: 'Habit Monitoring',
              description:
                  'Keep an eye on daily and weekly progress as athletes check off habits and log data into their app.',
              icon: 'chart',
            },
            {
              name: 'Stakeholder Collaboration',
              description:
                  'Allow habits to be viewed by Sport Coaches, Athletic Trainers, Wellness Professionals, and other stakeholders.',
              icon: 'group',
            },
          ],
          content: [
            {
              videoUrl: 'https://www.youtube.com/watch?v=rTr7DW2eNSI',
              image: htOnboardingImageOne,
              title: 'Create & Categorize Habits',
              description:
                  'To begin habit tracking inside TeamBuildr, start out by creating habits inside the web app so they appear for your athletes. Habits need a category assigned in order to view the collected data in Group & Individual Analysis.',
              gradientType: 'primary',
            },
            {
              image: htOnboardingImageTwo,
              title: 'Completion Overview',
              description:
                  'In the Completion Overview tab, select a group from the dropdown menu in the top left corner to view the collected data, and choose a week range in the top right. It is possible to go back at a max of 12 weeks from a selected day. The date selector will automatically detect the week range you select. Collected data starts on Monday and ends on Sunday.',
              gradientType: 'primary',
            },
            {
              image: htOnboardingImageThree,
              title: 'Group Analysis',
              description:
                  'The Group Analysis tab allows you to view a table of athletes and their assigned habit data, either individually or as a full group. You can select up to 10 habits in this view by filtering them, the filter can be found to the right of the selected group or athlete. In this view, you are also able to dynamically set thresholds for each habit. Thresholds will be removed if the browser is closed or you move out of habit tracking inside the same browser window.',
              gradientType: 'primary',
            },
            {
              image: htOnboardingImageFour,
              title: 'Data Visibility',
              description:
                  'Data will not appear for habits that do not have a category assigned to it. If you delete a category, all associated habits will still be there, but they will need to be assigned a new category in order to view it in the Group and Individual analysis tabs.',
              gradientType: 'primary',
            },
          ],
          videoUrl: 'https://www.youtube.com/watch?v=rTr7DW2eNSI',
          description: 'Create, assign, and monitor habits for users.',
          icon: 'checkmark',
          activeTab: '/ams-lite/habit-tracking',
          backgroundImage: habitTrack,
          tagColor: ' rgba(255, 133, 51, 0.40)',
          textColor: '#CC5200',
          circleColor: '#FF8533',
          subscriptionName: 'AMS Lite Core',
        },
      ]
      : []),
    {
      id: 6,
      title: 'Force Plate Dashboard',
      legacyTitle: '',
      addOnId: 1,
      core: false,
      unlocked: addOnUnlocked(1), // using addOnId of Force Plate Dashboard
      sections: [
        {
          name: 'Integrated',
          description:
            'Using imported data from our 2 partners - Hawkin Dynamic and VALD - our Force Plate Module rethinks how to display external data.',
          icon: 'force-plate',
        },
        {
          name: 'Summarize',
          description:
            'This module takes the simple approach to viewing Force Plate data by reading fewer metrics and highlighting unique values and trends.',
          icon: 'file',
        },
        {
          name: 'Groups vs. Individual',
          description:
            'Visualize force-velocity trends and profiles for an individual or a team, or position group - with trend analysis.',
          icon: 'conversation-group',
        },
      ],
      videoUrl: 'https://www.youtube.com/watch?v=wpzZlqbBeME',
      description: 'Monitor group and athlete data collected with force plates',
      icon: 'force-plate',
      activeTab: '/ams-lite/force-plate-dashboard',
      backgroundImage: forcePlate,
      tagColor: 'rgba(108, 130, 250, 0.40)',
      textColor: '#445AC3',
      circleColor: '#6C82FA',
      subscriptionName: 'Add On',
    },
  ];

  const [packages, setPackages] = useState([...moduleCardData]);

  const updatedPackages = useMemo(() => {
    if (!subscriptionStatus || !subscriptionStatus.packageSubscriptionStatus) {
      return packages;
    }

    const result = packages.map((packageItem) => {
      let unlocked = false;
      if (packageItem.core && coreSubscriptionUnlocked) {
        unlocked = true;
      } else if (!packageItem.core) {
        const packageId = packageItem.addOnId;
        // Find the add-on subscription status by addOnId
        const addOnSubscription = subscriptionStatus.addOnsSubscriptionStatus.find(
          (addOn) => addOn.addOnId === packageId,
        );
        const isPackageSubscribed = addOnSubscription?.isSubscribed;
        unlocked = !!isPackageSubscribed;
      }

      return {
        ...packageItem,
        unlocked,
      };
    });

    return result;
  }, [subscriptionStatus, packages]);

  // Function to compare two arrays of packages
  function arePackagesEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i += 1) {
      if (arr1[i].unlocked !== arr2[i].unlocked) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    // Assuming arePackagesEqual is a deep comparison function
    if (!arePackagesEqual(updatedPackages, packages)) {
      setPackages(updatedPackages);
    }
  }, [updatedPackages]);

  const unlockedModules = moduleCardData.filter((module) => module.unlocked);

  const coreModules = moduleCardData.filter(
    (module) => module.subscriptionName === 'AMS Lite Core',
  );

  const addOnModules = moduleCardData.filter(
    (module) => module.subscriptionName === 'Add On',
  );
  const lockedAddOnModules = addOnModules.filter((module) => !module.unlocked);

  const handleClickPurchasePackage = () => {
    batch(() => {
      dispatch(setIsUpgradeModalShowing(true));
      dispatch(setSelectedPackage(corePackage));
      dispatch(fetchSubscriptionPackage(currentUser.accountCode, 1));
    });
    subscriptionFlowTracker(
      'Purchase Package Button Clicked',
      'Upgrade Modal',
      'Sport Science Hub Landing Page',
    );
  };

  const handleAmplitudeDemo = () => {
    const packageData = {
      title: 'View All Apps',
      addOn: false,
      core: false,
    };
    subscriptionFlowTrackerDemo('Sport Science Hub Banner', packageData);
  };

  const handleAmplitudeModal = () => {
    batch(() => {
      dispatch(setSelectedPackage(corePackage));
      dispatch(setIsLearnMoreModalShowing(true));
    });
    subscriptionFlowTracker(
      'Modal Viewed',
      'Learn More',
      'Sport Science Hub Landing Page',
    );
  };

  return (
    <Container ref={containerRef}>
      <ResizeObserver
        onResize={(rect) => {
          setContainerWidth(rect?.width);
        }}
      />
      <InfoBanner unlocked={coreSubscriptionUnlocked}>
        <BannerHeader
          unlocked={coreSubscriptionUnlocked}
          containerWidth={containerWidth}
        >
          <div className='banner-title'>What is AMS Lite?</div>
          {/* Eventually will only show this text if core subscription not purchased */}
          {/* {!coreSubscriptionUnlocked && containerWidth > 864 && ( */}
          <div className='banner-description'>
            Formerly Sport Science Hub, AMS Lite is a suite of simple modules
            designed to make you your own sport scientist. It simplifies
            athlete performance tracking with insights to optimize coaching and recovery.
          </div>
          {/* )} */}
        </BannerHeader>
        <BannerButtonContainer>
          <LearnMoreButton onClick={handleAmplitudeModal}>
            LEARN MORE
          </LearnMoreButton>
          <BookDemoButton
            onClick={() => {
              handleAmplitudeDemo();
              openPopupWidget({
                url:
                  'https://calendly.com/teambuildr/15-minute-sports-science-hub',
              });
            }}
          >
            BOOK A DEMO
          </BookDemoButton>
        </BannerButtonContainer>
      </InfoBanner>
      <MainContainer>
        <CardGroup
          title={
            coreSubscriptionUnlocked ? 'Included' : 'Included in your plan'
          }
          cards={unlockedModules}
          containerWidth={containerWidth}
        />
        {!coreSubscriptionUnlocked && (
          <>
            <SectionDivider />
            <CardGroup
              title='AMS Lite Core'
              cards={coreModules}
              description='This subscription pack offers a suite of dashboards to optimize athlete performance and recovery, featuring real-time metrics tracking, workload monitoring, habit-building tools, injury logging, and advanced analytics integrations with devices like wearables and force plates.'
              purchaseButton='PURCHASE AMS-LITE CORE'
              purchaseClickFunction={handleClickPurchasePackage}
              gradient='linear-gradient(270deg, #FC4A1A 0%, #F7B733 100%)'
              containerWidth={containerWidth}
            />
          </>
        )}
        {!!lockedAddOnModules.length && (
          <>
            <SectionDivider />
            <CardGroup
              title='Add ons'
              cards={lockedAddOnModules}
              gradient='linear-gradient(270deg, #6A82FB 0%, #FC5C7D 100%)'
              containerWidth={containerWidth}
            />
          </>
        )}
      </MainContainer>
    </Container>
  );
};

SportScienceHome.propTypes = {
  isSideBarActive: PropTypes.bool.isRequired,
  flags: PropTypes.instanceOf(Object).isRequired,
};

export default withLDConsumer()(SportScienceHome);
