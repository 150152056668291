import update from 'immutability-helper';

import {
  SET_INSTANT_PAYMENT_MODAL,
  SET_LEARN_MORE_MODAL,
  SET_UPGRADE_MODAL,
  SET_UPDATED_UPGRADE_MODAL,
  SET_SIDE_BAR_OPEN,
  SET_IS_SIDE_BAR_EXPANDED,
  SET_MARKETING_MODAL,
  FETCH_SUBSCRIPTION_PACKAGE_FULFILLED,
  FETCH_SUBSCRIPTION_PACKAGE_REJECTED,
  FETCH_SUBSCRIPTION_STATUS_FULFILLED,
  FETCH_SUBSCRIPTION_STATUS_REJECTED,
  ADD_ON_SPORTS_SCIENCE_PACKAGE_FULFILLED,
  SET_BOOK_DEMO_MODAL,
  SET_SELECTED_PACKAGE,
} from './actionTypes';

const initialState = {
  isInstantPaymentModalShowing: false,
  isLearnMoreModalShowing: false,
  isUpgradeModalShowing: false,
  isUpdatedUpgradeModalShowing: false,
  isSideBarOpen: false,
  isSideBarExpanded: true,
  isMarketingModalShowing: false,
  isBookDemoModalShowing: false,
  addedPackage: {},
  subscriptionStatus: null,
  subscriptionPackage: {},
  selectedPackage: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_INSTANT_PAYMENT_MODAL: {
      return update(state, {
        isInstantPaymentModalShowing: {
          $set: action.payload,
        },
      });
    }
    case SET_LEARN_MORE_MODAL: {
      return update(state, {
        isLearnMoreModalShowing: {
          $set: action.payload,
        },
      });
    }
    case SET_UPGRADE_MODAL: {
      return update(state, {
        isUpgradeModalShowing: {
          $set: action.payload,
        },
      });
    }
    case SET_UPDATED_UPGRADE_MODAL: {
      return update(state, {
        isUpdatedUpgradeModalShowing: {
          $set: action.payload,
        },
      });
    }
    case SET_MARKETING_MODAL: {
      return update(state, {
        isMarketingModalShowing: {
          $set: action.payload,
        },
      });
    }
    case SET_BOOK_DEMO_MODAL: {
      return update(state, {
        isBookDemoModalShowing: {
          $set: action.payload,
        },
      });
    }
    case SET_SIDE_BAR_OPEN: {
      return update(state, {
        isSideBarOpen: {
          $set: action.payload,
        },
      });
    }
    case SET_IS_SIDE_BAR_EXPANDED: {
      return update(state, {
        isSideBarExpanded: {
          $set: action.payload,
        },
      });
    }
    case FETCH_SUBSCRIPTION_PACKAGE_FULFILLED: {
      return update(state, {
        subscriptionPackage: {
          $set: action.payload,
        },
      });
    }
    case FETCH_SUBSCRIPTION_PACKAGE_REJECTED: {
      /** I still want this to trigger a useEffect when it fails
       * so I'm taking it out of the immutability helper
       */
      return {
        ...state,
        subscriptionPackage: {},
      };
    }
    case FETCH_SUBSCRIPTION_STATUS_FULFILLED: {
      // return update(
      //   state, {
      //     subscriptionStatus: {
      //       $set: action.payload,
      //     },
      //   },
      // );
      return {
        ...state,
        subscriptionStatus: action.payload,
      };
    }
    case FETCH_SUBSCRIPTION_STATUS_REJECTED: {
      /** I still want this to trigger a useEffect when it fails
       * so I'm taking it out of the immutability helper
       */
      return {
        ...state,
        subscriptionStatus: null,
      };
    }
    case ADD_ON_SPORTS_SCIENCE_PACKAGE_FULFILLED: {
      return update(state, {
        addedPackage: {
          $set: action.payload,
        },
      });
    }
    case SET_SELECTED_PACKAGE: {
      return update(state, {
        selectedPackage: {
          $set: action.payload,
        },
      });
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
