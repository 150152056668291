/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { SketchPicker } from 'react-color';
import IcomoonReact from 'icomoon-react';
import { Form as FormikForm, Formik } from 'formik';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Button from '../../../../shared/components/Button/Button';
import Text from '../../../../shared/components/Text/Text';
import Input from '../../../../shared/_TBComponents/Input/index';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import MobileWidgetPreview from './MobileWidgetPreview';
import {
  setModalOpen,
  setErrorModalOpen,
} from '../../../sports-science/ducks/sportsScienceActions';
import {
  createNewCategoryForAccount,
  fetchCategoriesForAccount,
  editCategoryForAccount,
  fetchHabitsForAccount,
} from '../../ducks/habitTrackingActions';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const CreateCategoryForm = ({ modalType }) => {
  const ref = useRef(null);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  const dispatch = useDispatch();
  const editCategoryInfo = useSelector(
    (state) => state.habitTracking.data.editCategoryInfo,
  );
  const categories = useSelector(
    (state) => state.habitTracking.data.categories,
  );
  const isNewCategoryLoading = useSelector(
    (state) => state.habitTracking.ui.isNewCategoryLoading,
  );
  const isUpdateCategoryLoading = useSelector(
    (state) => state.habitTracking.ui.isUpdateCategoryLoading,
  );
  const [isSketchPickerPopupOpen, setIsSketchPickerPopupOpen] = useState(false);
  const [sketchPickerColor, setSketchPickerColor] = useState(
    modalType === 'edit-modal' && editCategoryInfo ? editCategoryInfo.color : '',
  );

  const colorsArray = [
    '#E6887A',
    '#CAB598',
    '#F6C300',
    '#E5E530',
    '#A7BB23',
    '#007F52',
    '#32AFB0',
    '#1F3FD7',
    '#9A44E6',
    '#DF0094',
  ];
  const IconArray = [
    'moon',
    'muscle',
    'note',
    'pencil',
    'phone',
    'raw-data-report',
    'ribbon',
    'science',
    'sleep-moon',
    'smiley',
    'star',
    'sun',
    'trophy',
    'user',
    'video',
    'visible',
    'warmup',
    'watch-heart-rate',
    'watch-sync',
    'TV 2',
    'calendar',
    'camera',
    'clipboard',
    'clock',
    'cloud',
    'credit-card',
    'distance-covered',
    'distance-covered1',
    'dollar',
    'feed',
    'fist-bump-left',
    'fist-bump-right',
    'heart-rate',
    'cooldown',
    'fork-knife',
    'percent',
    'message-bubbles',
    'group',
    'heart',
    'steps',
    'list',
    'key',
    'lift',
    'lightning-bolt',
    'group-alt',
    'headset',
    'journal',
    'alert-on',
    'home',
    'pin',
    'cardio',
    'circuit',
  ];
  const [selectedIcon, setSelectedIcon] = useState(
    modalType === 'edit-modal' && editCategoryInfo
      ? editCategoryInfo.icon
      : null,
  );

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      dispatch(setIsSketchPickerPopupOpen(false));
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const FormWrapper = styled('div')`
    .fade-in-100-width {
      width: 100%;
    }
  `;

  const StyledForm = styled(FormikForm)`
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: ${(props) => (props.activePage === 2 || props.activePage === 1 ? '60px' : '100px')};

    @media (max-width: 500px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  `;
  const PageContainer = styled('div')`
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'column')};
    justify-content: center;
    align-items: flex-start;
    gap: ${(props) => (props.gap ? props.gap : '20px')};
    .fade-in-100-width {
      width: 100%;
    }

    @media (max-width: 500px) {
      gap: 20px;
    }
  `;

  const Popover = styled('div')`
    margin-top: 30px;
    position: absolute;
    z-index: 2;
  `;

  const CircleContainer = styled('div')`
    padding: 2px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${(props) => (props.isActive ? `3px solid ${sketchPickerColor}` : 'none')};
  `;

  const ColorCircle = styled('div')`
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: ${(props) => props.backgroundColor};
  `;

  const ColorCirclesContainer = styled('div')`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 5px;
  `;

  const ColorLabelContainer = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 15px;
  `;

  const ErrorWrapper = styled('div')`
    display: flex;
    flex-direction: column;
  `;

  const SpinnerWrapper = styled('div')`
    .spinner-container {
      min-height: 200px;
    }
  `;

  const IconContainer = styled('div')`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    padding: 16px;
  `;

  const ChosenIconContainer = styled('div')`
    display: flex;
    border-radius: 4px;
    padding: 16px;
    border: 1px solid #EEEEEE;
    justify-content: center;
    align-items: center;
  `;

  const CreateCategoryValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Category name is required')
      .max(
        100,
        'Category name cannot exceed 100 characters',
      ) /** 100 character limit */
      .test(
        'unique-name',
        'Name already exists, choose a different name to start collecting data',
        (value) => {
          if (modalType === 'edit-modal') {
            return true; /** Skip unique name validation in edit mode */
          }
          return (
            value
            && !categories.some(
              (category) => category.name.toLowerCase() === value.toLowerCase(),
            )
          );
        },
      ),
    color: Yup.string().required('Please select a color'),
    icon: Yup.string().required('Please select an icon'),
  });

  const ModalButton = ({
    // eslint-disable-next-line react/prop-types
    onClick,
    cta,
    customColor,
    customTextColor,
    type,
  }) => (
    <Button
      bottom
      fullWidth
      cta={cta}
      className='modal-button'
      large
      noBorder
      customColor={customColor || '#E0E0E0'}
      customTextColor={customTextColor || '#BDBDBD'}
      primary
      square
      onClick={onClick}
      upperCase={false}
      fontSize='20px'
      letterSpacing={false}
      fontWeight={700}
      /* noHover */
      type={type}
      disabled={isNewCategoryLoading || isUpdateCategoryLoading}
    />
  );

  const modifyValues = (values) => {
    const updatedValues = { ...values };

    delete updatedValues.modalType;

    /** for edit modal only submit values that have changed */
    if (
      modalType === 'edit-modal'
      && updatedValues.name === editCategoryInfo.name
    ) {
      delete updatedValues.name;
    }
    if (
      modalType === 'edit-modal'
      && updatedValues.color === editCategoryInfo.color
    ) {
      delete updatedValues.color;
    }
    if (
      modalType === 'edit-modal'
      && updatedValues.icon === editCategoryInfo.icon
    ) {
      delete updatedValues.icon;
    }
    return updatedValues;
  };

  const getSubmitButtonCustomColors = (values, errors) => {
    if (!Object.keys(errors)?.length && values.name && values.icon) {
      return {
        customColor: '#444444',
        customTextColor: 'white',
      };
    }

    return {
      customColor: '',
      customTextColor: '',
    };
  };

  // Amplitude tracking
  const handleAmplitude = () => {
    if (modalType === 'edit-modal') {
      dashboardTracker(
        'Consistency Coach',
        'Edit Category Modal Closed',
        'User exited out of the modal',
      );
    } else {
      dashboardTracker(
        'Consistency Coach',
        'New Category Modal Closed',
        'User exited out of the modal',
      );
    }
  };

  return (
    <>
      {isNewCategoryLoading || isUpdateCategoryLoading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <Formik
          initialValues={
            modalType === 'edit-modal' && editCategoryInfo
              ? {
                name: editCategoryInfo.name,
                color: editCategoryInfo?.color,
                icon: editCategoryInfo.icon,
                modalType,
              }
              : {
                name: '',
                color: '',
                icon: '',
                modalType,
              }
          }
          validationSchema={CreateCategoryValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            const updatedValues = modifyValues(values);

            setSubmitting(true);

            if (modalType === 'edit-modal') {
              const categoryId = editCategoryInfo?.id;
              dispatch(
                editCategoryForAccount(accountCode, categoryId, updatedValues, {
                  onSuccess: () => {
                    setSubmitting(false);
                    dispatch(setModalOpen(false, ''));
                    /** re render habits so that associated habits get updated */
                    dispatch(fetchHabitsForAccount(accountCode));
                  },
                  onError: () => {
                    setSubmitting(false);
                    dispatch(setModalOpen(false, ''));
                    dispatch(
                      setErrorModalOpen(
                        true,
                        'general-error',
                        'Could not edit category',
                      ),
                    );
                  },
                }),
              );
            } else {
              dispatch(
                createNewCategoryForAccount(accountCode, updatedValues, {
                  onSuccess: () => {
                    setSubmitting(false);
                    dispatch(setModalOpen(false, ''));
                    dispatch(fetchCategoriesForAccount(accountCode));
                  },
                  onError: () => {
                    setSubmitting(false);
                    dispatch(
                      setErrorModalOpen(
                        true,
                        'general-error',
                        'Could not create category',
                      ),
                    );
                  },
                }),
              );
            }
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            handleChange,
            values,
            setFieldValue,
            handleBlur,
          }) => (
            <FormWrapper>
              <StyledForm style={{ paddingBottom: '80px' }}>
                <IcomoonReact
                  iconSet={iconSet}
                  size={15}
                  icon='remove'
                  style={{
                    position: 'fixed',
                    top: '20px',
                    right: '20px',
                  }}
                  onClick={() => {
                    handleAmplitude();
                    dispatch(setModalOpen(false, ''));
                  }}
                />
                <>
                  <PageContainer>
                    <Input
                      error={!!touched.name && !!errors.name}
                      errorMsg={errors.name}
                      fontSize='16px'
                      fontWeight={400}
                      textColor='#444444'
                      height='38px'
                      label='Name'
                      name='name'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      right='5px'
                      styledPropsLabel={{
                        fontSize: '19px',
                        fontWeight: 700,
                      }}
                      top='43px'
                      value={values.name}
                      width='100%'
                      placeholder='ie. Personal Development'
                    />
                    <ColorLabelContainer>
                      <Text fontWeight={700} fontSize='19px'>
                        Branding
                      </Text>
                      <Text fontWeight={400} fontSize='16px'>
                        Choose color
                      </Text>
                    </ColorLabelContainer>
                    <ErrorWrapper>
                      <ColorCirclesContainer>
                        {colorsArray.map((color) => (
                          <CircleContainer
                            isActive={
                              values.color && sketchPickerColor === color
                            }
                          >
                            <ColorCircle
                              onClick={() => {
                                setSketchPickerColor(color);
                                setFieldValue('color', color);
                              }}
                              backgroundColor={color}
                            />
                          </CircleContainer>
                        ))}
                        <CircleContainer
                          isActive={
                            values.color
                            && !colorsArray.includes(sketchPickerColor)
                          }
                        >
                          <ColorCircle
                            onClick={() => setIsSketchPickerPopupOpen(true)}
                            backgroundColor='linear-gradient(180deg, #FF7676 0%, #FFC967 16.5%, #FFFF70 32%, #8DFF8D 49.5%, #9191FF 68.5%, #DAA7FF 84.25%, #FFB2FF 100%)'
                          />
                        </CircleContainer>
                      </ColorCirclesContainer>
                      {errors.color && touched.color && (
                        <Text
                          color='#CC0404'
                          fontSize='13px'
                          fontWeight={700}
                          style={{ marginTop: '5px' }}
                        >
                          {errors.color}
                        </Text>
                      )}
                    </ErrorWrapper>
                    {isSketchPickerPopupOpen ? (
                      <Popover ref={ref}>
                        <SketchPicker
                          width={240}
                          color={sketchPickerColor}
                          onChangeComplete={(color) => {
                            setSketchPickerColor(color.hex);
                            setFieldValue('color', color.hex);
                          }}
                        />
                      </Popover>
                    ) : null}
                    {selectedIcon ? (
                      <Text fontSize='16px' fontWeight={400}>
                        Selected icon
                      </Text>
                    ) : (
                      <Text fontSize='16px' fontWeight={400}>
                        Choose icon
                      </Text>
                    )}
                    {selectedIcon && (
                      <ChosenIconContainer>
                        <IcomoonReact
                          iconSet={iconSet}
                          size={32}
                          icon={selectedIcon}
                          color='#444444'
                        />
                      </ChosenIconContainer>
                    )}
                    <ErrorWrapper>
                      <IconContainer>
                        {IconArray.map((icon) => (
                          <IcomoonReact
                            iconSet={iconSet}
                            size={24}
                            icon={icon}
                            color='#9E9E9E'
                            onClick={() => {
                              setSelectedIcon(icon);
                              setFieldValue('icon', icon);
                            }}
                          />
                        ))}
                      </IconContainer>
                      {errors.icon && touched.icon && (
                        <Text
                          color='#CC0404'
                          fontSize='13px'
                          fontWeight={700}
                          style={{ marginTop: '5px' }}
                        >
                          {errors.icon}
                        </Text>
                      )}
                    </ErrorWrapper>
                    <MobileWidgetPreview
                      currentlySelectedColor={sketchPickerColor}
                      selectedIcon={selectedIcon}
                    />
                  </PageContainer>
                  <ModalButton
                    customColor={
                      getSubmitButtonCustomColors(values, errors)?.customColor
                    }
                    customTextColor={
                      getSubmitButtonCustomColors(values, errors)
                        ?.customTextColor
                    }
                    onClick={() => {
                      handleSubmit();
                    }}
                    cta='Save category'
                    type='submit'
                  />
                </>
              </StyledForm>
            </FormWrapper>
          )}
        />
      )}
    </>
  );
};

CreateCategoryForm.propTypes = {
  modalType: PropTypes.string,
};

CreateCategoryForm.defaultProps = {
  modalType: 'create-modal',
};

export default CreateCategoryForm;
