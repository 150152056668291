import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { useCookies } from 'react-cookie';
import setAuthToken from '../../shared/utils/setAuthToken';
import PrivateRoute from './PrivateRoute';
import LoginRoute from './LoginRoute';
import FourOhFourRoute from './FourOhFourRoute';
import history from '../History/history';
// import { createHashHistory } from 'history';

// const history = createHashHistory();
import Login from '../../modules/login/login';
import Portal from '../../modules/login/portal';
import LoginWithSSOToken from '../../modules/login/components/presentational/sso/LoginWithSSOToken';
import WeightroomView from '../../modules/weightroom-view/components/container/WeightroomViewContainer';
import WeightroomViewNew from '../../modules/weightroom-view-new/components/container/WeightroomViewContainer';
import TestPage from '../../modules/test-page/TestPage';
// import Journal from '../../modules/journal/Journal';
import Documents from '../../modules/documents/Documents';
import Playground from '../../modules/playground/Playground';
import Calendars from '../../modules/calendars/Calendars';
import Profile from '../../modules/profile/Profile';
import Settings from '../../modules/settings/Settings';
import Leaderboard from '../../modules/leaderboard/Leaderboard';
import Reporting from '../../modules/reporting/Reporting';
import Reseller from '../../modules/reseller/Reseller';
import Programs from '../../modules/programs/Programs';
import Conversations from '../../modules/conversations/Conversations';
import SocketTester from '../../shared/components/SocketTester/SocketTester';
import AccountSettingsPage from '../../modules/settings/components/presentational/account-settings/accountSettingsPage';
import OrganizationSettingsPage from '../../modules/settings/components/presentational/organization-settings/OrganizationSettingsPage';
import IntegrationsSettingsPage from '../../modules/settings/components/presentational/integrations-settings/IntegrationsSettingsPage';
import SubscriptionsSettingsPage from '../../modules/settings/components/presentational/subscriptions-settings/SubscriptionsSettingsPage';
import ForgotPassword from '../../modules/forgot-password/ForgotPassword';
import ResetPassword from '../../modules/forgot-password/ResetPassword';
import SharedWorkout from '../../modules/shared-workout/components/SharedWorkout';
import Workouts from '../../modules/workouts/Workouts';
import SportsScience from '../../modules/sports-science/SportsScience';
import GymStudio from '../../modules/gymstudio/GymStudio';
import Evaluations from '../../modules/evaluations/Evaluations';
import TacticalSettingsPage from '../../modules/settings/components/presentational/tactical-settings/tacticalSettingsPage';
import { AthleteReportCardRouteWrapper } from '../../modules/athleteReportCard/AthleteReportCard';
import ViewOnlySCDash from '../../modules/sport-coach-dashboard/ViewOnlySCDash';
import ViewOnlyFPDash from '../../modules/force-plate-dashboard/ViewOnlyFPDashboard';
import CancelPage from '../../modules/settings/components/presentational/cancel-page';
import ViewOnlyLMDash from '../../modules/load-monitoring-dashboard/ViewOnlyLMDashboard';

// Redirects for sports-science to ams-lite and related module renaming
const REDIRECT_MAPPING = {
  'sport-coach-dashboard': 'kpi-tracker',
  'recovery-radar': 'body-heat-map',
  'consistency-coach': 'habit-tracker',
};

const Routes = () => {
  const [cookies, setCookies] = useCookies(['v2token']);
  const isAuthenticatedv3 = cookies.v2token !== undefined
    && cookies.v2token !== '0'
    && ((cookies.accessToken !== undefined && cookies.accessToken !== '0')
      || (cookies.refreshToken !== undefined && cookies.refreshToken !== '0'))
    && cookies.session_id !== undefined
    && cookies.session_id !== '0'
    && cookies.session_key !== undefined
    && cookies.session_key !== '0';

  const currentUser = useSelector((state) => state.auth.data.currentUser);

  // we assume that the user is registered until we get the currentUser object
  // and then if it's not registered we send them to the registration page
  // within the private route
  let isRegistered = true;

  if (Object.keys(currentUser).length && currentUser.isRegistered === false) {
    isRegistered = false;
  }

  const isAuthenticated = cookies.v2token && cookies.v2token !== '0';

  useLayoutEffect(() => {
    if (cookies.v2token) {
      if (cookies.v2token !== '0') {
        setAuthToken(cookies.v2token);
      }
    }
  }, []);

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <PrivateRoute
          exact
          path='/weightroom-view-old'
          component={WeightroomView}
          authed={isAuthenticated}
          registered={isRegistered}
        />
        <PrivateRoute
          exact
          path='/weightroom-view'
          component={WeightroomViewNew}
          authed={isAuthenticated}
          registered={isRegistered}
        />
        <Route path='/programs' component={Programs} />
        <PrivateRoute
          path='/documents'
          component={Documents}
          authed={isAuthenticatedv3}
          registered={isRegistered}
        />
        <PrivateRoute
          path='/playground'
          component={Playground}
          authed={isAuthenticatedv3}
          registered={isRegistered}
        />
        <PrivateRoute
          path='/profile'
          component={Profile}
          authed={isAuthenticatedv3}
          registered={isRegistered}
        />
        <PrivateRoute
          exact
          path='/settings'
          component={Settings}
          authed={isAuthenticatedv3}
          registered={isRegistered}
        />
        <PrivateRoute
          exact
          path='/settings/account'
          component={AccountSettingsPage}
          authed={isAuthenticatedv3}
          registered={isRegistered}
        />
        <PrivateRoute
          exact
          path='/settings/organization'
          component={OrganizationSettingsPage}
          authed={isAuthenticatedv3}
          registered={isRegistered}
        />
        <PrivateRoute
          exact
          path='/settings/subscriptions'
          component={SubscriptionsSettingsPage}
          authed={isAuthenticatedv3}
          registered={isRegistered}
        />
        <PrivateRoute
          exact
          path='/settings/tactical'
          component={TacticalSettingsPage}
          authed={isAuthenticatedv3}
          registered={isRegistered}
        />
        <PrivateRoute
          exact
          path='/settings/integrations'
          component={IntegrationsSettingsPage}
          authed={isAuthenticatedv3}
          registered={isRegistered}
        />
        <PrivateRoute
          exact
          path='/settings/cancel'
          component={CancelPage}
          authed={isAuthenticatedv3}
          registered={isRegistered}
        />
        <PrivateRoute
          path='/workouts'
          component={Workouts}
          authed={isAuthenticatedv3}
          registered={isRegistered}
        />
        <PrivateRoute
          path='/calendars'
          component={Calendars}
          authed={isAuthenticatedv3}
          registered={isRegistered}
        />
        <PrivateRoute
          path='/messaging'
          component={Conversations}
          authed={isAuthenticatedv3}
          registered={isRegistered}
        />
        <PrivateRoute
          path='/reseller'
          component={Reseller}
          authed={isAuthenticatedv3}
          registered={isRegistered}
        />
        <PrivateRoute
          exact
          path='/reporting'
          component={Reporting}
          authed={isAuthenticatedv3}
          registered={isRegistered}
        />
        <PrivateRoute
          exact
          path='/leaderboard'
          component={Leaderboard}
          authed={isAuthenticatedv3}
          registered={isRegistered}
        />
        <PrivateRoute
          path='/ams-lite'
          component={SportsScience}
          authed={isAuthenticatedv3}
          registered={isRegistered}
        />
        <PrivateRoute
          path='/gymstudio'
          component={GymStudio}
          authed={isAuthenticatedv3}
          registered={isRegistered}
        />
        <Route path='/report-card' component={AthleteReportCardRouteWrapper} />
        <Route exact path='/kpi-tracker' component={ViewOnlySCDash} />
        <Route exact path='/lm-dash' component={ViewOnlyLMDash} />
        <Route exact path='/fp-dash' component={ViewOnlyFPDash} />
        <PrivateRoute
          path='/evaluations'
          component={Evaluations}
          authed={isAuthenticatedv3}
          registered={isRegistered}
        />
        <LoginRoute
          exact
          path='/login'
          component={Login}
          authed={isAuthenticatedv3}
        />
        <LoginRoute
          path='/portal'
          component={Portal}
          authed={isAuthenticatedv3}
        />
        <LoginRoute
          exact
          path='/forgot'
          component={ForgotPassword}
          authed={isAuthenticatedv3}
        />
        <Route path='/reset-password' component={ResetPassword} />
        <Route exact path='/login/sso' component={LoginWithSSOToken} />
        {process.env.ENVIRONMENT !== 'PRODUCTION' && (
          <PrivateRoute
            path='/socket-tester'
            component={SocketTester}
            authed={isAuthenticatedv3}
          />
        )}
        {process.env.ENVIRONMENT !== 'PRODUCTION' && (
          <PrivateRoute
            exact
            path='/test-page'
            component={TestPage}
            authed={isAuthenticatedv3}
          />
        )}
        {/* {process.env.ENVIRONMENT !== 'PRODUCTION' && (
          <PrivateRoute exact path='/journal' component={Journal} authed={isAuthenticatedv3} />
        )} */}
        <Route path='/' exact render={() => <Redirect to='/login' />} />
        {/* Added redirect to messaging from conversations after changing URLs. */}
        <Route
          path='/conversations'
          exact
          render={() => <Redirect to='/messaging' />}
        />
        {/* Handle all sports-science and ams-lite redirects */}
        <Route
          path={['/sports-science/:path*', '/ams-lite/:path*']}
          render={({ match }) => {
            const pathSegments = match.params.path
              ? match.params.path.split('/')
              : [];
            const firstSegment = pathSegments[0];

            // If this is a known redirect, use the mapped value
            if (REDIRECT_MAPPING[firstSegment]) {
              return (
                <Redirect
                  to={`/ams-lite/${REDIRECT_MAPPING[firstSegment]}${
                    pathSegments.slice(1).length > 0
                      ? `/${pathSegments.slice(1).join('/')}`
                      : ''
                  }`}
                  status={301}
                />
              );
            }

            // For all other paths, just replace sports-science with ams-lite
            if (match.path.startsWith('/sports-science')) {
              return (
                <Redirect
                  to={`/ams-lite/${match.params.path || ''}`}
                  status={301}
                />
              );
            }

            // '/ams-lite' path that doesn't need redirection
            return null;
          }}
        />

        {/* Root sports-science redirect for landing page */}
        <Route
          exact
          path='/sports-science'
          render={() => <Redirect to='/ams-lite' status={301} />}
        />
        {/* Redirect view only dashboard from Sport Coach Dashboard URL to new KPI Tracker URL */}
        <Route
          path='/sc-dash'
          render={({ location }) => (
            <Redirect
              to={{
                pathname: '/kpi-tracker',
                search: location.search,
              }}
              status={301}
            />
          )}
        />
        <Route path='/shared-workouts' component={SharedWorkout} />
        <Route path='*' component={FourOhFourRoute} />
      </Switch>
    </ConnectedRouter>
  );
};

export default Routes;
