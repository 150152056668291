/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';
import ResizeObserver from 'react-resize-observer';
import IcomoonReact from 'icomoon-react';
import Modal from '../../../../shared/components/Modal/Modal';
import CreateHabitForm from '../../../habit-tracking/components/presentational/CreateHabitForm';
import {
  setModalOpen,
  setErrorModalOpen,
} from '../../ducks/sportsScienceActions';
import CreateCategoryForm from '../../../habit-tracking/components/presentational/CreateCategoryForm';
import AssignmentConflictModalContent from '../../../habit-tracking/components/presentational/AssignmentConflictModalContent';
import AttentionModalContent from '../../../habit-tracking/components/presentational/AttentionModalContent';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';
import { setAssignmentConflictAction } from '../../../habit-tracking/ducks/habitTrackingActions';
import DeleteModalContent from '../../../habit-tracking/components/presentational/DeleteModalContent';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const ModalContainer = styled('div')`
  width: 100%;
`;

const SportsScienceModal = () => {
  const isSideBarExpanded = useSelector(
    (state) => state.sportsScience.data.isSideBarExpanded,
  );
  const activeModal = useSelector(
    (state) => state.sportsScience.ui.activeModal,
  );
  const isModalOpen = useSelector(
    (state) => state.sportsScience.ui.isModalOpen,
  );
  const isErrorModalOpen = useSelector(
    (state) => state.sportsScience.ui.isErrorModalOpen,
  );
  const editHabitInfo = useSelector(
    (state) => state.habitTracking.data.editHabit,
  );
  const isSingleHabitLoading = useSelector(
    (state) => state.habitTracking.ui.isSingleHabitLoading,
  );

  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setModalOpen(false, ''));
    // Amplitude tracking
    switch (activeModal) {
      case 'habitTracking-create-category':
        dashboardTracker(
          'Consistency Coach',
          'New Category Modal Closed',
          'User exited out of the modal',
        );
        break;
      case 'habitTracking-edit-category':
        dashboardTracker(
          'Consistency Coach',
          'Edit Category Modal Closed',
          'User exited out of the modal',
        );
        break;
      case 'habitTracking-delete-category':
        dashboardTracker(
          'Consistency Coach',
          'Delete Category Modal Closed',
          'User exited out of the modal',
        );
        break;
      case 'habitTracking-delete-habit':
        dashboardTracker(
          'Consistency Coach',
          'Delete Habit Modal Closed',
          'User exited out of the modal',
        );
        break;
      case 'habitTracking-create-habit':
        dashboardTracker(
          'Consistency Coach',
          'Create Habit Modal Closed',
          'User exited out of the modal',
        );
        break;
      case 'habitTracking-edit-habit':
        dashboardTracker(
          'Consistency Coach',
          'Edit Habit Modal Closed',
          'User exited out of the modal',
        );
        break;
      default:
    }
  };

  const modalTitle = () => {
    if (activeModal === 'habitTracking-create-habit') {
      return 'Create new habit';
    }
    if (activeModal === 'habitTracking-edit-habit') {
      return 'Edit habit';
    }
    if (activeModal === 'habitTracking-create-category') {
      return 'Add new category';
    }
    if (activeModal === 'habitTracking-edit-category') {
      return 'Edit category';
    }
    return null;
  };

  const modalSubtitle = () => {
    if (
      activeModal === 'habitTracking-edit-habit'
      && editHabitInfo
      && !isSingleHabitLoading
    ) {
      return `${editHabitInfo?.name}`;
    }
    return null;
  };

  const modalIcon = () => {
    if (activeModal === 'habitTracking-create-habit') {
      return 'plus';
    }
    if (activeModal === 'habitTracking-edit-habit') {
      return 'pencil';
    }
    if (activeModal === 'habitTracking-create-category') {
      return 'list';
    }
    if (activeModal === 'habitTracking-edit-category') {
      return 'pencil';
    }
    return null;
  };
  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={isModalOpen}
      title={modalTitle()}
      subtitle={modalSubtitle()}
      titleIcon={modalIcon()}
      customBorderRadius='24px'
      hideButton
      flexGrow={false}
      customMaxWidth='750px'
      widthFitContent={
        activeModal === 'habitTracking-delete-habit'
        || activeModal === 'habitTracking-delete-category'
      }
      headerHeightToSubtract={49}
      sidebarWidth={isSideBarExpanded ? 300 : 96}
      overlayBackgroundColor='rgba(68, 68, 68, 0.6)'
      titleBorderBottomStyle='0px'
      hideTitle={
        activeModal === 'habitTracking-delete-habit'
        || activeModal === 'habitTracking-delete-category'
      }
      closeOnClickOutside={!isErrorModalOpen}
      customPadding='0px'
    >
      <ModalContainer>
        {activeModal === 'habitTracking-create-habit' && (
          <CreateHabitForm initialPage={1} modalType='create-modal' />
        )}
        {activeModal === 'habitTracking-edit-habit' && (
          <CreateHabitForm initialPage={3} modalType='edit-modal' />
        )}
        {activeModal === 'habitTracking-create-category' && (
          <CreateCategoryForm
            closeModal={closeModal}
            modalType='create-category'
          />
        )}
        {activeModal === 'habitTracking-edit-category' && (
          <CreateCategoryForm closeModal={closeModal} modalType='edit-modal' />
        )}
        {(activeModal === 'habitTracking-delete-habit'
          || activeModal === 'habitTracking-delete-category') && (
          <DeleteModalContent closeModal={closeModal} />
        )}
      </ModalContainer>
    </Modal>
  );
};

const ErrorOverlay = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000 !important;
`;

const ErrorModalContainer = styled('div')`
  display: flex;
  background: white;
  border-radius: 24px;
  max-width: 90%;
  max-height: 98%;
  overflow: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #ffffff;
  max-width: 452px;
`;

const Button = styled('button')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.backgroundColor && props.backgroundColor};
  padding: 8px 16px 8px 16px;
  height: 51px;
  border-radius: 4px;
`;

const Header = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding: 40px 20px 40px 20px;
  text-align: center;
  gap: 20px;
`;

const IconCircle = styled('div')`
  display: flex;
  height: 52px;
  width: 52px;
  min-width: 52px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid #444444;
  padding-bottom: 5px;
`;

const Body = styled('div')`
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.containerWidth < 400 ? '20px' : '20px 40px 36px 40px')};
  gap: 20px;
  text-align: center;
`;

const SportsScienceErrorModal = () => {
  const [containerWidth, setContainerWidth] = useState(0);
  const activeErrorModal = useSelector(
    (state) => state.sportsScience.ui.activeErrorModal,
  );
  const isErrorModalOpen = useSelector(
    (state) => state.sportsScience.ui.isErrorModalOpen,
  );
  const errorModalMessage = useSelector(
    (state) => state.sportsScience.ui.errorModalMessage,
  );

  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setErrorModalOpen(false, ''));
    dispatch(setAssignmentConflictAction(''));
  };

  return (
    <>
      {isErrorModalOpen && (
        <ErrorOverlay onClick={(e) => closeModal(e)}>
          <ErrorModalContainer onClick={(e) => e.stopPropagation()}>
            <IcomoonReact
              iconSet={iconSet}
              size={16}
              icon='remove'
              onClick={() => closeModal()}
              style={{ position: 'absolute', top: 25, right: 25 }}
            />
            {activeErrorModal === 'habitTracking-assignment-conflict' && (
              <AssignmentConflictModalContent closeModal={closeModal} />
            )}
            {activeErrorModal === 'habitTracking-assignment-attention' && (
              <AttentionModalContent closeModal={closeModal} />
            )}
            {activeErrorModal === 'general-error' && (
              <Wrapper>
                <ResizeObserver
                  onResize={(rect) => {
                    setContainerWidth(rect?.width);
                  }}
                />
                <Header>
                  <IconCircle>
                    <IcomoonReact iconSet={iconSet} size={28} icon='warning' />
                  </IconCircle>
                  <Text fontWeight={700} fontSize='32px'>
                    Error
                  </Text>
                </Header>
                <Body containerWidth={containerWidth}>
                  <Text
                    fontSize={containerWidth < 400 ? '16px' : '19px'}
                    fontWeight={700}
                  >
                    Oops! There was an issue trying to process your request.
                    Please reload the page or contact your administrator.
                  </Text>
                  <Text
                    fontSize={containerWidth < 400 ? '16px' : '19px'}
                    fontWeight={700}
                  >
                    {errorModalMessage}
                  </Text>
                  <Button
                    onClick={() => closeModal()}
                    backgroundColor='#FFCB47'
                  >
                    <Text fontSize='23px' fontWeight={700}>
                      OK
                    </Text>
                  </Button>
                </Body>
              </Wrapper>
            )}
          </ErrorModalContainer>
        </ErrorOverlay>
      )}
    </>
  );
};

export { SportsScienceModal, SportsScienceErrorModal };
