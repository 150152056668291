/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import ReactPlayer from 'react-player';
import { openPopupWidget } from 'react-calendly';
import { setTimeout } from 'core-js';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';
import {
  setIsInstantPaymentModalShowing,
  setIsUpgradeModalShowing,
  setSelectedPackage,
  fetchSubscriptionPackage,
} from '../../ducks/sportsScienceActions';
import corePackage from './packages';
import { subscriptionFlowTracker, subscriptionFlowTrackerDemo } from '../../../../shared/utils/amplitudeHelper';

const transitionSpeed = 0.5;

const ModalContainer = styled('div')`
	display: flex;
  z-index: 1003;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  min-height: 500px;
  transition: all 1s ease;
  width: 50vw;
  position: absolute; 
  @media screen and (min-width: 540px) {
    min-width: 820px;
  }
  @media screen and (max-width: 540px) {
    border-radius: 0px;
    width: 100%;
    height: 100%; 
    margin-top: 49px;
  }
  @media screen and (max-width: 820px) {
    min-width: 90%;
  }
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .remove-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    z-index: 1001;
    .remove-div-icon {
      cursor: pointer;
    }
    position: absolute;
  }
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  @media screen and (max-height: 700px) {
    height: 90vh;
  }  
  max-height: 800px;
  max-width: 600px;
`;

const ModalNextButton = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-radius: 10000px;
  height: 40px;
  padding: 10px;
  margin-bottom: 5px;
  color: white;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(247,183,51,1) 0%, rgba(252,74,26,1) 100%);
  flex: 1;
  max-width: ${(props) => (props.isPaid ? '100%' : '49%')};
  transition: all ${transitionSpeed}s ease;
  @media screen and (max-width: 540px) {
    min-width: 100%;
  }

  &:hover {
    opacity: 0.7;
  }
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background: white;
  margin-bottom: 85px;
  box-shadow: ${BoxShadow};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  scroll-behavior: smooth;
  @media screen and (max-width: 540px) {
    height: 100%; 
    margin-bottom: 0px;
  }
`;

const Buttons = styled('div')`
  position: absolute;
  display: flex;
  width: 100%;
  background: white;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 12px;
  box-shadow: ${BoxShadow};
  bottom: -10px;
  @media screen and (max-width: 540px) {
    background: none;
    flex-direction: column;
    background: white;
    bottom: 0px;
  }
`;

const VideoContainer = styled('div')`
  background-image: url(src/app/shared/images/load-monitoring-dashboard.jpg);
`;

const UpperBox = styled('div')`
  height: 339px;
  width: 100%;
  background: black;
  position: relative;
  @media screen and (max-width: 540px) {
    height: 50%; 
  }
`;

const LowerBox = styled('div')`
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  position: relative;
  @media screen and (max-width: 540px) {
    height: 50%;
  }
`;

const LowerBoxColumn = styled('div')`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-top: 20px;
`;

const LowerBoxBadge = styled('div')`
  display: inline-block;
  padding: 4px 12px;
  border-radius: 0px 0px 8px 0px;
  background: #FF8533;
  position: absolute;
  top:0;
  left: 0;
  color:  #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 130%; /* 20.8px */
  letter-spacing: 1.28px;
`;

const RowOfColumns = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  @media screen and (max-width: 540px) {
    padding-bottom: 175px;
    flex-direction: column;
  }
`;

const LowerBoxTitle = styled('div')`
  width: 100%;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
  background: ${(props) => (!props.isCore
    ? 'linear-gradient(270deg, #6A82FB 60%, #FC5C7D 100%)'
    : 'linear-gradient(to right, #667eea, #764ba2)')};
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  transition: color 0.3s ease;
  position: relative;
  margin-top: 20px;

  @media screen and (min-width: 540px) {
    font-size: 41px;
    line-height: 110%;
  }
`;

const LowerBoxCoreDisclaimer = styled('div')`
color: #444;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Nunito Sans';
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 150%;
margin-top: 5px;
`;

const LowerBoxColumnIcon = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  gap: 4px;
  width: 40px;
  height: 40px;
  background: #E0E0E0;
  border-radius: 10000px;
`;

const LowerBoxColumnDescription = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin-top: 10px;
`;

const LowerBoxColumnTitle = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  margin-top: 10px;
`;

const RemoveButton = styled('div')`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  @media screen and (min-width: 540px) {
    display: none;
  }
`;

const ModalCancelButton = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10000px;
  height: 40px;
  width: ${(props) => (props.isPaid ? '0%' : '49%')};
  margin-bottom: 5px;
  color: white;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
  border-image: linear-gradient(45deg, purple, orange) 1;
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(90deg, rgba(247, 183, 51, 1), rgba(252, 74, 26, 1)) border-box;
  border: ${(props) => (props.isPaid ? '0px' : '3px solid transparent')};
  color: #FF8533;
  opacity: ${(props) => (props.isPaid ? '0' : '1')};
  transition: all ${transitionSpeed}s ease;
  @media screen and (max-width: 540px) {
    min-width: 100%;
  }

  &:hover {
    opacity: 0.7;
  }
`;

const CoreText = styled('div')`
color: #563FD8;
font-feature-settings: 'clig' off, 'liga' off;
font-family: 'Nunito Sans';
font-size: 13px;
font-style: normal;
font-weight: 700;
line-height: 150%;
display: inline;

&:hover {
  cursor: pointer;
  text-decoration: underline;
}
`;

const BillingModal = () => {
  const dispatch = useDispatch();
  const isUpgradeModalShowing = useSelector(
    (state) => state.sportsScience.data.isUpgradeModalShowing,
  );
  const subscriptionPackage = useSelector((state) => state.sportsScience.data.subscriptionPackage);
  const selectedPackage = useSelector((state) => state.sportsScience.data.selectedPackage);
  const subscriptionStatus = useSelector((state) => state.sportsScience.data.subscriptionStatus);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const reactPlayerRef = useRef(null);

  useEffect(() => {
    if (!isUpgradeModalShowing && reactPlayerRef?.current) {
      reactPlayerRef?.current?.getInternalPlayer()?.pauseVideo();
    }
  }, [isUpgradeModalShowing]);

  return (
    <ModalContainer isActive={isUpgradeModalShowing}>
      <ContentContainer>
        <UpperBox>
          <RemoveButton onClick={() => dispatch(setIsUpgradeModalShowing(false))}>
            <IcomoonReact
              iconSet={iconSet}
              size={25}
              icon='remove'
              color='white'
            />
          </RemoveButton>
          <VideoContainer>
            <ReactPlayer
              url={selectedPackage?.videoUrl ?? ''}
              controls
              width='100%'
              height={339}
              ref={reactPlayerRef}
            />
          </VideoContainer>
        </UpperBox>
        <LowerBox>
          {selectedPackage?.title !== 'AMS Lite Core Package' && (
            <LowerBoxBadge>
              {selectedPackage?.core ? 'INCLUDED IN CORE' : 'ADD ON FEATURES' }
            </LowerBoxBadge>
          )}
          <LowerBoxTitle isCore={selectedPackage?.core}>
            {selectedPackage?.title ?? ''}
          </LowerBoxTitle>
          {!subscriptionStatus?.packageSubscriptionStatus?.isSubscribed
            && !selectedPackage?.core && (
            <LowerBoxCoreDisclaimer isCore={selectedPackage?.core}>
              Requires
              <CoreText onClick={() => {
                setTimeout(() => {
                  dispatch(setIsUpgradeModalShowing(true));
                }, 500);
                batch(() => {
                  dispatch(setIsUpgradeModalShowing(false));
                  dispatch(setSelectedPackage(corePackage));
                  dispatch(fetchSubscriptionPackage(currentUser.accountCode, 1));
                });
              }}
              >
                {' '}
                AMS Lite Core
                {' '}
              </CoreText>
              subscription before purchase
            </LowerBoxCoreDisclaimer>
          )}
          <RowOfColumns>
            {selectedPackage && selectedPackage?.sections?.map((section) => (
              <LowerBoxColumn key={section.name}>
                <LowerBoxColumnIcon>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={25}
                    icon={section.icon}
                    color='#444444'
                  />
                </LowerBoxColumnIcon>
                <LowerBoxColumnTitle>
                  {section?.name}
                </LowerBoxColumnTitle>
                <LowerBoxColumnDescription>
                  {section?.description}
                </LowerBoxColumnDescription>
              </LowerBoxColumn>
            ))}
          </RowOfColumns>
        </LowerBox>
        <Buttons>
          <ModalNextButton
            onClick={() => {
              if (currentUser?.userSettings?.billing === 0) { // billing access is disabled
                window.open(`${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/billing`);
              } else {
                // Tracks which modal a user started the upgrade process from
                subscriptionFlowTracker('Upgrade Modal Started', selectedPackage.title, 'none');
                dispatch(setIsUpgradeModalShowing(false));
                if (subscriptionPackage?.redirect) {
                  window.open(subscriptionPackage?.url);
                } else {
                  dispatch(setIsInstantPaymentModalShowing(true));
                }
              }
            }}
          >
            UPGRADE
          </ModalNextButton>
          <ModalCancelButton
            onClick={() => {
              // Tracks which modal a user booked a demo from
              subscriptionFlowTrackerDemo('Modal', selectedPackage);
              openPopupWidget({
                url: 'https://calendly.com/teambuildr/15-minute-sports-science-hub',
              });
            }}
          >
            BOOK A DEMO
          </ModalCancelButton>
        </Buttons>
      </ContentContainer>
    </ModalContainer>
  );
};

export default BillingModal;
