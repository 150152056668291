/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import Helmet from 'react-helmet';
import { batch, useDispatch, useSelector } from 'react-redux';
import IcomoonReact from 'icomoon-react';
import { Prompt } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import ResizeObserver from 'react-resize-observer';

import iconSet from '../../shared/images/teambuildr-selection.json';
import HeaderText from '../../shared/components/HeaderText/HeaderText';
import SharedTemplatesGrid from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplatesGrid';
import { dashboardTracker } from '../../shared/utils/amplitudeHelper';
import {
  fetchLMTemplates,
  setIsDashboardDisplayed,
  setAboutLMDashModal,
  setActiveLMDashboard,
  setDeleteLMDashboardModal,
  setDuplicateLMDashModal,
  setEditLMDashModal,
  setIsNewLMDash,
  setUnsavedLMDashModal,
  setShareLMDashModal,
  setNextLocation,
  fetchReportableGroups,
  fetchQuestionsForAccount,
  fetchQuestionnairesForAccount,
  clearActiveLMDashboard,
  setGroups,
  setCurrentGroupInGroupPicker,
  setCurrentAthleteInAthletePicker,
  setCurrentDateInDashDatePicker,
  fetchQuestionnaireReportSingleDay,
  fetchLoadMonitoringLineChartData,
  clearLMDashModals,
} from './ducks/loadMonitoringDashboardActions';
import NewDashboardComponent from './NewDashboardComponent';
import SharedTemplateTriOptionMenuButton from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplateTriOptionMenuButton';
import GeneratedDataPage from './components/presentational/GeneratedDataPage';
import SharedTemplateAboutModal from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplateAboutModal';
import SharedTemplateDeleteModal from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplateDeleteModal';
import SharedTemplateDuplicateModal from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplateDuplicateModal';
import SharedTemplateEditModal from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplateEditModal';
import SharedTemplateSaveModal from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplateSaveModal';
import SharedTemplateShareModal from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplateShareModal';
import SharedTemplateTriOptionModal from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplateTriOptionModal';

const Container = styled('div')`
  width: 100%;
`;

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const DashboardHeader = styled('div')`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.bgColor};
  padding-top: 30px;
  padding-left: 20px;
  justify-content: space-between;
  align-items: flex-end;
`;

const MenuContainer = styled('div')`
  display: flex;
`;

const MenuItem = styled('button')`
  border: none;
  margin-left: 10px;
  margin-right: 12px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-size: 16px;
  border-bottom: ${(props) => (props.isActive ? '3px solid #1787FB!important' : '3px solid white')};
  color: ${(props) => (!props.isActive ? '#9E9E9E' : '#444444')};
`;

const SearchContainer = styled('input')`
  display: flex;
  height: 40px;
  border: none;
  background: none;
  padding-left: 5px;
`;

const SearchAndIcon = styled('div')`
  display: flex;
  align-items: center;
  border: ${(props) => (props.isFocused ? '1px solid   #5F8BDC' : '1px solid #EEEEEE')};
  border-radius: 50px;
  background: white;
  transition: max-width 0.5s ease;
  max-width: ${(props) => (props.isFocused ? '250px' : '40px')};
  overflow: hidden;
`;

const IconsAndSearch = styled('div')`
  display: flex;
  align-items: center;
`;

const HeaderWidthContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SearchIconContainer = styled('div')`
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
`;

const HeaderTextAndArrow = styled('div')`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;

const BackButtonContainer = styled('div')`
  cursor: pointer;
`;

const GeneratedDataContainer = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: ${(props) => (props.containerWidth < 424 ? '0px' : '15px')};
`;

const LoadMonitoringDashboard = () => {
  const dispatch = useDispatch();
  const pageRef = useRef(null);
  const formikRef = useRef(null);
  const theme = useTheme();

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  const reportableGroups = useSelector(
    (state) => state.loadMonitoringDashboard.data.reportableGroups,
  );
  const isDashboardDisplayed = useSelector(
    (state) => state.loadMonitoringDashboard.ui.isDashboardDisplayed,
  );
  const nextLocation = useSelector(
    (state) => state.loadMonitoringDashboard.data.nextLocation,
  );
  const activeTemplate = useSelector(
    (state) => state.loadMonitoringDashboard.data.activeTemplate,
  );
  const isNewDash = useSelector(
    (state) => state.loadMonitoringDashboard.ui.isNewDash,
  );
  const loadMonitoringTemplates = useSelector(
    (state) => state.loadMonitoringDashboard.data.templates,
  );
  const dayRange = useSelector(
    (state) => state.loadMonitoringDashboard.data.dayRange,
  );
  const aboutModalShowing = useSelector(
    (state) => state.loadMonitoringDashboard.ui.aboutModalShowing,
  );
  const deleteModalShowing = useSelector(
    (state) => state.loadMonitoringDashboard.ui.deleteModalShowing,
  );
  const duplicateModalShowing = useSelector(
    (state) => state.loadMonitoringDashboard.ui.duplicateModalShowing,
  );
  const editModalShowing = useSelector(
    (state) => state.loadMonitoringDashboard.ui.editModalShowing,
  );
  const saveModalShowing = useSelector(
    (state) => state.loadMonitoringDashboard.ui.saveModalShowing,
  );
  const shareModalShowing = useSelector(
    (state) => state.loadMonitoringDashboard.ui.shareModalShowing,
  );
  const triOptionModalShowing = useSelector(
    (state) => state.loadMonitoringDashboard.ui.triOptionModalShowing,
  );
  const loadMonitoringFormValues = useSelector(
    (state) => state.loadMonitoringDashboard.data.loadMonitoringFormValues,
  );

  const [activePage, setActivePage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [containerWidth, setContainerWidth] = useState({});

  useEffect(() => {
    dispatch(setIsNewLMDash(false));
    dispatch(setIsDashboardDisplayed(false));
    dispatch(clearLMDashModals());
  }, []);

  useEffect(() => {
    if (!isDashboardDisplayed && !isNewDash && currentUser) {
      dispatch(fetchReportableGroups(currentUser.accountCode));
    }
  }, [isDashboardDisplayed, isNewDash, currentUser]);

  useEffect(() => {
    /** on page load, fetch groups, questions and questionnaires associated with account
     */
    if (currentUser?.id) {
      batch(() => {
        dispatch(fetchLMTemplates(accountCode));
        dispatch(fetchReportableGroups(accountCode));
        dispatch(fetchQuestionsForAccount(accountCode));
        dispatch(fetchQuestionnairesForAccount(accountCode));
      });
    }
  }, [currentUser]);

  // Handle scrolling back to top for once the dashboard is displayed
  const scrollTop = () => {
    setTimeout(
      () => pageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      }),
      250,
    );
  };

  useEffect(() => {
    if (isDashboardDisplayed) {
      scrollTop();
    }
  }, [isDashboardDisplayed]);

  const unsavedDash = isDashboardDisplayed && !activeTemplate;

  // Handles if a user tries to leave the site, or navigate to PHP page, with an unsaved dashboard
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();

      // Note, custom messages do not work for Chrome
      const customMessage = `This dashboard has not been saved, are you sure you want to exit without saving?
      Exiting now will discard any unsaved progress. If you have made any updates,
      close this modal to review and save your changes.`;

      return customMessage;
    };

    if (unsavedDash) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedDash]);

  // Handles if a user tries to navigate to another React page with an unsaved dashboard
  const handleNavigation = (location) => {
    if (unsavedDash && !nextLocation) {
      batch(() => {
        dispatch(setNextLocation(location.pathname));
        dispatch(setUnsavedLMDashModal(true));
      });
      return false; // Prevent navigation
    }
    return true; // Allow navigation
  };

  // Handles returning to dashboard landing screen
  const handleReturnClick = () => {
    if (isNewDash && !isDashboardDisplayed) {
      // When filling out new dashboard form
      if (activePage === 1) {
        // Return to landing page from form page 1
        dispatch(setIsNewLMDash(false));
        // KPI tracking
        dashboardTracker(
          'Load Monitoring Dashboard',
          'Back Arrow',
          'Return to main screen clicked from new dashboard form',
        );
      } else {
        // Return to form page 1 from form page 2
        setActivePage(activePage - 1);
        if (formikRef.current) {
          formikRef.current.resetForm();
          formikRef.current.setErrors({});
          formikRef.current.setTouched({});
        }
        // KPI tracking
        dashboardTracker(
          'Load Monitoring Dashboard',
          'Back Arrow',
          'Return to form page one clicked from new dashboard form',
        );
      }
    } else if (unsavedDash) {
      // When viewing unsaved new dashboard - activePage === 3 and isDashboardDisplayed === true
      if (formikRef.current) {
        formikRef.current.setErrors({});
        formikRef.current.setTouched({});
        const newQuestionIds = loadMonitoringFormValues.arrayOfQuestions.map(
          (questionObj) => ({
            id: questionObj.id,
            title: questionObj.title,
          }),
        );

        // Fill questionIds with empty objects until the length is 5
        while (newQuestionIds.length < 5) {
          newQuestionIds.push({ title: '', id: '' });
        }

        if (Object.keys(loadMonitoringFormValues).length) {
          formikRef.current.setValues({
            reportType: loadMonitoringFormValues.reportType,
            selectionType: loadMonitoringFormValues.selectionType,
            date: loadMonitoringFormValues.date,
            arrayOfQuestions: loadMonitoringFormValues.arrayOfQuestions,
            questionIds: newQuestionIds,
            questionnaire: loadMonitoringFormValues.questionnaire,
            showRangeAverages: loadMonitoringFormValues.showRangeAverages,
            showTotal: loadMonitoringFormValues.showTotal,
            dayRange: loadMonitoringFormValues.dayRange,
          });
        }
        setActivePage(2);
        dispatch(setIsDashboardDisplayed(false));
      }
      // KPI tracking
      dashboardTracker(
        'Load Monitoring Dashboard',
        'Back Arrow',
        'Return to form clicked from unsaved dashboard',
      );
      // batch(() => {
      //   dispatch(setNextLocation('/sports-science/load-monitoring-dashboard'));
      //   dispatch(setUnsavedLMDashModal(true));
      // });
    } else {
      // When viewing saved template
      batch(() => {
        dispatch(clearActiveLMDashboard());
        dispatch(setIsDashboardDisplayed(!isDashboardDisplayed));
      });
      // KPI tracking
      dashboardTracker(
        'Load Monitoring Dashboard',
        'Back Arrow',
        'Return to main screen clicked from saved dashboard template',
      );
    }
  };

  const handleCreateNewDashboard = () => {
    // KPI tracking
    dashboardTracker(
      'Force Plate Dashboard',
      'Main Screen',
      'Create new dashboard clicked',
    );
    batch(() => {
      dispatch(clearActiveLMDashboard());
      dispatch(setCurrentGroupInGroupPicker({}));
      dispatch(setIsNewLMDash(true));
    });
  };

  const isOwner = (template) => currentUser?.id === template?.createdBy;

  const fetchReport = (template) => {
    /** fetch questionnaire report for single day on default load */
    /** template with group access marked private will not have groups;
     * owners should still be able to view and default to first group
     * in array of reportableGroups */
    const defaultGroup = (!!template.groups.length && template.groups[0])
      || (isOwner(template) && reportableGroups[0]);
    if (defaultGroup.id) {
      const filterType = '2';
      const filterIds = [defaultGroup.id];
      const currentDate = new Date();
      const date = {
        date: currentDate,
        queryFormat: currentDate.toISOString().slice(0, 10),
      };
      dispatch(
        setCurrentGroupInGroupPicker({
          ...defaultGroup,
          displayText: defaultGroup.name,
        }),
      );
      dispatch(
        setCurrentAthleteInAthletePicker({
          ...defaultGroup,
          displayText: defaultGroup.name,
          isAthlete: false,
        }),
      );
      dispatch(setCurrentDateInDashDatePicker(date));
      const values = {
        questionnaire: template.questionnaire,
        arrayOfQuestions: template.questions,
        showRangeAverages: template.showAverages,
        showTotal: template.showTotals,
        date,
        dayRange,
      };
      dispatch(
        fetchQuestionnaireReportSingleDay(
          accountCode,
          filterType,
          filterIds,
          values,
        ),
      );
      dispatch(
        fetchLoadMonitoringLineChartData(
          accountCode,
          filterType,
          filterIds,
          values,
        ),
      );
      dispatch(setIsDashboardDisplayed(true));
    }
  };

  const handleTemplateView = (template) => {
    // KPI tracking
    dashboardTracker(
      'Load Monitoring Dashboard',
      'Main Screen',
      'Saved dashboard opened',
    );
    batch(() => {
      dispatch(setActiveLMDashboard(template));
      dispatch(setCurrentGroupInGroupPicker({}));
      if (template.groups.length || !isOwner(template)) {
        /** Dictates template group picker items
         * If group access is private, template owner will have
         * access to all their reportable groups; non-owners shouldn't have any access
         * Otherwise, groups will be intersection of template groups
         * and user's reportable group access */
        dispatch(setGroups(template.groups));
      }
    });
    setTimeout(() => {
      fetchReport(template);
    }, [500]);
  };

  const templateActions = {
    handleCreateNewDashboard,
    handleTemplateView,
    setDeleteModal: setDeleteLMDashboardModal,
    setAboutModal: setAboutLMDashModal,
    setEditModal: setEditLMDashModal,
    setDuplicateModal: setDuplicateLMDashModal,
    setShareModal: setShareLMDashModal,
    clearActiveTemplate: clearActiveLMDashboard,
  };

  return (
    <Container
      onClick={(e) => {
        if (
          e.target.id !== 'search-container'
          && e.target.id !== 'search-icon-container'
          && e.target.id !== 'search-container-input'
          && e.target.id !== 'mag-icon'
          && isSearchFocused
          && searchTerm.length === 0
        ) {
          setIsSearchFocused(false);
        }
      }}
    >
      {aboutModalShowing && (
        <SharedTemplateAboutModal dashboard='Load Monitoring' />
      )}
      {deleteModalShowing && (
        <SharedTemplateDeleteModal dashboard='Load Monitoring' />
      )}
      {duplicateModalShowing && (
        <SharedTemplateDuplicateModal dashboard='Load Monitoring' />
      )}
      {editModalShowing && (
        <SharedTemplateEditModal dashboard='Load Monitoring' />
      )}
      {saveModalShowing && (
        <SharedTemplateSaveModal dashboard='Load Monitoring' />
      )}
      {shareModalShowing && (
        <SharedTemplateShareModal dashboard='Load Monitoring' />
      )}
      {triOptionModalShowing && (
        <SharedTemplateTriOptionModal dashboard='Load Monitoring' />
      )}
      <Helmet>
        <title>Load Monitoring Dashboard | TeamBuildr</title>
      </Helmet>
      <PageWrapper ref={pageRef}>
        <DashboardHeader bgColor={theme.colors.background[0]}>
          <HeaderWidthContainer>
            <HeaderTextAndArrow>
              {isDashboardDisplayed || isNewDash ? (
                <BackButtonContainer>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={20}
                    icon='left-arrow'
                    onClick={handleReturnClick}
                  />
                </BackButtonContainer>
              ) : null}
              <HeaderText
                fontWeight='300'
                letterSpacing='normal'
                style={{
                  marginLeft: 10,
                }}
              >
                Load Monitoring Dashboard
              </HeaderText>
            </HeaderTextAndArrow>
            {!isDashboardDisplayed && !isNewDash ? (
              <MenuContainer>
                <IconsAndSearch>
                  <MenuItem>
                    <SearchAndIcon
                      id='search-container'
                      onClick={() => setIsSearchFocused(true)}
                      isFocused={isSearchFocused}
                    >
                      <SearchIconContainer id='search-icon-container'>
                        <IcomoonReact
                          iconSet={iconSet}
                          size={20}
                          icon='magnifying-glass-alt'
                          id='mag-icon'
                        />
                      </SearchIconContainer>
                      <SearchContainer
                        placeholder='Search'
                        id='search-container-input'
                        value={searchTerm}
                        onFocus={() => setIsSearchFocused(true)}
                        onChange={(inputValue) => {
                          setSearchTerm(inputValue.target.value);
                        }}
                      />
                    </SearchAndIcon>
                  </MenuItem>
                </IconsAndSearch>
              </MenuContainer>
            ) : null}
          </HeaderWidthContainer>
        </DashboardHeader>
        {!isDashboardDisplayed && !isNewDash && (
          <SharedTemplatesGrid
            searchTerm={searchTerm}
            dashboard='Load Monitoring'
            templates={loadMonitoringTemplates}
            actions={templateActions}
          />
        )}
        {isDashboardDisplayed && activeTemplate && (
          <GeneratedDataContainer containerWidth={containerWidth}>
            <ResizeObserver
              onResize={(rect) => {
                setContainerWidth(rect?.width);
              }}
            />
            <GeneratedDataPage containerWidth={containerWidth} />
          </GeneratedDataContainer>
        )}
        {isNewDash && (
          <NewDashboardComponent
            activePage={activePage}
            setActivePage={setActivePage}
            formikRef={formikRef}
          />
        )}
        {isDashboardDisplayed && (
          <SharedTemplateTriOptionMenuButton dashboard='Load Monitoring' />
        )}
      </PageWrapper>

      <Prompt when={unsavedDash} message={handleNavigation} />
    </Container>
  );
};

export default LoadMonitoringDashboard;
