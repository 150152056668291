/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import Shimmer from 'react-shimmer-effect';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import SharedSingleDayQuestionnaireGrid from '../../../../shared/components/SportsHub/SharedSingleDayQuestionnaireGrid';
import SharedMultiDayQuestionnaireGrid from '../../../../shared/components/SportsHub/SharedMultiDayQuestionnaireGrid';
import DownloadReportButton from './DownloadReportButton';
import {
  setCurrentQuestionInQuestionnairePicker,
  setCurrentAthleteInAthletePicker,
  setAthletesInClickedZone,
  fetchPublicLMQuestionnaireReportMultiDay,
  fetchPublicLMQuestionnaireReportSingleDay,
  fetchPublicLMLineChartData,
} from '../../ducks/loadMonitoringDashboardActions';
import PickerAndDonutChart from './PickerAndDonutChart';
import Picker from '../../../../shared/components/SportsHub/Picker';
import TBDatePicker from '../../../../shared/components/TBDatePicker/TBDatePicker';
import Text from '../../../../shared/components/Text/Text';
import LoadMonitoringComposedChart from './LoadMonitoringComposedChart';
import SpiderChart from './SpiderChart';
import CheckboxFilterComponent from './CheckboxFilterComponent';
import WarningComponent from './WarningComponent';
import dummyAthleteData from './dummyAthleteData';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const MainContainer = styled('main')`
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;
`;

const GraphAndPickersContainer = styled('div')`
  display: flex;
  flex-direction: column;
  background: ${(props) => (props.containerWidth < 424 ? '#F5F3F3' : '#F5F3F3')};
  border-radius: 20px;
  margin-bottom: 30px;
  gap: 20px;
`;

const PickerHeaderRow = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
`;

const DaySelector = styled('div')`
  display: flex;
  padding: 8px 10px;
  border-radius: 6px;
  box-shadow: ${BoxShadow};
  margin-right: 10px;
  background-color: #fff;
  max-width: max-content;
`;

const DayOption = styled('button')`
  border: none;
  font-family: 'Nunito Sans';
  font-size: 16px;
  color: ${(props) => (!props.isActive ? '#9E9E9E' : null)};
  cursor: default !important;
`;

const DaySpacer = styled('span')`
  color: #9e9e9e;
  font-family: 'Nunito Sans';
  font-size: 16px;
  margin: 0px 5px;
`;

const PickerHeaderSubContainerRow = styled('div')`
  display: flex;
  flex-direction: row;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 8px 16px rgba(48, 49, 51, 0.1);
  border-radius: 24px;
  width: ${(props) => props.containerWidth < 575 && '100%'};
  max-width: ${(props) => (props.containerWidth < 1035 && props.containerWidth > 575
    ? '240px'
    : 'max-content')};
  padding: ${(props) => (props.containerWidth < 301
    ? '15px'
    : props.containerWidth > 301 && props.containerWidth < 1035
      ? '25px'
      : '10px 25px')};
  align-items: center;
  gap: ${(props) => (props.containerWidth < 1035 ? '30px' : '15px')};
  flex-wrap: wrap;
  flex-grow: 1;
`;

const AthletePickerAndSelectedQuestionsSubRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
`;

const PickerAndDescriptionContainer = styled('div')`
  display: flex;
  flex-direction: ${(props) => (props.containerWidth < 414 ? 'column' : 'row')};
  align-items: ${(props) => props.containerWidth >= 414 && 'center'};
  gap: ${(props) => (props.containerWidth < 424 ? '0px' : '15px')};
  flex-wrap: wrap;
  width: ${(props) => props.containerWidth < 1035 && '100%'};
  justify-content: ${(props) => props.containerWidth < 1035 && 'space-between'};
`;

const RowWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 40px;
  flex-wrap: ${(props) => props.containerWidth < 850 && 'wrap'};
  padding-bottom: 20px;
`;

const BodyRow = styled('div')`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
  height: max-content;
  overflow-x: ${(props) => (props.containerWidth < 510 ? 'scroll' : 'hidden')};
  background: #f5f3f3;
`;

const ChartRow = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const RelativelyPositionedRowWrapperForScrollIndicator = styled('div')`
  display: flex;
  flex-direction: row;
  height: max-content;
  position: relative;
  height: max-content;
  overflow-x: hidden;
`;

const GridTitleRow = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 30px;
`;

const GridLoadingShimmerPlaceHolder = styled('div')`
  display: flex;
  flex-direction: row;
  height: 400px;
  width: 100vw;
  border-radius: 20px;
`;

const PlaceHolderForAthletePickerShimmerLoading = styled('div')`
  min-width: 150px;
  height: 21px;
  border-radius: 12px;
  padding: 8px;
  background-color: #ff6600;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 4px 8px rgba(48, 49, 51, 0.1);
`;

const PickerDonutChartAndDownloadContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-top: ${(props) => props.containerWidth >= 850
    && props.columnHeaderHeight
    && `${props.columnHeaderHeight}px`};
  width: ${(props) => props.containerWidth < 850 && '100%'};
`;

const BadgeButton = styled('button')`
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'white')};
  border: none;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 12px;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 4px 8px rgba(48, 49, 51, 0.1);
  display: flex;
  max-width: fit-content;
  align-items: center;
  gap: 15px;
`;

const TitleAndBadgeContainer = styled('div')`
  display: flex;
  gap: ${(props) => (props.isAthlete ? '0px' : '15px')};
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.isAthlete ? 'column' : 'row')};
`;

const ViewOnlyGeneratedData = ({ containerWidth, token }) => {
  const dispatch = useDispatch();
  const gridRowRef = useRef(null);
  const isLoadingQuestionnaireReportSingleDay = useSelector(
    (state) => state.loadMonitoringDashboard.ui.isLoadingQuestionnaireReportSingleDay,
  );
  const isLoadingQuestionnaireReportMultiday = useSelector(
    (state) => state.loadMonitoringDashboard.ui.isLoadingQuestionnaireReportMultiday,
  );
  const athletesAssociatedWithCurrentGroup = useSelector(
    (state) => state.loadMonitoringDashboard.data?.athletesAssociatedWithCurrentGroup,
  );

  const lineChartData = useSelector(
    (state) => state.loadMonitoringDashboard.data.loadMonitoringLineChartData,
  );

  const athletesInClickedZone = useSelector(
    (state) => state.loadMonitoringDashboard.data.athletesInClickedZone,
  );
  const currentQuestionInDonutChartQuestionnairePicker = useSelector(
    (state) => state.loadMonitoringDashboard.data
      .currentQuestionInDonutChartQuestionnairePicker,
  );
  const thresholdFilterColor = athletesInClickedZone.length
    && athletesInClickedZone[0].questions.filter(
      (question) => question.id === currentQuestionInDonutChartQuestionnairePicker.id,
    )[0].response.backgroundColor;

  /** state to track for dash change Api calls */
  const currentDateInDashDatePicker = useSelector(
    (state) => state.loadMonitoringDashboard.data.currentDateInDashDatePicker,
  );
  const currentGroupInGroupPicker = useSelector(
    (state) => state.loadMonitoringDashboard.data.currentGroupInGroupPicker,
  );
  const currentAthleteInAthletePicker = useSelector(
    (state) => state.loadMonitoringDashboard.data.currentAthleteInAthletePicker,
  );
  const currentReportType = useSelector(
    (state) => state.loadMonitoringDashboard.data.currentReportType,
  );
  const reportableGroups = useSelector(
    (state) => state.loadMonitoringDashboard.data.reportableGroups,
  );
  /** props to pass to grids */
  const dataRows = useSelector(
    (state) => state.loadMonitoringDashboard.data.questionnaireReportData.dataRows,
  );
  const filteredDataRows = dataRows?.filter(
    (athlete) => athletesInClickedZone.indexOf(athlete) !== -1,
  );

  const teamAverageTotals = useSelector(
    (state) => state.loadMonitoringDashboard.data.questionnaireReportData
      .teamAverageTotals,
  );
  const questionnaire = useSelector(
    (state) => state.loadMonitoringDashboard.data.readOnlyQuestionnaire,
  );
  const readOnlyQuestions = useSelector(
    (state) => state.loadMonitoringDashboard.data.readOnlyQuestions,
  );
  const showAverages = useSelector(
    (state) => state.loadMonitoringDashboard.data.readOnlyShowAverages,
  );
  const showTotals = useSelector(
    (state) => state.loadMonitoringDashboard.data.readOnlyShowTotals,
  );
  const apiUnformattedData = useSelector(
    (state) => state.loadMonitoringDashboard.data.questionnaireReportData.data,
  );
  const dayRange = useSelector(
    (state) => state.loadMonitoringDashboard.data.dayRange,
  );
  const isReadOnlyView = useSelector(
    (state) => state.loadMonitoringDashboard.ui.isReadOnlyView,
  );

  const [columnHeaderHeight, setColumnHeaderHeight] = useState(null);

  const [rowIsScrollable, setIsRowScrollable] = useState(false);

  const emptyAthleteTableData = dummyAthleteData(
    readOnlyQuestions,
    currentAthleteInAthletePicker,
  );

  const handleScroll = () => {
    /** targetting the grid row container div scroll properties */
    const scrollWidth = gridRowRef.current?.scrollWidth;
    const offsetWidth = gridRowRef.current?.offsetWidth;
    const scrollLeft = gridRowRef.current?.scrollLeft;
    setIsRowScrollable(offsetWidth + scrollLeft !== scrollWidth);
  };

  React.useEffect(() => {
    if (gridRowRef.current) {
      const scrollWidth = gridRowRef.current?.scrollWidth;
      const offsetWidth = gridRowRef.current?.offsetWidth;
      const scrollLeft = gridRowRef.current?.scrollLeft;
      setIsRowScrollable(offsetWidth + scrollLeft !== scrollWidth);
    }
  }, [containerWidth]);

  const formattedListOfGroupsForGroupPicker = reportableGroups?.map((group) => {
    const obj = { ...group, displayText: group.name };
    return obj;
  });

  const formattedListOfAthletesForAthletePicker = athletesAssociatedWithCurrentGroup?.map(
    (athlete) => {
      const obj = { ...athlete, displayText: athlete.name, isAthlete: true };
      return obj;
    },
  );

  const formattedCurrentGroupInGroupPicker = {
    ...currentGroupInGroupPicker,
    displayText: currentGroupInGroupPicker.name,
  };

  const formattedListForSelectedQuestionsPicker = [
    {
      displayText: questionnaire ? questionnaire.title : 'Individual Questions',
      id: questionnaire ? 'selectAllQuestions' : 'selectIndividualQuestions',
    },
  ];

  /** any time the user changes the athlete, we need
   * to make another call to the one of the grid APIs to get the updated data
   * to do this we will set up a function called handleGridAPICall()=> this function will check if the current athlete in the picker
   * if the current athlete in the picker is a group, then it will make a call to the single day grid
   * if the current ahtlete in the picker is actually an athlete, then it will make a call to the multiday grid
   * athletes will have a userId
   */

  // Adapt function to only utilize token and, if item.isAthlete, the athlete's userId
  const handleGridAPICall = (item) => {
    if (item?.isAthlete) {
      // Amplitude tracking
      dashboardTracker(
        'Load Monitoring Dashboard',
        'View Only Generated Data Page',
        'Athlete data fetched',
      );
      dispatch(fetchPublicLMQuestionnaireReportMultiDay(token, item.userId));
      /** everytime we make a call to the grid api with changes we also need to
       * make a call to the line chart to reflect those chages
       */
      dispatch(fetchPublicLMLineChartData(token, item.userId));
    } else {
      // Amplitude tracking
      dashboardTracker(
        'Load Monitoring Dashboard',
        'View Only Generated Data Page',
        'Group data fetched',
      );
      dispatch(fetchPublicLMQuestionnaireReportSingleDay(token));
      /** everytime we make a call to the grid api with changes we also need to
       * make a call to the line chart to reflect those chages
       */
      dispatch(fetchPublicLMLineChartData(token));
    }
  };

  // View only disables change of group, athlete, date, and day range
  const emptyFunction = () => undefined;

  const functionForAthletePicker = (item) => {
    // Amplitude tracking
    dashboardTracker(
      'Load Monitoring Dashboard',
      'View Only Athlete Picker',
      'Athlete selection changed',
    );
    dispatch(setCurrentAthleteInAthletePicker(item));
    handleGridAPICall(item);
  };

  /** clear the threshold filter everytime the user manipulates a data point */
  useEffect(() => {
    dispatch(setAthletesInClickedZone([]));
  }, [
    currentQuestionInDonutChartQuestionnairePicker,
    currentGroupInGroupPicker,
    currentAthleteInAthletePicker,
    currentDateInDashDatePicker,
  ]);

  return (
    <MainContainer containerWidth={containerWidth}>
      {containerWidth < 1035 ? (
        <GraphAndPickersContainer containerWidth={containerWidth}>
          <PickerHeaderRow
            style={{ flexWrap: containerWidth < 575 ? 'wrap' : 'nowrap' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                height: containerWidth > 574 ? '100%' : 'fit-content',
              }}
            >
              <TBDatePicker
                activeDate={currentDateInDashDatePicker?.date}
                setActiveDate={emptyFunction}
                disabled
                additionalStyles={{
                  justifyContent: 'flex-start',
                  margin: '0px',
                  maxWidth: '240px',
                }}
              />
              <DaySelector>
                <DayOption isActive={dayRange === 7} onClick={emptyFunction}>
                  7D
                </DayOption>
                <DaySpacer> | </DaySpacer>
                <DayOption isActive={dayRange === 28} onClick={emptyFunction}>
                  28D
                </DayOption>
              </DaySelector>
              {(!isReadOnlyView && reportableGroups.length)
              || (isReadOnlyView && currentGroupInGroupPicker?.id) ? (
                <PickerHeaderSubContainerRow containerWidth={containerWidth}>
                  <PickerAndDescriptionContainer
                    containerWidth={containerWidth}
                    style={{
                      borderRight:
                        containerWidth < 1035 ? 'none' : '2px solid #D9D9D9',
                      paddingRight: containerWidth < 1035 ? '0px' : '15px',
                    }}
                  >
                    <Text fontSize='15px' fontWeight={700}>
                      {' '}
                      Group:
                    </Text>
                    <Picker
                      disabled
                      list={
                        isReadOnlyView
                          ? [currentGroupInGroupPicker]
                          : formattedListOfGroupsForGroupPicker
                      }
                      reduxFunction={emptyFunction}
                      visibleContainerItem={
                        currentGroupInGroupPicker?.displayText
                      }
                      pickerButtonColor='#444444'
                      pickerFontColor='white'
                      minWidth={190}
                      height={34}
                      itemPickerContainerMaxWidth={containerWidth < 414 && 200}
                      absoluteLeftPositioningOfItemPickerContainer={
                        containerWidth < 575 && containerWidth >= 414 && -100
                      }
                      addSearchBar
                    />
                  </PickerAndDescriptionContainer>
                  <AthletePickerAndSelectedQuestionsSubRow>
                    <PickerAndDescriptionContainer
                      containerWidth={containerWidth}
                    >
                      <Text fontSize='15px' fontWeight={700}>
                        {' '}
                        Currently Viewing:
                      </Text>
                      {isLoadingQuestionnaireReportMultiday
                      || isLoadingQuestionnaireReportSingleDay ? (
                        <Shimmer>
                          <PlaceHolderForAthletePickerShimmerLoading />
                        </Shimmer>
                        ) : (
                          <Picker
                            list={[
                              { ...formattedCurrentGroupInGroupPicker },
                              ...formattedListOfAthletesForAthletePicker,
                            ]}
                            reduxFunction={functionForAthletePicker}
                            visibleContainerItem={
                            currentAthleteInAthletePicker?.displayText
                          }
                            icon={{ name: 'swap', size: 16, color: '#444444' }}
                            pickerButtonColor='#EEEEEE'
                            pickerFontColor='#444444'
                            small
                            minWidth={150}
                            itemPickerContainerMaxWidth={
                            containerWidth < 420 && 200
                          }
                            absoluteLeftPositioningOfItemPickerContainer={
                            containerWidth < 575
                            && containerWidth >= 414
                            && -100
                          }
                            addSearchBar
                          />
                        )}
                    </PickerAndDescriptionContainer>
                    <PickerAndDescriptionContainer
                      containerWidth={containerWidth}
                    >
                      <Text fontSize='15px' fontWeight={700}>
                        {' '}
                        {questionnaire?.title
                          ? 'Selected Questionnaire:'
                          : 'Selected Questions:'}
                      </Text>
                      <Picker
                        disabled
                        list={formattedListForSelectedQuestionsPicker}
                        reduxFunction={emptyFunction}
                        visibleContainerItem={
                          formattedListForSelectedQuestionsPicker[0].displayText
                        }
                        pickerButtonColor='#EEEEEE'
                        pickerFontColor='#444444'
                        small
                        minWidth={150}
                        itemPickerContainerMaxWidth={
                          containerWidth < 420 && 200
                        }
                        absoluteLeftPositioningOfItemPickerContainer={
                          containerWidth < 575 && containerWidth >= 414 && -100
                        }
                      />
                    </PickerAndDescriptionContainer>
                  </AthletePickerAndSelectedQuestionsSubRow>
                </PickerHeaderSubContainerRow>
                ) : (
                  <WarningComponent
                    containerWidth={containerWidth}
                    message='Oops! Something went wrong. We were not able to find any reportable groups. Please check the status of your user groups and make sure reporting is enabled.'
                  />
                )}
            </div>
            <SpiderChart
              maxHeight='500px'
              alignSelf='flex-end'
              containerWidth={containerWidth}
              showAverages={showAverages}
            />
          </PickerHeaderRow>
          <ChartRow
            style={{ flexWrap: containerWidth < 768 ? 'wrap' : 'nowrap' }}
          >
            <LoadMonitoringComposedChart
              containerWidth={containerWidth}
              lineChartData={lineChartData}
            />
            <CheckboxFilterComponent
              containerWidth={containerWidth}
              renderTooltip={!(containerWidth < 768) && !!lineChartData?.length}
              lineChartData={lineChartData || []}
            />
          </ChartRow>
        </GraphAndPickersContainer>
      ) : (
        <>
          <GraphAndPickersContainer containerWidth={containerWidth}>
            <PickerHeaderRow>
              <TBDatePicker
                activeDate={currentDateInDashDatePicker?.date}
                setActiveDate={emptyFunction}
                disabled
                additionalStyles={{
                  justifyContent: 'flex-start',
                  margin: '0px',
                  maxWidth: '240px',
                }}
              />
              <DaySelector>
                <DayOption isActive={dayRange === 7} onClick={emptyFunction}>
                  7D
                </DayOption>
                <DaySpacer> | </DaySpacer>
                <DayOption isActive={dayRange === 28} onClick={emptyFunction}>
                  28D
                </DayOption>
              </DaySelector>
              {(!isReadOnlyView && reportableGroups.length)
              || (isReadOnlyView && currentGroupInGroupPicker) ? (
                <PickerHeaderSubContainerRow containerWidth={containerWidth}>
                  <PickerAndDescriptionContainer
                    containerWidth={containerWidth}
                    style={{
                      borderRight:
                        containerWidth < 1035 ? 'none' : '2px solid #D9D9D9',
                      paddingRight: containerWidth < 1035 ? '0px' : '15px',
                    }}
                  >
                    <Text fontSize='15px' fontWeight={700}>
                      {' '}
                      Group:
                    </Text>
                    <Picker
                      disabled
                      list={
                        isReadOnlyView
                          ? [currentGroupInGroupPicker]
                          : formattedListOfGroupsForGroupPicker
                      }
                      reduxFunction={emptyFunction}
                      visibleContainerItem={
                        currentGroupInGroupPicker?.displayText
                      }
                      pickerButtonColor='#444444'
                      pickerFontColor='white'
                      minWidth={190}
                      height={34}
                      addSearchBar
                    />
                  </PickerAndDescriptionContainer>
                  <AthletePickerAndSelectedQuestionsSubRow>
                    <PickerAndDescriptionContainer
                      containerWidth={containerWidth}
                    >
                      <Text fontSize='15px' fontWeight={700}>
                        {' '}
                        Currently Viewing:
                      </Text>
                      {isLoadingQuestionnaireReportMultiday
                      || isLoadingQuestionnaireReportSingleDay ? (
                        <Shimmer>
                          <PlaceHolderForAthletePickerShimmerLoading />
                        </Shimmer>
                        ) : (
                          <Picker
                            list={[
                              { ...formattedCurrentGroupInGroupPicker },
                              ...formattedListOfAthletesForAthletePicker,
                            ]}
                            reduxFunction={functionForAthletePicker}
                            visibleContainerItem={
                            currentAthleteInAthletePicker?.displayText
                          }
                            icon={{ name: 'swap', size: 16, color: '#444444' }}
                            pickerButtonColor='#EEEEEE'
                            pickerFontColor='#444444'
                            small
                            minWidth={150}
                            addSearchBar
                          />
                        )}
                    </PickerAndDescriptionContainer>
                    <PickerAndDescriptionContainer
                      containerWidth={containerWidth}
                    >
                      <Text fontSize='15px' fontWeight={700}>
                        {' '}
                        {questionnaire?.title
                          ? 'Selected Questionnaire:'
                          : 'Selected Questions:'}
                      </Text>
                      <Picker
                        disabled
                        list={formattedListForSelectedQuestionsPicker}
                        reduxFunction={emptyFunction}
                        visibleContainerItem={
                          formattedListForSelectedQuestionsPicker[0].displayText
                        }
                        pickerButtonColor='#EEEEEE'
                        pickerFontColor='#444444'
                        small
                        minWidth={containerWidth < 1084 ? 150 : 200}
                        absoluteLeftPositioningOfItemPickerContainer={-100}
                      />
                    </PickerAndDescriptionContainer>
                  </AthletePickerAndSelectedQuestionsSubRow>
                </PickerHeaderSubContainerRow>
                ) : (
                  <WarningComponent
                    containerWidth={containerWidth}
                    message='Oops! Something went wrong. We were not able to find any reportable groups. Please check the status of your user groups and make sure reporting is enabled.'
                  />
                )}
            </PickerHeaderRow>
            <ChartRow>
              <SpiderChart
                maxWidth='275px'
                containerWidth={containerWidth}
                showAverages={showAverages}
              />
              <LoadMonitoringComposedChart
                containerWidth={containerWidth}
                lineChartData={lineChartData}
              />
              <CheckboxFilterComponent
                containerWidth={containerWidth}
                renderTooltip={!!lineChartData?.length}
                lineChartData={lineChartData || []}
              />
            </ChartRow>
          </GraphAndPickersContainer>
        </>
      )}
      {(currentReportType === 'singleDay' && !!dataRows?.length)
      || currentReportType === 'multiDay' ? (
        <>
          <GridTitleRow>
            <TitleAndBadgeContainer
              isAthlete={currentAthleteInAthletePicker?.isAthlete}
            >
              <Text fontWeight={700} fontSize='16px'>
                {' '}
                {currentAthleteInAthletePicker?.isAthlete
                  ? 'Athlete'
                  : 'Group'}
                {' '}
                Report -
                {' '}
                {currentAthleteInAthletePicker?.name}
                {' '}
                {currentAthleteInAthletePicker?.isAthlete
                  ? null
                  : `(${moment(currentDateInDashDatePicker?.date).format(
                    'MMM DD, YYYY',
                  )})`}
              </Text>
              {currentAthleteInAthletePicker?.isAthlete && (
                <Text fontSize='11px' fontWeight={700}>
                  {dataRows?.length
                    ? `Last ${dayRange} days that data was found for current athlete
                  before selected date`
                    : 'No data found for current athlete before selected date. Please adjust your selections above and try again.'}
                </Text>
              )}
              {athletesInClickedZone.length ? (
                <BadgeButton
                  type='button'
                  onClick={() => dispatch(setAthletesInClickedZone([]))}
                  backgroundColor={thresholdFilterColor}
                >
                  <Text color='white' fontSize='12px' fontWeight={700}>
                    Threshold Filter
                  </Text>
                  <IcomoonReact
                    iconSet={iconSet}
                    color='white'
                    size={8}
                    icon='remove'
                  />
                </BadgeButton>
              ) : null}
            </TitleAndBadgeContainer>
            {currentReportType === 'multiDay' && !!dataRows?.length && (
              <DownloadReportButton
                backgroundColor='white'
                textColor='#444444'
                chartName={`Athlete Report - ${currentAthleteInAthletePicker?.name}`}
              />
            )}
          </GridTitleRow>
          <RowWrapper containerWidth={containerWidth}>
            {currentReportType === 'singleDay' && (
              <PickerDonutChartAndDownloadContainer
                columnHeaderHeight={columnHeaderHeight}
                containerWidth={containerWidth}
              >
                <PickerAndDonutChart containerWidth={containerWidth} />
                <DownloadReportButton
                  chartName={`Group Report - ${
                    currentAthleteInAthletePicker?.name
                  } (${moment(currentDateInDashDatePicker?.date).format(
                    'MMM DD, YYYY',
                  )}) `}
                />
              </PickerDonutChartAndDownloadContainer>
            )}
            <RelativelyPositionedRowWrapperForScrollIndicator>
              <BodyRow
                containerWidth={containerWidth}
                ref={gridRowRef}
                onScroll={handleScroll}
                rowIsScrollable={rowIsScrollable}
              >
                {isLoadingQuestionnaireReportMultiday
                || isLoadingQuestionnaireReportSingleDay ? (
                  <Shimmer>
                    <GridLoadingShimmerPlaceHolder />
                  </Shimmer>
                  ) : (
                    <>
                      {currentReportType === 'singleDay' && (
                      <SharedSingleDayQuestionnaireGrid
                        setColumnHeaderHeight={setColumnHeaderHeight}
                        containerWidth={containerWidth}
                        dataRows={
                          athletesInClickedZone?.length
                            ? filteredDataRows
                            : dataRows
                        }
                        teamAverageTotals={teamAverageTotals}
                        showAverages={showAverages}
                        showTotals={showTotals}
                        setCurrentQuestionInDonutChartQuestionnairePicker={
                          setCurrentQuestionInQuestionnairePicker
                        }
                        rowIsScrollable={rowIsScrollable}
                        athletesInClickedZone={athletesInClickedZone}
                      />
                      )}
                      {currentReportType === 'multiDay' && (
                      <>
                        <SharedMultiDayQuestionnaireGrid
                          containerWidth={containerWidth}
                          dataRows={
                            dataRows?.length ? dataRows : emptyAthleteTableData
                          }
                          apiUnformattedData={
                            dataRows?.length
                              ? apiUnformattedData
                              : emptyAthleteTableData
                          }
                          showAverages={showAverages}
                          showTotals={showTotals}
                          setColumnHeaderHeight={setColumnHeaderHeight}
                          rowIsScrollable={rowIsScrollable}
                        />
                      </>
                      )}
                    </>
                  )}
              </BodyRow>
            </RelativelyPositionedRowWrapperForScrollIndicator>
          </RowWrapper>
        </>
        ) : null}
    </MainContainer>
  );
};

export default ViewOnlyGeneratedData;
