import { track } from '@amplitude/analytics-browser';

// Utility function to format event names that are snakecase.
const snakeToTitleCase = (snakeStr) => snakeStr
  .split('_')
  .map((word) => {
    // Only capitalize the first letter if the rest of the word is lowercase
    if (word === word.toLowerCase() || word === word.toUpperCase()) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    return word;
  })
  .join(' ');

// General AMS Lite trackers
export const dashboardTracker = (dashboard, feature, description) => {
  if (feature) {
    track({
      event_type: 'Feature Engagement - Feature Clicked',
      event_properties: {
        dashboard_name: dashboard,
        feature_name: feature,
        description,
      },
    });
  } else {
    track({
      event_type: 'Feature Engagement - Dashboard Viewed',
      event_properties: {
        module_name: dashboard,
      },
    });
  }
};

// GymStudio Integration trackers
export const gymstudioMatchingTracker = (
  module,
  matchedUsers,
  unmatchedUsers,
) => {
  track({
    event_type: 'Feature Engagement - User Matching Updated',
    event_properties: {
      module_name: module,
      matched_users: matchedUsers,
      unmatched_users: unmatchedUsers,
    },
  });
};

// Global general trackers
export const navigationTracker = (page) => {
  track({
    event_type: 'Navigation - Page Viewed',
    event_properties: {
      nav_item_clicked: page,
      page_title: page,
    },
  });
};

export const navigationTrackerAthlete = (page) => {
  track({
    event_type: 'Athlete Navigation - Page Viewed',
    event_properties: {
      nav_item_clicked: page,
      page_title: page,
    },
  });
};

export const featureClickTracker = (module, feature, description) => {
  track({
    event_type: 'Feature Engagement - Feature Clicked',
    event_properties: {
      module_name: module,
      feature_name: feature,
      description,
    },
  });
};

export const integrationConnectionTracker = (
  module,
  integration,
  description,
  status,
  error,
) => {
  track({
    event_type: 'Feature Engagement - Integration Connection Attempted',
    event_properties: {
      module_name: module,
      integration_name: integration,
      connection_status: status,
      error_message: error,
      description,
    },
  });
};

export const integrationDisconnectionTracker = (
  module,
  integration,
  description,
  status,
  error,
) => {
  track({
    event_type: 'Feature Engagement - Integration Disconnection Attempted',
    event_properties: {
      module_name: module,
      integration_name: integration,
      connection_status: status,
      error_message: error,
      description,
    },
  });
};

export const moduleTracker = (module, isModuleLocked) => {
  track({
    event_type: 'Feature Engagement - Module Viewed',
    event_properties: {
      module_name: module,
      module_locked: isModuleLocked,
    },
  });
};

// Onboarding tracker (AMS Lite)
export const onboardingTracker = (
  action,
  dashboard,
  description,
  currentSlide = null,
) => {
  track({
    event_type: `Onboarding Interaction - ${action}`,
    event_properties: {
      current_slide: currentSlide,
      dashboard,
      description,
    },
  });
};

// Reporting trackers
export const reportingFilterTracker = (action, filterType, error) => {
  track({
    event_type: `Report Filter - ${action}`,
    event_properties: {
      filter_type: snakeToTitleCase(filterType),
      error,
    },
  });
};

export const reportInteractionTracker = (
  action,
  report,
  error = '',
  filterLength = 0,
  filterType = '',
) => {
  track({
    event_type: `Report Interaction - ${action}`,
    event_properties: {
      report_name: `${snakeToTitleCase(report)} Report`,
      filter_length: filterLength,
      filter_type: filterType,
      error,
    },
  });
};

export const reportErrorTracker = (error, report) => {
  track({
    event_type: 'Report Interaction - Report Error',
    event_properties: {
      error,
      report_name: report,
    },
  });
};

export const reportNavEditTracker = (action, report) => {
  track(
    action === 'edit'
      ? {
        event_type: 'Report Interaction - Edit Clicked',
        event_properties: {
          report_name: report,
        },
      }
      : {
        event_type: 'Report Interaction - Back Clicked',
        event_properties: {
          previous_page: report,
        },
      },
  );
};

export const reportSubmitTracker = (
  filters,
  filterType,
  report,
  options,
  questions,
  time,
  rawDataType = null,
) => {
  track({
    event_type: 'Report Interaction - Report Submitted',
    event_properties: {
      active_filters: filters,
      active_filter_type: filterType,
      raw_data_type: rawDataType,
      report_name: report,
      report_options: options,
      selected_questions: questions,
      time_frame: time,
    },
  });
};

export const reportViewTracker = (report, downloaded) => {
  track({
    event_type: `Report Interaction - ${
      downloaded ? 'Download Clicked' : 'Report Viewed'
    }`,
    event_properties: {
      report_name: report,
    },
  });
};

// Subscription and demo trackers
export const subscriptionFlowTracker = (action, modal, activeModule) => {
  track({
    event_type: `Subscription Flow - ${action}`,
    event_properties: {
      modal_name: modal,
      active_module: activeModule,
    },
  });
};

export const subscriptionFlowTrackerDemo = (action, packageData) => {
  track({
    event_type: `Subscription Flow - Demo Clicked ${action}`,
    event_properties: {
      modal_name: action === 'Modal' ? packageData.title : 'none',
      active_module: action === 'Modal' ? 'none' : packageData.title,
      is_core: packageData.core,
      is_add_on: packageData.addOn || !packageData.core,
    },
  });
};
