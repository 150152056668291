/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const transitionSpeed = 0.5;

const ModalContainer = styled('div')`
	display: flex;
  z-index: 1003;
  justify-content: center;
  min-width: 660px;
  max-width: 800px;
  height: auto;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  min-height: 500px;
  transition: all 1s ease;
  width: 60vw;
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .remove-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    background: white;
    z-index: 1001;
    .remove-div-icon {
      cursor: pointer;
    }
  }
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  height: 100%;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background: white;
  box-shadow: ${BoxShadow};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  scroll-behavior: smooth;
  .marketing-modal-next-button {
    background: #FFA500;
    color: white;
    border-radius: 8px;
    height: 32px;
    transition: 0.5 1s ease;
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    font-family: 'Nunito Sans';
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
  }
  .marketing-modal-cancel-button {
    border: 1px solid #FFA500;
    color: #FFA500;
    border-radius: 8px;
    height: 32px;
    background: none;
    transition: all ${transitionSpeed}s ease;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Nunito Sans';
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
  }
  .custom-form {
    padding: 30px;
  }
  .note-text-area {
    padding: 10px;
    line-height: 1.5;
    border-radius: 5px;
    border: 1px solid #ccc;
    min-height: 100px;
    width: 80%;
    margin-top: 5px;
  }
`;

const TopBox = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const MiddleBox = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: space-around;
`;

const BottomBox = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
`;

const IconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopBoxText = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-top: 15px;
  color: black;
`;

const TopBoxText2 = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 15px;
`;

const FifteenContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 340px;
  padding: 15px;
  border-top: 1px solid lightgrey;
`;

const FifteenPlaceHolder = styled('div')`
  display: flex;
  flex-direction: column;
  width: 340px;
  padding: 15px;
`;

const GreenCircle = styled('div')`
  height: 20px;
  min-width: 20px;
  background-color: #0DCC8A;
  border-radius: 50%;
  margin-right: 20px;
`;

const BlueCircle = styled('div')`
  height: 20px;
  width: 20px;
  background-color: rgb(53,113,242);
  border-radius: 50%;
  margin-right: 20px;
`;

const OptionText = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin-top: 20px;
`;

const OptionTitle = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: black;
`;

const OptionItems = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const LeftThing = styled('div')`
  display: flex;
  align-items: center;
`;

const RightArrowContainer = styled('div')`
  display: flex;
`;

const BookDemoModal = () => {
  const isBookDemoModalShowing = useSelector(
    (state) => state.sportsScience.data.isBookDemoModalShowing,
  );

  return (
    <ModalContainer isActive={isBookDemoModalShowing}>
      <ContentContainer>
        <TopBox>
          <IconContainer>
            <IcomoonReact
              iconSet={iconSet}
              size={40}
              icon='heart-rate'
              color='black'
            />
          </IconContainer>
          <TopBoxText>
            TeamBuildr Demo / CS / Sales
          </TopBoxText>
          <TopBoxText2>
            Choose a time to walkthrough below:
          </TopBoxText2>
          <MiddleBox>
            <FifteenContainer>
              <OptionItems>
                <LeftThing>
                  <GreenCircle />
                  <OptionTitle>
                    15 Minute Demo
                  </OptionTitle>
                </LeftThing>
                <RightArrowContainer>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={20}
                    icon='right-arrow'
                    color='black'
                  />
                </RightArrowContainer>
              </OptionItems>
              <OptionText>
                Schedule a 15-minute demo with TeambBuildr Support.
                Ask a few question or get a full overview
              </OptionText>
            </FifteenContainer>
            <FifteenContainer>
              <OptionItems>
                <LeftThing>
                  <BlueCircle />
                  <OptionTitle>
                    30 Minute Demo
                  </OptionTitle>
                </LeftThing>
                <RightArrowContainer>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={20}
                    icon='right-arrow'
                    color='black'
                  />
                </RightArrowContainer>
              </OptionItems>
              <OptionText>
                Schedule a 30-minute demo with TeambBuildr Support.
                Ask a few question or get a full overview
              </OptionText>
            </FifteenContainer>
          </MiddleBox>
          <MiddleBox>
            <FifteenContainer>
              <OptionItems>
                <LeftThing>
                  <GreenCircle />
                  <OptionTitle>
                    15 Minute - Payment Portal Activation
                  </OptionTitle>
                </LeftThing>
                <RightArrowContainer>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={20}
                    icon='right-arrow'
                    color='black'
                  />
                </RightArrowContainer>
              </OptionItems>
              <OptionText>
                Schedule a 15-minute demo with TeambBuildr Support to
                activate your Payment Portal.
              </OptionText>
            </FifteenContainer>
            <FifteenPlaceHolder />
          </MiddleBox>
        </TopBox>
      </ContentContainer>
    </ModalContainer>
  );
};

export default BookDemoModal;
