/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import Helmet from 'react-helmet';
import { batch, useDispatch, useSelector } from 'react-redux';
import IcomoonReact from 'icomoon-react';
import { Prompt } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import ResizeObserver from 'react-resize-observer';

import iconSet from '../../shared/images/teambuildr-selection.json';
import HeaderText from '../../shared/components/HeaderText/HeaderText';
import TemplatesComponent from './TemplatesComponent';
import OverviewComponent from './components/presentational/OverviewComponent';
import {
  setSCDashView,
  fetchGroups,
  setIsUnsavedDashModalShowing,
  setNextLocation,
  setIsOverview,
  clearSCDashData,
} from './ducks/sportCoachDashboardActions';
import AnalysisComponent from './components/presentational/AnalysisComponent';
import NewDashboardComponent from './NewDashboardComponent';
import OverviewAnalysisMenu from './components/presentational/OverviewAnalysisMenu';
import { dashboardTracker } from '../../shared/utils/amplitudeHelper';

const Container = styled('div')`
  width: 100%;
`;

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const DashboardHeader = styled('div')`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.bgColor};
  padding-top: 30px;
  padding-left: 20px;
  justify-content: space-between;
  align-items: flex-end;
`;

const MenuContainer = styled('div')`
  display: flex;
`;

const MenuItem = styled('button')`
  border: none;
  margin-left: 10px;
  margin-right: 12px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-size: 16px;
  border-bottom: ${(props) => (props.isActive ? '3px solid #1787FB!important' : '3px solid white')};
  color: ${(props) => (!props.isActive ? '#9E9E9E' : '#444444')};
`;

const SearchContainer = styled('input')`
  display: flex;
  height: 40px;
  border: none;
  background: none;
  padding-left: 5px;
`;

const SearchAndIcon = styled('div')`
  display: flex;
  align-items: center;
  border: ${(props) => (props.isFocused ? '1px solid   #5F8BDC' : '1px solid #EEEEEE')};
  border-radius: 50px;
  background: white;
  transition: max-width 0.5s ease;
  max-width: ${(props) => (props.isFocused ? '250px' : '40px')};
  overflow: hidden;
`;

const IconsAndSearch = styled('div')`
  display: flex;
  align-items: center;
`;

const HeaderWidthContainer = styled('div')`
  max-width: 1430px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SearchIconContainer = styled('div')`
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
`;

const HeaderTextAndArrow = styled('div')`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;

const HeaderAndSubheader = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
  gap: 8px;
`;

const SubheaderText = styled('h2')`
  font-family: 'Nunito Sans';
  font-size: 16px;
  color: #9e9e9e;
  line-height: 24px;
  margin-bottom: 0;
`;

const BackButtonContainer = styled('div')`
  cursor: pointer;
`;

const SportCoachDashboard = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isOverview = useSelector(
    (state) => state.sportCoachDashboard.ui.isOverview,
  );
  const nextLocation = useSelector(
    (state) => state.sportCoachDashboard.data.nextLocation,
  );
  const scDashView = useSelector(
    (state) => state.sportCoachDashboard.ui.scDashView,
  );
  const template = useSelector(
    (state) => state.sportCoachDashboard.data.template,
  );

  const dispatch = useDispatch();
  const pageRef = useRef(null);

  const theme = useTheme();

  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [newDash, setNewDash] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);

  const handleMenuClick = (view) => {
    if (view !== scDashView) {
      dispatch(setSCDashView(view));
      // Track feature click for SC Dash
      dashboardTracker(
        'Sport Coach Dashboard',
        view === 'group' ? 'Group Analysis' : 'Performance Overview',
        'Menu item clicked',
      );
    }
  };

  useEffect(() => {
    if (!isOverview && currentUser) {
      dispatch(fetchGroups(currentUser.accountCode));
    }
  }, [isOverview, currentUser]);

  const setIsOverviewTrue = () => {
    dispatch(setIsOverview(true));
  };

  // Handle scrolling back to top for performance overview screen
  const scrollTop = () => {
    setTimeout(
      () => pageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      }),
      250,
    );
  };

  useEffect(() => {
    if (isOverview) {
      scrollTop();
    }
  }, [isOverview]);

  const unsavedDash = isOverview && !template;

  // Handles if a user tries to leave the site, or navigate to PHP page, with an unsaved dashboard
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();

      // Note, custom messages do not work for Chrome
      const customMessage = `This dashboard has not been saved, are you sure you want to exit without saving?
      Exiting now will discard any unsaved progress. If you have made any updates,
      close this modal to review and save your changes.`;

      return customMessage;
    };

    if (unsavedDash) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedDash]);

  // Handles if a user tries to navigate to another React page with an unsaved dashboard
  const handleNavigation = (location) => {
    if (unsavedDash && !nextLocation) {
      batch(() => {
        dispatch(setNextLocation(location.pathname));
        dispatch(setIsUnsavedDashModalShowing(true));
      });
      return false; // Prevent navigation
    }
    return true; // Allow navigation
  };

  const handleReturnClick = () => {
    // Amplitude tracking
    dashboardTracker(
      'Sport Coach Dashboard',
      'Main Screen',
      'Return to main screen clicked',
    );
    if (newDash) {
      setNewDash(false);
    } else if (unsavedDash) {
      batch(() => {
        dispatch(setNextLocation('/ams-lite/kpi-tracker'));
        dispatch(setIsUnsavedDashModalShowing(true));
      });
    } else {
      batch(() => {
        dispatch(setIsOverview(!isOverview));
        dispatch(clearSCDashData());
      });
    }
  };

  return (
    <Container
      onClick={(e) => {
        if (
          e.target.id !== 'search-container'
          && e.target.id !== 'search-icon-container'
          && e.target.id !== 'search-container-input'
          && e.target.id !== 'mag-icon'
          && isSearchFocused
          && searchTerm.length === 0
        ) {
          setIsSearchFocused(false);
        }
      }}
    >
      <ResizeObserver
        onResize={(rect) => {
          setContainerWidth(rect?.width);
        }}
      />
      <Helmet>
        <title>KPI Tracker | TeamBuildr</title>
      </Helmet>
      <PageWrapper ref={pageRef}>
        <DashboardHeader bgColor={theme.colors.background[0]}>
          <HeaderWidthContainer>
            <HeaderTextAndArrow>
              {isOverview || newDash ? (
                <BackButtonContainer>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={20}
                    icon='left-arrow'
                    onClick={handleReturnClick}
                  />
                </BackButtonContainer>
              ) : null}
              <HeaderAndSubheader>
                <HeaderText fontWeight='300' letterSpacing='normal'>
                  KPI Tracker
                </HeaderText>
                {containerWidth > 760 && (
                  <SubheaderText>
                    (Formerly Sport Coach Dashboard)
                  </SubheaderText>
                )}
              </HeaderAndSubheader>
            </HeaderTextAndArrow>
            {!isOverview ? (
              <MenuContainer>
                <IconsAndSearch>
                  <MenuItem>
                    <SearchAndIcon
                      id='search-container'
                      onClick={() => setIsSearchFocused(true)}
                      isFocused={isSearchFocused}
                    >
                      <SearchIconContainer id='search-icon-container'>
                        <IcomoonReact
                          iconSet={iconSet}
                          size={20}
                          icon='magnifying-glass-alt'
                          id='mag-icon'
                        />
                      </SearchIconContainer>
                      <SearchContainer
                        placeholder='Search'
                        id='search-container-input'
                        value={searchTerm}
                        onFocus={() => setIsSearchFocused(true)}
                        onChange={(inputValue) => {
                          setSearchTerm(inputValue.target.value);
                        }}
                      />
                    </SearchAndIcon>
                  </MenuItem>
                </IconsAndSearch>
              </MenuContainer>
            ) : (
              <MenuContainer>
                <MenuItem
                  isActive={scDashView === 'performance'}
                  onClick={() => handleMenuClick('performance')}
                >
                  Performance Overview
                </MenuItem>
                <MenuItem
                  isActive={scDashView === 'group'}
                  onClick={() => handleMenuClick('group')}
                >
                  Group Analysis
                </MenuItem>
              </MenuContainer>
            )}
          </HeaderWidthContainer>
        </DashboardHeader>
        {!isOverview && !newDash && (
          <TemplatesComponent searchTerm={searchTerm} setNewDash={setNewDash} />
        )}
        {isOverview && scDashView === 'performance' && <OverviewComponent />}
        {isOverview && scDashView === 'group' && <AnalysisComponent />}
        {!isOverview && newDash && (
          <NewDashboardComponent
            setIsOverview={setIsOverviewTrue}
            setNewDash={setNewDash}
          />
        )}
        {isOverview && <OverviewAnalysisMenu />}
      </PageWrapper>
      <Prompt when={unsavedDash} message={handleNavigation} />
    </Container>
  );
};

export default SportCoachDashboard;
