/* eslint-disable no-nested-ternary */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import Button from '../../../../shared/components/Button/Button';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import { setIsLegendInfoModalShowing } from '../../ducks/forcePlateDashboardActions';
import LegendSVG from '../../../../shared/images/fp-dash-metrics-legend.svg';
import SharedTemplateModalContainer from '../../../sports-science/components/presentational/sharedTemplateComponents/SharedTemplateModalContainer';

const ModalContainer = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: ${({ isSideBarExpanded, isReadOnlyView }) => (isReadOnlyView
    ? 'translate(-50%, -50%)'
    : isSideBarExpanded
      ? 'translate(calc(-50% + 150px), -50%)'
      : 'translate(calc(-50% + 48px), -50%)')};
  background-color: #ffffff;
  width: 594px;
  padding: 24px;
  border-radius: 8px;
  font-family: 'Nunito Sans';
  box-shadow: ${BoxShadow};
  z-index: 1003;

  @media screen and (min-width: 714px) and (max-width: 900px) {
    transform: ${({ isSideBarExpanded, isReadOnlyView }) => (isReadOnlyView
    ? 'translate(-50%, -50%)'
    : isSideBarExpanded
      ? 'translate(-37.5%, -50%)'
      : 'translate(calc(-50% + 48px), -50%)')};
  }

  @media screen and (max-width: 714px) {
    transform: translate(-50%, -50%);
  }

  @media screen and (max-width: 594px) {
    left: 0;
    width: 100%;
    margin-left: 0;
    transform: translate(0, -50%);
  }
`;

const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const Header = styled('div')`
  font-size: 16px;
  color: #9e9e9e;
`;

const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  overflow-x: auto;
`;

const Title = styled('div')`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const DescriptionImage = styled('div')`
  background-image: url(${LegendSVG});
  background-repeat: no-repeat;
  height: 225px;
  width: 545px;
`;

const LegendInfoModal = () => {
  const dispatch = useDispatch();
  const isSideBarExpanded = useSelector(
    (state) => state.sportsScience.data.isSideBarExpanded,
  );
  const isModalShowing = useSelector(
    (state) => state.forcePlateDashboard.ui.isLegendInfoModalShowing,
  );
  const isReadOnlyView = useSelector(
    (state) => state.forcePlateDashboard.ui.isReadOnlyView,
  );

  const handleClose = () => {
    dispatch(setIsLegendInfoModalShowing(false));
  };

  return (
    <>
      <ModalContainer
        isSideBarExpanded={isSideBarExpanded}
        isReadOnlyView={isReadOnlyView}
      >
        <HeaderContainer>
          <Header>Legend</Header>
          <Button icon='remove' iconOnly onClick={handleClose} />
        </HeaderContainer>
        <ContentContainer>
          <Title>Key Performance Metrics</Title>
          <DescriptionImage />
        </ContentContainer>
      </ModalContainer>
      <SharedTemplateModalContainer
        isModalShowing={isModalShowing}
        onClose={handleClose}
      />
    </>
  );
};

export default LegendInfoModal;
