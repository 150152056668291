import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import Checkbox from '@material-ui/core/Checkbox';

import Button from '../../../../shared/components/Button/Button';
import TBTooltip from '../../../../shared/components/TBTooltip/TBTooltip';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import { setIsExerFiltersGroupShowing, setExerFiltersGroup } from '../../ducks/sportCoachDashboardActions';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const FilterAndButtonContainer = styled('div')`
  position: relative;
  margin-top: 8px;
`;

const Badge = styled('span')`
  position: absolute;
  top: -7px;
  right: -7px;
  border-radius: 100px;
  background-color: #444444;
  color: #FFFFFF;
  width: 16px;
  display: flex;
  justify-content: center;
  font-family: 'Nunito Sans';
  font-size: 11px;
  font-weight: bold;
`;

const FilterContainer = styled('div')`
  display: flex;
  position: absolute;
  top: 38px;
  background-color: #FFFFFF;
  z-index: 1;
  flex-direction: column;
  width: 250px;
  max-height: 420px;
  border-radius: 4px;
  padding: 16px;
  box-shadow: ${BoxShadow};
`;

const FilterHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  font-family: 'Nunito Sans';
  font-size: 20px;
  font-weight: 700;
`;

const Filters = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: bold;
  overflow-y: auto;
`;

const FilterOption = styled('div')`
  display: flex;
  align-items: center;
  margin-left: -10px;

  h3 {
    font-weight: normal;
  }
`;

const OptionText = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const FilterBottom = styled('div')`
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
`;

const ExerciseFilterGroup = () => {
  const exerciseFiltersGroup = useSelector((
    state,
  ) => state.sportCoachDashboard.data.exerciseFiltersGroup);
  const exercises = useSelector((state) => state.sportCoachDashboard.data.exercises);
  const isExerciseFiltersGroupShowing = useSelector((
    state,
  ) => state.sportCoachDashboard.ui.isExerciseFiltersGroupShowing);

  const dispatch = useDispatch();

  const handleFilter = () => {
    // Amplitude tracking
    dashboardTracker('Sport Coach Dashboard', 'Group Analysis - Filter', 'Filter clicked');
    dispatch(setIsExerFiltersGroupShowing(!isExerciseFiltersGroupShowing));
  };

  const handleClose = () => {
    // Amplitude tracking
    dashboardTracker('Sport Coach Dashboard', 'Group Analysis - Filter', 'Filter closed');
    dispatch(setIsExerFiltersGroupShowing(false));
  };

  const handleChange = (exer) => {
    // Amplitude tracking
    dashboardTracker('Sport Coach Dashboard', 'Group Analysis - Filter', `Exercise filter clicked - ${exer}`);
    // If exercise is already in filters, remove it
    if (exerciseFiltersGroup.includes(exer)) {
      const newExercises = [...exerciseFiltersGroup];
      const index = newExercises.indexOf(exer);
      if (index > -1) {
        newExercises.splice(index, 1);
      }
      dispatch(setExerFiltersGroup(newExercises));
    // Else add it to the filters array
    } else {
      const newExercises = [...exerciseFiltersGroup];
      newExercises.push(exer);
      dispatch(setExerFiltersGroup(newExercises));
    }
  };

  const handleClear = () => {
    // Amplitude tracking
    dashboardTracker('Sport Coach Dashboard', 'Group Analysis - Filter', 'Filters cleared');
    dispatch(setExerFiltersGroup([]));
  };

  return (
    <FilterAndButtonContainer>
      <Button
        icon='filter'
        cta='Filter'
        rounded
        onClick={handleFilter}
      />
      {exerciseFiltersGroup.length > 0 && (
        <Badge>{exerciseFiltersGroup.length}</Badge>
      )}
      {isExerciseFiltersGroupShowing && (
      <FilterContainer>
        <FilterHeader>
          Exercises
          <Button
            icon='remove'
            iconOnly
            onClick={handleClose}
          />
        </FilterHeader>
        <Filters>
          {exercises.map(
            (option) => (
              <FilterOption key={option.id}>
                <Checkbox
                  color='default'
                  checked={exerciseFiltersGroup.includes(option.name)}
                  onChange={() => handleChange(option.name)}
                />
                {option.name.length > 20 ? (
                  <TBTooltip
                    textToDisplay={option.name}
                    textToHover={option.name}
                    fontSize='16px'
                    textContainerPadding='0px'
                    textContainerMaxWidth='170px'
                  />
                ) : (
                  <OptionText>{option.name}</OptionText>
                )}
              </FilterOption>
            ),
          )}
        </Filters>
        <FilterBottom>
          <Button
            cta='Clear'
            rounded
            onClick={handleClear}
          />
          <Button
            cta='Done'
            customColor='#444444'
            customTextColor='#FFFFFF'
            primary
            disabled={exerciseFiltersGroup.length === 0}
            rounded
            onClick={handleClose}
          />
        </FilterBottom>
      </FilterContainer>
      )}
    </FilterAndButtonContainer>
  );
};

export default ExerciseFilterGroup;
