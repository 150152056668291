/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
} from 'recharts';

import { setDateType, fetchSCDashData } from '../../ducks/sportCoachDashboardActions';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const ChartContainer = styled('div')`
  width: 100%;
  height: ${(props) => (props.isLoading ? '355px' : '90%')};
  filter: ${(props) => (props.isBlurred ? 'blur(1.2px)' : null)};

  .custom-tooltip {
    background-color: #FFFFFF;
    padding: 8px 16px;
    border: solid 1px #EEEEEE;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 250px;
    justify-content: flex-start;
    font-family: 'Nunito Sans';
    font-size: 13px;
    font-weight: normal;
  }
`;

const DailySelector = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const DailyOption = styled('button')`
  border: none;
  font-family: 'Nunito Sans';
  font-size: 16px;
  color: ${(props) => (!props.isActive ? '#9E9E9E' : null)};
`;

const DailySpacer = styled('span')`
  color: #9E9E9E;
  font-family: 'Nunito Sans';
  font-size: 16px;
  margin: 0px 5px;
  cursor: default;
`;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className='custom-tooltip'>
        <p style={{ fontWeight: 'bold' }}>{label}</p>
        {payload.map((dataPoint) => (
          <p key={dataPoint.name} style={{ color: dataPoint.formatter || dataPoint.color }}>
            {`${dataPoint.name}: `}
            <span style={{ color: '#444444' }}>{dataPoint.value}</span>
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const SCOverviewChart = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const dateType = useSelector((state) => state.sportCoachDashboard.data.dateType);
  const endDateData = useSelector((state) => state.sportCoachDashboard.data.endDateData);
  const exerciseFiltersPerformance = useSelector((state) => state.sportCoachDashboard.data.exerciseFiltersPerformance);
  const exercises = useSelector((state) => state.sportCoachDashboard.data.exercises);
  const group = useSelector((state) => state.sportCoachDashboard.data.group);
  const isReadOnlyView = useSelector((state) => state.sportCoachDashboard.ui.isReadOnlyView);
  const isSCDashDataLoading = useSelector((state) => state.sportCoachDashboard.ui.isSCDashDataLoading);
  const maxType = useSelector((state) => state.sportCoachDashboard.data.maxType);
  const chartData = useSelector((state) => state.sportCoachDashboard.data.chartData);
  const selectedAthletePerformance = useSelector((state) => state.sportCoachDashboard.data.selectedAthletePerformance);
  const startDateData = useSelector((state) => state.sportCoachDashboard.data.startDateData);
  const weightType = useSelector((state) => state.sportCoachDashboard.data.weightType);

  const hasData = chartData.length > 0;
  const data = hasData && [...chartData];

  const exerIds = exercises.length > 0 && exercises.map((item) => item.id);

  const weight = weightType || currentUser?.userSettings?.weight;

  // Set the labe for the Y axis based on unit abbreviation or the weight type of the account
  const yAxisLabel = exerciseFiltersPerformance.length === 0 ? weight
    : exerciseFiltersPerformance[0]?.unitAbbr === 'wgt' ? weight
      : exerciseFiltersPerformance[0]?.unitAbbr || 'secs';

  // Format the dates correctly between monthly/daily
  let formattedData = null;
  if (dateType === 1) {
    formattedData = data && data.map((obj) => ({
      ...obj,
      date: moment(obj.date, 'MM/YYYY').format('MMM YY'),
    }));
  } else {
    formattedData = data && data.map((obj) => ({
      ...obj,
      date: moment(obj.date).format('MMM DD'),
    }));
  }

  const dispatch = useDispatch();

  // Create dummy/default data for the no-data view of graph
  const defaultData = [];
  const range = moment(endDateData).diff(moment(startDateData), 'months');
  for (let i = range, n = 0; i >= n; i--) {
    if (i !== 0) {
      const day = {
        date: moment(endDateData).subtract(i, 'months').format('MMM YY'),
        exerData: 0,
      };
      defaultData.push(day);
    } else {
      const day = {
        date: moment(endDateData).format('MMM YY'),
        exerData: 0,
      };
      defaultData.push(day);
    }
  }

  // Available colors for the line chart
  const colors = ['#1787FB', '#0DCC8A', '#FFCB47'];

  // Handles creating a new array of data with the filtered athlete data pulled into root
  const filterAthleteData = (chrtData, userId, exers) => {
    const prettyObject = (chrtData && userId) && chrtData.map((dateObj) => {
      const { athleteData, ...returnObj } = dateObj;
      const matchingAthlete = athleteData
        .find((athleteWithMaxData) => athleteWithMaxData.userId === userId);
      if (matchingAthlete) {
        exers.forEach((exercise) => {
          if (matchingAthlete.testResults[exercise.name]) {
            returnObj[`${matchingAthlete.fullName}_${exercise.name}`] = matchingAthlete.testResults[exercise.name];
          }
        });
      }
      return returnObj;
    });
    return prettyObject;
  };

  const handleDateTypeClick = (type) => {
    if (!isReadOnlyView) {
      // Amplitude tracking
      dashboardTracker('Sport Coach Dashboard', 'Performance Overview - Daily/Monthly Picker', `${type === 1 ? 'Monthly' : 'Daily'} data range selected`);
      batch(() => {
        dispatch(setDateType(type));
        dispatch(
          fetchSCDashData(
            currentUser.accountCode,
            startDateData,
            endDateData,
            group.id,
            exerIds,
            type,
            maxType,
          ),
        );
      });
    }
  };

  return (
    <>
      {isSCDashDataLoading ? (
        <ChartContainer isLoading={isSCDashDataLoading}>
          <Spinner />
        </ChartContainer>
      ) : (
        <ChartContainer isBlurred={!hasData}>
          <DailySelector>
            <DailyOption
              disabled={!hasData || dateType === 2 || isReadOnlyView}
              isActive={dateType === 2}
              onClick={() => handleDateTypeClick(2)}
            >
              Daily
            </DailyOption>
            <DailySpacer> | </DailySpacer>
            <DailyOption
              disabled={!hasData || dateType === 1 || isReadOnlyView}
              isActive={dateType === 1}
              onClick={() => handleDateTypeClick(1)}
            >
              Monthly
            </DailyOption>
          </DailySelector>
          {hasData ? (
            <>
              {selectedAthletePerformance ? (
                <ResponsiveContainer width='100%' height='90%' minHeight={380} id='printableDiv'>
                  <LineChart data={filterAthleteData(formattedData, selectedAthletePerformance?.userId, exercises)}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='date' angle={315} height={50} textAnchor='end' />
                    <YAxis
                      label={
                      {
                        value: yAxisLabel,
                        fill: '#666666',
                        angle: -90,
                        position: 'insideLeft',
                      }
                    }
                      width={yAxisLabel === 'secs' ? 70 : 60}
                    />
                    {exerciseFiltersPerformance.map((filter, i) => (
                      <Line
                        key={`${filter.name}_${i}`}
                        connectNulls
                        name={filter.name}
                        type='linear'
                        dataKey={filter.name}
                        stroke={colors[i]}
                      />
                    ))}
                    {exerciseFiltersPerformance.map((filter, i) => (
                      <Line
                        key={`${selectedAthletePerformance.fullName}_${filter.name}_${i}`}
                        connectNulls
                        name={`${selectedAthletePerformance.fullName} (${filter.name})`}
                        type='monotone'
                        dataKey={`${selectedAthletePerformance.fullName}_${filter.name}`}
                        stroke={colors[i]}
                        strokeDasharray='5 5'
                        legendType='plainline'
                      />
                    ))}
                    <Tooltip content={<CustomTooltip />} />
                    <Legend verticalAlign='top' height={exerciseFiltersPerformance.length > 1 ? 60 : 30} />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <ResponsiveContainer width='100%' height='90%' minHeight={380} id='printableDiv'>
                  <LineChart data={formattedData}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='date' angle={315} height={50} textAnchor='end' />
                    <YAxis
                      label={
                        {
                          value: yAxisLabel,
                          fill: '#666666',
                          angle: -90,
                          position: 'insideLeft',
                        }
                      }
                      width={yAxisLabel === 'secs' ? 70 : 60}
                    />
                    {exerciseFiltersPerformance.map((filter, i) => (
                      <Line
                        key={`${filter.name}_${i}`}
                        connectNulls
                        name={filter.name}
                        type='linear'
                        dataKey={filter.name}
                        stroke={colors[i]}
                      />
                    ))}
                    <Tooltip content={<CustomTooltip />} />
                    <Legend verticalAlign='top' height={30} />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </>
          ) : (
            <ResponsiveContainer width='100%' height='90%' minHeight={380}>
              <LineChart
                data={defaultData}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='date' angle={315} height={50} textAnchor='end' />
                <YAxis
                  label={{
                    value: yAxisLabel,
                    fill: '#666666',
                    angle: -90,
                    position: 'insideLeft',
                  }}
                  domain={[0, 350]}
                />
                <Line
                  type='linear'
                  dataKey='exerData'
                  dot={false}
                  stroke='#444444'
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </ChartContainer>
      )}
    </>
  );
};

export default SCOverviewChart;
