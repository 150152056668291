import React from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import PropTypes from 'prop-types';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import { BoxShadow } from '../../../../../shared/GlobalStyles';
import {
  setDuplicateModalShowing as setDuplicateSCModalShowing,
  setSaveDashboardModal,
  setShareDashboardModal,
  setTriOptionModal,
} from '../../../../sport-coach-dashboard/ducks/sportCoachDashboardActions';
import { dashboardTracker } from '../../../../../shared/utils/amplitudeHelper';
import {
  setDuplicateLMDashModal,
  setLMTriOptionModal,
  setSaveLMDashModal,
  setShareLMDashModal,
} from '../../../../load-monitoring-dashboard/ducks/loadMonitoringDashboardActions';
import {
  setDuplicateFPDashModal,
  setFPTriOptionModal,
  setSaveFPDashModal,
  setShareFPDashModal,
} from '../../../../force-plate-dashboard/ducks/forcePlateDashboardActions';
import SharedTemplateModalContainer from './SharedTemplateModalContainer';

const ModalContainer = styled('div')`
  height: 65%;
  min-width: 300px;
  z-index: 1004;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  gap: 30px;
  max-width: 1400px;
  position: absolute;
  left: 50%;
  top: 50%;
  right: 50%;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  max-height: 650px;
  overflow: auto;
  @media only screen and (max-width: 714px) {
    flex-direction: column;
    max-height: 800px;
    height: fit-content;
    gap: 15px;
  }
`;

const InteriorContainer = styled('div')`
  background: white;
  box-shadow: ${BoxShadow};
  border-radius: 24px;
  height: 80%;
  width: 30%;
  min-width: 275px;
  max-height: 350px;
  position: relative;
  cursor: pointer;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  @media only screen and (max-width: 714px) {
    margin-top: 20px;
  }
  @media only screen and (max-width: 714px) and (max-height: 700px) {
    margin-top: 0;
    padding: 30px;
  }
`;

const MainText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 23px;
  color: #444444;
  margin-top: 30px;
`;

const SubText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 16px;
  color: #9e9e9e;
  margin-top: 10px;
`;

const SharedTemplateTriOptionModal = ({ dashboard }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  let activeTemplate = null;
  let isModalShowing = false;
  let setTriOptionModalShowing = () => {};
  let setDuplicateModalShowing = () => {};
  let setSaveModalShowing = () => {};
  let setShareModalShowing = () => {};
  const questionnaireReportHasData = useSelector(
    (state) => state.loadMonitoringDashboard.data.questionnaireReportHasData,
  );
  const lineChartHasData = useSelector(
    (state) => state.loadMonitoringDashboard.data.lineChartHasData,
  );
  const performanceOverviewData = useSelector(
    (state) => state.forcePlateDashboard.data.performanceOverviewData,
  ) || [];
  const groupAnalysisAthleteData = useSelector(
    (state) => state.forcePlateDashboard.data.groupAnalysisAthleteData,
  ) || [];
  const groupAnalysisAverageData = useSelector(
    (state) => state.forcePlateDashboard.data.groupAnalysisAverageData,
  ) || [];
  const flaggedAthletesDataGroup = useSelector(
    (state) => state.forcePlateDashboard.data.flaggedAthletesDataGroup,
  ) || [];
  const chartData = useSelector(
    (state) => state.sportCoachDashboard.data.chartData,
  );
  let dashboardHasData = false;

  if (dashboard === 'Load Monitoring') {
    activeTemplate = useSelector(
      (state) => state.loadMonitoringDashboard.data.activeTemplate,
    );
    isModalShowing = useSelector(
      (state) => state.loadMonitoringDashboard.ui.triOptionModalShowing,
    );
    setTriOptionModalShowing = setLMTriOptionModal;
    setDuplicateModalShowing = setDuplicateLMDashModal;
    setSaveModalShowing = setSaveLMDashModal;
    setShareModalShowing = setShareLMDashModal;
    dashboardHasData = questionnaireReportHasData || lineChartHasData;
  }

  if (dashboard === 'Force Plate') {
    activeTemplate = useSelector(
      (state) => state.forcePlateDashboard.data.activeTemplate,
    );
    isModalShowing = useSelector(
      (state) => state.forcePlateDashboard.ui.triOptionModalShowing,
    );
    setTriOptionModalShowing = setFPTriOptionModal;
    setDuplicateModalShowing = setDuplicateFPDashModal;
    setSaveModalShowing = setSaveFPDashModal;
    setShareModalShowing = setShareFPDashModal;
    dashboardHasData = !!performanceOverviewData.length
      || !!groupAnalysisAthleteData.length
      || !!groupAnalysisAverageData.length
      || !!flaggedAthletesDataGroup.length;
  }

  if (dashboard === 'KPI Tracker') {
    activeTemplate = useSelector(
      (state) => state.sportCoachDashboard.data.template,
    );
    isModalShowing = useSelector(
      (state) => state.sportCoachDashboard.ui.triOptionModalShowing,
    );
    setTriOptionModalShowing = setTriOptionModal;
    setDuplicateModalShowing = setDuplicateSCModalShowing;
    setSaveModalShowing = setSaveDashboardModal;
    setShareModalShowing = setShareDashboardModal;
    dashboardHasData = !!chartData.length;
  }

  const isOwner = currentUser?.id === activeTemplate?.createdBy || !activeTemplate;

  const handleDuplicateClick = () => {
    // KPI tracking
    dashboardTracker(
      `${dashboard} Dashboard`,
      'Dashboard Options Menu',
      'Duplicate dashboard clicked',
    );
    batch(() => {
      dispatch(setTriOptionModalShowing(false));
      dispatch(setDuplicateModalShowing(true, activeTemplate));
    });
  };

  const handleSaveClick = () => {
    // KPI tracking
    dashboardTracker(
      `${dashboard} Dashboard`,
      'Dashboard Options Menu',
      'Save dashboard clicked',
    );
    batch(() => {
      dispatch(setTriOptionModalShowing(false));
      dispatch(setSaveModalShowing(true));
    });
  };

  const handleShareClick = () => {
    // KPI tracking
    dashboardTracker(
      `${dashboard} Dashboard`,
      'Dashboard Options Menu',
      'Share dashboard clicked',
    );
    batch(() => {
      dispatch(setTriOptionModalShowing(false));
      dispatch(setShareModalShowing(true));
    });
  };

  const handleClose = () => {
    // KPI tracking
    dashboardTracker(
      `${dashboard} Dashboard`,
      'Dashboard Options Menu',
      'Modal closed',
    );
    dispatch(setTriOptionModalShowing(false));
  };

  return (
    <>
      <ModalContainer onClick={handleClose} isActive={isModalShowing}>
        {isOwner ? (
          <>
            {activeTemplate && (
              <InteriorContainer onClick={handleDuplicateClick}>
                <IcomoonReact
                  iconSet={iconSet}
                  size={65}
                  icon='copy'
                  color='#444444'
                />
                <MainText>Duplicate Dashboard</MainText>
                <SubText>
                  Create a copy of this dashboard to edit, save and share.
                </SubText>
              </InteriorContainer>
            )}
            {!activeTemplate && (
              <InteriorContainer onClick={handleSaveClick}>
                <IcomoonReact
                  iconSet={iconSet}
                  size={65}
                  icon='save'
                  color='#444444'
                />
                <MainText>Save Dashboard</MainText>
                <SubText>
                  {dashboard === 'KPI Tracker'
                    ? 'Save the exercises selected in this dashboard to allow any coaches with a TeamBuildr account in your organization to access.'
                    : 'Save the options selected in this dashboard to allow any coaches with a TeamBuildr account in your organization to access.'}
                </SubText>
              </InteriorContainer>
            )}
            {dashboardHasData && (
              <InteriorContainer onClick={handleShareClick}>
                <IcomoonReact
                  iconSet={iconSet}
                  size={65}
                  icon='share'
                  color='#444444'
                />
                <MainText>Share Dashboard</MainText>
                <SubText>
                  Send a custom ‘View Only’ version of this dashboard to coaches
                  and stakeholders. This link will expire in 30 days.
                </SubText>
              </InteriorContainer>
            )}
          </>
        ) : (
          <InteriorContainer onClick={handleDuplicateClick}>
            <IcomoonReact
              iconSet={iconSet}
              size={65}
              icon='copy'
              color='#444444'
            />
            <MainText>Duplicate Dashboard</MainText>
            <SubText>
              Create a copy of this dashboard to edit, save and share.
            </SubText>
          </InteriorContainer>
        )}
      </ModalContainer>
      <SharedTemplateModalContainer
        isModalShowing={isModalShowing}
        onClose={handleClose}
      />
    </>
  );
};

SharedTemplateTriOptionModal.defaultProps = {
  dashboard: 'Wearables',
};

SharedTemplateTriOptionModal.propTypes = {
  dashboard: PropTypes.string,
};

export default SharedTemplateTriOptionModal;
