import React from 'react';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import { useSelector } from 'react-redux';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const ContentWrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 35px;
  height: 100%;
  align-items: center;
`;

const UpgradeText = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #000000;
  margin-top: 30px;
`;

const SuccessfulUpgradeDesc = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  margin-top: 30px;
`;

const IconWrapper = styled('div')`
  margin-top: 30px;
`;

const InstantPaymentSlide2 = () => {
  const { package: subscriptionPackage, addOns } = useSelector(
    (state) => state.sportsScience.data.subscriptionPackage,
  );

  const addOnsText = addOns.map((addOn) => addOn.addOnName).join(', ');

  return (
    <ContentWrapper>
      <IconWrapper>
        <IcomoonReact
          iconSet={iconSet}
          size={45}
          icon='checkmark'
          color='#0DCC8A'
        />
      </IconWrapper>
      <UpgradeText>Upgrade Successful!</UpgradeText>
      <SuccessfulUpgradeDesc>
        You now have access to the
        {' '}
        {subscriptionPackage.packageName}
        {' '}
        package
        {addOns.length > 0 ? ` and add-on(s): ${addOnsText}` : ''}
        .
      </SuccessfulUpgradeDesc>
    </ContentWrapper>
  );
};

export default InstantPaymentSlide2;
