/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { setIsLearnMoreModalShowing } from '../../ducks/sportsScienceActions';
import LearnMoreModal from './LearnMoreModal';

const LearnMoreModalContainerOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.8);
  z-index: 1002;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

const LearnMoreModalContainer = () => {
  const dispatch = useDispatch();
  const isLearnMoreModalShowing = useSelector(
    (state) => state.sportsScience.data.isLearnMoreModalShowing,
  );

  return (
    <>
      <LearnMoreModal />
      <LearnMoreModalContainerOverlay
        isActive={isLearnMoreModalShowing}
        onClick={
          () => dispatch(setIsLearnMoreModalShowing(false))
          }
      />
    </>
  );
};

LearnMoreModalContainer.propTypes = { };

export default LearnMoreModalContainer;
