import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const CardWrapper = styled('div')`
  display: flex;
  position: relative;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  width: 344px;
  min-width: 240px;
  height: 185px;
  padding: 20px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0px 1px 8px 0px rgba(8, 5, 10, 0.1);

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.bgImage});
    background-size: cover;
    background-position: center;
    opacity: 0.9;
    z-index: 1;
  }

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 2;
    transition: background-color 0.3s ease;
  }

  &:hover::after {
    background-color: rgba(0, 0, 255, 0.2);
  }
`;

const InnerContent = styled('div')`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  padding: 16px 12px;
  width: 100%;
  border-radius: 12px;
  z-index: 3;
  gap: 12px;
`;

const CardText = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 12px;
`;

const CardHeader = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  gap: 4px;
  width: 100%;
`;

const ModuleIcon = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModuleTitle = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
`;

const ModuleSubtitle = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: '#9e9e9e';
  font-family: 'Nunito Sans';
  font-size: 11px;
  line-height: 14px;
`;

const CardDescription = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

const ModuleTag = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 25px;
  padding: 4px 12px;
  gap: 8px;
  border-radius: 10000px;
  background: ${(props) => props.color};
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Nunito Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 16.5px */
  span {
    color: ${(props) => props.text};
  }
`;

const TagCircle = styled('div')`
  border-radius: 10000px;
  background: ${(props) => props.color};
  height: 12px;
  width: 12px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
`;

const ModuleCardUnlocked = ({ cardData }) => {
  const dispatch = useDispatch();
  const {
    backgroundImage,
    icon,
    title,
    legacyTitle,
    description,
    tagColor,
    textColor,
    circleColor,
    subscriptionName,
    activeTab,
  } = cardData;

  const handleAmplitudeModule = (moduleTitle) => {
    dashboardTracker(moduleTitle, null, null);
  };

  return (
    <CardWrapper
      bgImage={backgroundImage}
      onClick={() => {
        handleAmplitudeModule(title);
        dispatch(push(activeTab));
      }}
    >
      <InnerContent>
        <CardText>
          <CardHeader>
            <ModuleIcon>
              <IcomoonReact
                iconSet={iconSet}
                color='#444444'
                size={16}
                icon={icon}
              />
            </ModuleIcon>
            <ModuleTitle>{title}</ModuleTitle>
            {legacyTitle && (
              <ModuleSubtitle>
                (Formerly
                {' '}
                {legacyTitle}
                )
              </ModuleSubtitle>
            )}
          </CardHeader>

          <CardDescription>{description}</CardDescription>
        </CardText>
        {subscriptionName !== 'Included in your plan' && (
          <ModuleTag color={tagColor} text={textColor}>
            <TagCircle color={circleColor} />
            <span>{subscriptionName}</span>
          </ModuleTag>
        )}
      </InnerContent>
    </CardWrapper>
  );
};

ModuleCardUnlocked.propTypes = {
  cardData: PropTypes.instanceOf(Object).isRequired,
};

export default ModuleCardUnlocked;
