/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import Select from 'react-select';

import PropTypes from 'prop-types';
import { BoxShadow } from '../../../../../shared/GlobalStyles';

import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import {
  setSaveDashboardModal,
  createDashboard,
} from '../../../../sport-coach-dashboard/ducks/sportCoachDashboardActions';
import Button from '../../../../../shared/components/Button/Button';
import { dashboardTracker } from '../../../../../shared/utils/amplitudeHelper';
import {
  createLMTemplate,
  setSaveLMDashModal,
} from '../../../../load-monitoring-dashboard/ducks/loadMonitoringDashboardActions';
import {
  createFPTemplate,
  setSaveFPDashModal,
} from '../../../../force-plate-dashboard/ducks/forcePlateDashboardActions';
import SharedTemplateModalContainer from './SharedTemplateModalContainer';

const ModalContainer = styled('div')`
  z-index: 1003;
  min-height: 270px;
  width: 50vw;
  overflow: auto;
  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
  min-width: 430px;
  max-height: calc(100vh - 20px);
  position: absolute;
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .print-button {
    background: #424242;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    min-height: 48px;
  }
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  user-select: none;
`;

const RemoveDiv = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1001;
  color: ${(props) => (props.isDarkMode ? 'white' : '#444444')};
  background: white;
  .remove-div-icon {
    cursor: pointer;
  }
`;

const ContentContainer = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  max-height: ${(props) => props.customHeight};
  .print-button {
    position: absolute;
    bottom: 0;
    background: #10cd8c;
  }
  transition: max-height 0.5s linear;
`;

const ModalHeader = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 40px;
`;

const HeaderIconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  height: 52px;
  width: 52px;
  border-radius: 50%;
`;

const ModalHeaderText = styled('div')`
  font-size: 24px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  margin-top: 10px;
`;

const DashTitle = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
`;

const DashTitleContainer = styled('div')`
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
`;

const DashTitleBox = styled('input')`
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding-left: 10px;
`;

const DashDescription = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
`;

const DashDescriptionBox = styled('textarea')`
  height: 60px;
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding-left: 10px;
  padding-top: 8px;
`;

const SlidesContainer = styled('div')`
  overflow: hidden;
  width: 100%;
  max-height: ${(props) => props.customHeight};
  transition: max-height 1s linear;
`;

const Slides = styled('div')`
  white-space: nowrap;
  transition: transform 0.5s ease;
  transform: ${(props) => `translateX(-${props.activeSlide * 100}%)`};
  height: 100%;
`;

const Slide = styled('div')`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
`;

const SlideIndicators = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  background: white;
`;

const SlideIndicator = styled('div')`
  width: 7px;
  height: 7px;
  border: 1px solid grey;
  background: ${(props) => (props.isActive ? 'grey' : 'none')};
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  transition: background-color 0.5s ease;
`;

const Slide2Block2 = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const BlockText = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 500;
`;

const PortalContainer = styled('div')`
  width: 100%;
  background: white;
  box-shadow: ${BoxShadow};
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  overflow: auto;
`;

const ErrorBox = styled('div')`
  display: flex;
  flex-direction: column;
`;

const MappedError = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 14px;
  color: #c0392b;
`;

const ButtonContainer = styled('div')`
  border-radius: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
`;

const RelativePortalContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DashDescriptionContainer = styled('div')`
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
`;

const selectBoxOne = [
  { name: 'All groups', id: 1 },
  { name: 'Select groups', id: 2 },
  { name: 'Private', id: 3 },
];

const SharedTemplateSaveModal = ({ dashboard }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  let isModalShowing = false;
  let setSaveModalShowing = () => {};
  let handleCreateTemplate = () => {};
  let groups = [];
  let loadMonitoringFormValues = {};
  let metricFiltersPerformance = [];
  let keyPerformanceMetrics = [];
  let exercises = [];
  let maxType = '';

  if (dashboard === 'Load Monitoring') {
    isModalShowing = useSelector(
      (state) => state.loadMonitoringDashboard.ui.saveModalShowing,
    );
    setSaveModalShowing = setSaveLMDashModal;
    handleCreateTemplate = createLMTemplate;
    groups = useSelector(
      (state) => state.loadMonitoringDashboard.data.reportableGroups,
    );
    loadMonitoringFormValues = useSelector(
      (state) => state.loadMonitoringDashboard.data.loadMonitoringFormValues,
    );
  }

  if (dashboard === 'Force Plate') {
    isModalShowing = useSelector(
      (state) => state.forcePlateDashboard.ui.saveModalShowing,
    );
    setSaveModalShowing = setSaveFPDashModal;
    handleCreateTemplate = createFPTemplate;
    groups = useSelector((state) => state.forcePlateDashboard.data.groups);
    metricFiltersPerformance = useSelector(
      (state) => state.forcePlateDashboard.data.metricFiltersPerformance,
    );
    keyPerformanceMetrics = useSelector(
      (state) => state.forcePlateDashboard.data.keyPerformanceMetrics,
    );
  }

  if (dashboard === 'KPI Tracker') {
    isModalShowing = useSelector(
      (state) => state.sportCoachDashboard.ui.saveDashboardModalShowing,
    );
    setSaveModalShowing = setSaveDashboardModal;
    handleCreateTemplate = createDashboard;
    groups = useSelector((state) => state.sportCoachDashboard.data.groups);
    exercises = useSelector(
      (state) => state.sportCoachDashboard.data.exercises,
    );
    maxType = useSelector((state) => state.sportCoachDashboard.data.maxType);
  }

  const [activeSlide, setActiveSlide] = useState(0);
  const [dashDescription, setDashDescription] = useState('');
  const [dashTitle, setDashTitle] = useState('');
  const [groupType, setGroupType] = useState({ name: 'All groups', id: 1 });
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [errors, setErrors] = useState({
    errorsExist: true,
    errors: [
      {
        error: 'name error',
        msg: 'You must add a dashboard title',
        isShowing: false,
      },
      {
        error: 'incomplete item error',
        msg: 'Some evaluation items are incomplete',
        isShowing: false,
      },
      {
        error: 'group error',
        msg: 'You must select at least one group',
        isShowing: false,
      },
      {
        error: 'API error',
        msg:
          'There was an issue saving the dashboard, please check the form and try again.',
        isShowing: false,
      },
    ],
  });

  const exerIds = exercises.length > 0 && exercises.map((item) => item.id);

  const handleClose = () => {
    dispatch(setSaveModalShowing(false));
    setActiveSlide(0);
    setDashTitle('');
    setDashDescription('');
    setGroupType({ name: 'All groups', id: 1 });
    setSelectedGroups([]);
  };

  useEffect(() => {
    if (isModalShowing === false) {
      setErrors({
        errorsExist: true,
        errors: [
          {
            error: 'name error',
            msg: 'You must add a dashboard title',
            isShowing: false,
          },
          {
            error: 'incomplete item error',
            msg: 'Some evaluation items are incomplete',
            isShowing: false,
          },
          {
            error: 'group error',
            msg: 'You must select at least one group',
            isShowing: false,
          },
          {
            error: 'API error',
            msg:
              'There was an issue saving the dashboard, please check the form and try again.',
            isShowing: false,
          },
        ],
      });
      setActiveSlide(0);
      setDashTitle('');
      setDashDescription('');
      setGroupType({ name: 'All groups', id: 1 });
      setSelectedGroups([]);
    }
  }, [isModalShowing]);

  const handleSubmit = () => {
    if (dashTitle.length) {
      if (activeSlide === 0) {
        setActiveSlide(1);
      } else {
        let groupArray = [];
        if (groupType.id === 1) {
          groupArray = groups.map((obj) => obj.id);
        } else if (groupType.id === 2) {
          if (selectedGroups.length > 0) {
            groupArray = selectedGroups.map((obj) => obj.id);
            const newErrors = { ...errors };
            newErrors.errors[2].isShowing = false;
            setErrors(newErrors);
          } else {
            const newErrors = { ...errors };
            newErrors.errors[2].isShowing = true;
            setErrors(newErrors);
            return;
          }
        }
        let submissionObj = {
          title: dashTitle,
          description: dashDescription,
        };
        if (dashboard === 'Load Monitoring') {
          const {
            questionnaire,
            arrayOfQuestions,
            showRangeAverages,
            showTotal,
          } = loadMonitoringFormValues;

          submissionObj = {
            ...submissionObj,
            groupIds: groupArray,
            isQuestionnaire: !!questionnaire,
            itemIds: questionnaire
              ? [questionnaire.id]
              : arrayOfQuestions.map((question) => question.id),
            showAverages: showRangeAverages,
            showTotals: showTotal,
          };
        }
        if (dashboard === 'Force Plate') {
          submissionObj = {
            ...submissionObj,
            chartForcePlateMetrics: metricFiltersPerformance,
            kpiForcePlateMetrics: keyPerformanceMetrics.map((metric) => metric.replace(/\s/g, '')),
            groupIds: groupArray,
          };
        }
        if (dashboard === 'KPI Tracker') {
          submissionObj = {
            ...submissionObj,
            maxType,
            exercises: exerIds,
            groups: groupArray,
          };
        }
        dispatch(handleCreateTemplate(currentUser.accountCode, submissionObj));
        // KPI tracking
        dashboardTracker(
          dashboard,
          'Save Dashboard',
          'Dashboard saved',
        );
        handleClose();
      }
    } else {
      const newErrors = { ...errors };
      newErrors.errors[0].isShowing = true;
      setErrors(newErrors);
    }
  };

  return (
    <>
      <ModalContainer
        isActive={isModalShowing}
        customWidth={activeSlide === 1 ? '550px' : '1000px'}
        customHeight={activeSlide === 1 ? '400px' : '1000px'}
      >
        <RelativePortalContainer>
          <ButtonContainer>
            <div />
            <PortalContainer>
              <RemoveDiv>
                <div
                  className='remove-div-icon'
                  onClick={() => {
                    if (activeSlide === 1) {
                      setActiveSlide(0);
                    }
                  }}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={18}
                    icon='left-arrow'
                    color={activeSlide === 1 ? 'black' : 'white'}
                  />
                </div>
                <ModalHeader>
                  <HeaderIconContainer>
                    <IcomoonReact iconSet={iconSet} size={20} icon='save' />
                  </HeaderIconContainer>
                  <ModalHeaderText>Save Dashboard</ModalHeaderText>
                  <ErrorBox>
                    {errors.errors[0].isShowing ? (
                      <MappedError>{errors.errors[0].msg}</MappedError>
                    ) : null}
                    {errors.errors[1].isShowing ? (
                      <MappedError>{errors.errors[1].msg}</MappedError>
                    ) : null}
                    {errors.errors[2].isShowing ? (
                      <MappedError>{errors.errors[2].msg}</MappedError>
                    ) : null}
                  </ErrorBox>
                </ModalHeader>
                <div className='remove-div-icon' onClick={handleClose}>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={15}
                    icon='remove'
                    color='black'
                  />
                </div>
              </RemoveDiv>
              <ContentContainer
                customHeight={(activeSlide === 1 && groupType.id !== 2) ? '200px' : '500px'}
              >
                <SlidesContainer customHeight='auto'>
                  <Slides activeSlide={activeSlide}>
                    <Slide>
                      <DashTitleContainer>
                        <DashTitle>Title</DashTitle>
                        <DashTitleBox
                          onBlur={() => {
                            if (!dashTitle.length) {
                              const newErrors = { ...errors };
                              newErrors.errors[0].isShowing = true;
                              setErrors(newErrors);
                            }
                          }}
                          value={dashTitle}
                          onChange={(e) => {
                            if (
                              errors.errors[0].isShowing
                              && e.target.value.length
                            ) {
                              const newErrors = { ...errors };
                              newErrors.errors[0].isShowing = false;
                              setErrors(newErrors);
                            }
                            setDashTitle(e.target.value);
                          }}
                          placeholder='Title'
                        />
                      </DashTitleContainer>
                      <DashDescriptionContainer>
                        <DashDescription>Description</DashDescription>
                        <DashDescriptionBox
                          value={dashDescription}
                          onChange={(e) => {
                            setDashDescription(e.target.value);
                          }}
                          placeholder='Description (optional)'
                        />
                      </DashDescriptionContainer>
                    </Slide>
                    <Slide>
                      <BlockText>Dashboard Access</BlockText>
                      <Slide2Block2>
                        <Select
                          className='item-select'
                          value={groupType}
                          options={selectBoxOne}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          onChange={(option) => {
                            setGroupType(option);
                          }}
                        />
                      </Slide2Block2>
                      {groupType.id === 2 ? (
                        <>
                          <BlockText>Select Groups</BlockText>
                          <Slide2Block2>
                            <Select
                              className='item-select'
                              options={groups}
                              isMulti
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              onChange={(selectGroups) => {
                                if (
                                  errors.errors[2].isShowing
                                  && selectGroups.length
                                ) {
                                  const newErrors = { ...errors };
                                  newErrors.errors[2].isShowing = false;
                                  setErrors(newErrors);
                                }
                                setSelectedGroups(selectGroups);
                              }}
                            />
                          </Slide2Block2>
                        </>
                      ) : null}
                    </Slide>
                  </Slides>
                </SlidesContainer>
              </ContentContainer>
            </PortalContainer>
            <SlideIndicators>
              <SlideIndicator isActive={activeSlide === 0} />
              <SlideIndicator isActive={activeSlide === 1} />
            </SlideIndicators>
            <Button
              bottom
              fullWidth
              cta={activeSlide === 0 ? 'Next' : 'Submit'}
              customTextColor='#FFFFFF'
              className='print-button'
              large
              square
              noBorder
              primary
              onClick={handleSubmit}
            />
          </ButtonContainer>
        </RelativePortalContainer>
      </ModalContainer>
      <SharedTemplateModalContainer
        isModalShowing={isModalShowing}
        onClose={handleClose}
        setModalShowing={setSaveModalShowing}
      />
    </>
  );
};

SharedTemplateSaveModal.defaultProps = {
  dashboard: 'Wearables',
};

SharedTemplateSaveModal.propTypes = {
  dashboard: PropTypes.string,
};

export default SharedTemplateSaveModal;
