/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import { useHistory } from 'react-router-dom';

import { BoxShadow } from '../../../../shared/GlobalStyles';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import {
  clearSCDashData,
  setIsOverview,
  setIsUnsavedDashModalShowing,
  setNextLocation,
  setSaveDashboardModal,
} from '../../ducks/sportCoachDashboardActions';
import Button from '../../../../shared/components/Button/Button';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const ModalContainer = styled('div')`
  z-index: 1003;
  min-height: 270px;
  width: 55vw;
  overflow: auto;
  @media only screen and (max-width : 768px) {
    width: 90vw;
  }
  min-width: 430px;
  max-height: calc(100vh - 20px);
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  user-select: none;
`;

const RemoveDiv = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1001;
  color: ${(props) => (props.isDarkMode ? 'white' : '#444444')};
	background: white;
  .remove-div-icon {
    cursor: pointer;
  }
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 20px;
`;

const ContentContainer = styled('div')`
  margin: 30px 15px;
  font-family: 'Nunito Sans';
  font-size: 16px;
`;

const ModalHeader = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 40px;
`;

const HeaderIconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  height: 52px;
  width: 52px;
  border-radius: 50%;
`;

const ModalHeaderText = styled('div')`
  font-size: 24px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  margin-top: 10px;
`;

const PortalContainer = styled('div')`
  width: 100%;
  background: white;
  box-shadow: ${BoxShadow};
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  overflow: auto;
`;

const RelativePortalContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const SaveButtonContainer = styled('div')`
  padding: 0px 10px;
  margin-bottom: 10px;
`;

const CloseButtonContainer = styled('div')`
  padding: 0px 10px;
`;

const UnsavedDashModal = () => {
  const isUnsavedDashModalShowing = useSelector(
    (state) => state.sportCoachDashboard.ui.isUnsavedDashModalShowing,
  );
  const nextLocation = useSelector((state) => state.sportCoachDashboard.data.nextLocation);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleSave = () => {
    // Amplitude tracking
    dashboardTracker('Sport Coach Dashboard', 'Unsaved Dash Warning', 'Save dashboard clicked');
    batch(() => {
      dispatch(setIsUnsavedDashModalShowing(false));
      dispatch(setSaveDashboardModal(true));
      dispatch(setNextLocation(null));
    });
  };

  const handleCloseWithNavigation = () => {
    // Amplitude tracking
    dashboardTracker('Sport Coach Dashboard', 'Unsaved Dash Warning', 'Continue without saving clicked');
    batch(() => {
      dispatch(setIsUnsavedDashModalShowing(false));
      dispatch(clearSCDashData());
      if (nextLocation) {
        if (nextLocation === '/ams-lite/kpi-tracker') {
          dispatch(setIsOverview(false));
          dispatch(setNextLocation(null));
        } else {
          history.push(nextLocation);
          dispatch(setNextLocation(null));
        }
      }
    });
  };

  const handleClose = () => {
    // Amplitude tracking
    dashboardTracker('Sport Coach Dashboard', 'Unsaved Dash Warning', 'Modal closed');
    batch(() => {
      dispatch(setIsUnsavedDashModalShowing(false));
      dispatch(setNextLocation(null));
    });
  };

  return (
    <ModalContainer
      isActive={isUnsavedDashModalShowing}
      customWidth='700px'
    >
      <RelativePortalContainer>
        <div />
        <PortalContainer>
          <RemoveDiv>
            <ModalHeader>
              <HeaderIconContainer>
                <IcomoonReact
                  iconSet={iconSet}
                  size={20}
                  icon='warning'
                />
              </HeaderIconContainer>
              <ModalHeaderText>Dashboard Not Saved</ModalHeaderText>
            </ModalHeader>
            <div
              className='remove-div-icon'
              onClick={handleClose}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={15}
                icon='remove'
                color='black'
              />
            </div>
          </RemoveDiv>
          <ContentContainer>
            This dashboard has not been saved, are you sure you want to exit without saving?
            Exiting now will discard any unsaved progress. If you have made any updates,
            close this modal to review and save your changes.
          </ContentContainer>
          <ButtonsContainer>
            <SaveButtonContainer>
              <Button
                cta='Save Dashboard'
                square
                noBorder
                fullWidth
                primary
                customColor='#444444'
                customTextColor='#FFFFFF'
                onClick={handleSave}
              />
            </SaveButtonContainer>
            <CloseButtonContainer>
              <Button
                cta='Continue Without Saving'
                square
                fullWidth
                borderColor='#444444'
                customColor='#FFFFFF'
                customTextColor='#444444'
                onClick={handleCloseWithNavigation}
              />
            </CloseButtonContainer>
          </ButtonsContainer>
        </PortalContainer>
      </RelativePortalContainer>
    </ModalContainer>
  );
};

UnsavedDashModal.defaultProps = {
  isDarkMode: false,
};

UnsavedDashModal.propTypes = {

};

export default UnsavedDashModal;
