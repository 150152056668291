const corePackage = {
  title: 'AMS Lite Core Package',
  core: true,
  unlocked: false,
  sections: [
    {
      name: 'Monitoring',
      description:
        'Generate insights based on athlete workload and self-reported questionnaire data.',
      icon: 'clipboard',
    },
    {
      name: 'Readiness',
      description:
        'Determine athlete readiness by viewing health metrics from wearable devices like sleep, heart rate, HRV, and more.',
      icon: 'heart-rate',
    },
    {
      name: 'Recovery',
      description:
        'Uncover athletes’ individualized responses to training and competition all while reducing the risk of injury.',
      icon: 'circuit',
    },
  ],
  videoUrl: 'https://www.youtube.com/watch?v=_QV5pVHHIEs',
  description: 'Compare athlete weight room load to questionnaire responses',
  icon: 'chart-line',
};

export const coreModules = [
  {
    title: 'Load Monitoring Dashboard',
    core: true,
    unlocked: false,
    sections: [
      {
        name: 'Monitoring',
        description:
          'Generate insights based on athlete workload and self-reported questionnaire data.',
        icon: 'chart-line',
      },
      {
        name: 'Readiness',
        description:
          'Determine athlete readiness by viewing data regarding sleep, fatigue, motivation, stress levels, and more.',
        icon: 'heart-rate',
      },
      {
        name: 'Recovery',
        description:
          'See how your athletes are recovering from competition and training sessions.',
        icon: 'circuit',
      },
    ],

    videoUrl: 'https://www.youtube.com/watch?v=soAvDS3obS0',
    description:
      'Compare athlete weight room load to questionnaire responses',
    icon: 'chart-line',
    activeTab: '/ams-lite/load-monitoring-dashboard',
  },
  {
    title: 'KPI Tracker',
    core: true,
    unlocked: false,
    sections: [
      {
        name: 'Collaborate',
        description:
          'Share individual athlete workout data with Sport Coaches so they can see how their athletes are progressing.',
        icon: 'fist-bump-full-together',
      },
      {
        name: 'Monitor Progress',
        description:
          'Sport & Performance coaches can easily see when athletes hit a new max or personal best.',
        icon: 'clipboard',
      },
      {
        name: 'Team Trends',
        description:
          'See how each athlete is developing compared to the rest of the team.',
        icon: 'group',
      },
    ],
    videoUrl: 'https://www.youtube.com/watch?v=ZRtmL63e62c',
    description: 'Share individual athlete workout data with Sport Coaches',
    icon: 'clipboard',
    activeTab: '/ams-lite/kpi-tracker',
  },
  {
    title: 'Body Heat Map',
    core: true,
    unlocked: false,
    // UPDATE STILL NEEDED FOR SECTIONS
    sections: [
      {
        name: 'Collaborate',
        description:
            'Share individual athlete workout data with Sport Coaches so they can see how their athletes are progressing.',
        icon: 'fist-bump-full-together',
      },
      {
        name: 'Monitor Progress',
        description:
            'Sport & Performance coaches can easily see when athletes hit a new max or personal best.',
        icon: 'clipboard',
      },
      {
        name: 'Team Trends',
        description:
            'See how each athlete is developing compared to the rest of the team.',
        icon: 'group',
      },
    ],
    // UPDATE STILL NEEDED FOR VIDEOURL
    videoUrl: 'https://www.youtube.com/watch?v=iJbmYLH90PE&t=161s',
    description: 'Monitor athletes experiencing any pain or soreness',
    icon: 'target',
    activeTab: '/ams-lite/body-heat-map',
  },
  {
    title: 'Habit Tracker',
    core: true,
    unlocked: false,
    sections: [
      {
        name: 'Create Consistency',
        description:
            'Create, manage, and assign custom habits for athletes to complete throughout the day or week.',
        icon: 'checkmark',
      },
      {
        name: 'Habit Monitoring',
        description:
            'Keep an eye on daily and weekly progress as athletes check off habits and log data into their app.',
        icon: 'chart',
      },
      {
        name: 'Stakeholder Collaboration',
        description:
            'Allow habits to be viewed by Sport Coaches, Athletic Trainers, Wellness Professionals, and other stakeholders.',
        icon: 'group',
      },
    ],
    videoUrl: 'https://www.youtube.com/watch?v=rTr7DW2eNSI',
    description: 'Create, Assign, and Monitor habits for users.',
    icon: 'checkmark',
    activeTab: '/ams-lite/habit-tracker',
  },
];

export const addOns = [
  {
    title: 'Force Plate Dashboard',
    addOnId: 1,
    core: false,
    unlocked: false,
    sections: [
      {
        name: 'Integrated',
        description:
          'Using imported data from our 2 partners - Hawkin Dynamic and VALD - our Force Plate Module rethinks how to display external data.',
        icon: 'force-plate',
      },
      {
        name: 'Summarize',
        description:
          'This module takes the simple approach to viewing Force Plate data by reading fewer metrics and highlighting unique values and trends.',
        icon: 'file',
      },
      {
        name: 'Groups vs. Individual',
        description:
          'Visualize force-velocity trends and profiles for an individual or a team, or position group - with trend analysis.',
        icon: 'conversation-group',
      },
    ],
    videoUrl: 'https://www.youtube.com/watch?v=wpzZlqbBeME',
    description: 'Monitor group and athlete data collected with force plates',
    icon: 'force-plate',
    activeTab: '/ams-lite/force-plate-dashboard',
  },
];

export default corePackage;
