/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import tinycolor from 'tinycolor2';
import { useLocation } from 'react-router';
import { Global, css } from '@emotion/core';

import Button from '../../shared/components/Button/Button';
import HeaderText from '../../shared/components/HeaderText/HeaderText';
import LightLogo from '../../shared/images/poweredByTBWhite.png';
import DarkLogo from '../../shared/images/poweredByTBBlack.png';
import Spinner from '../../shared/components/Spinner/Spinner';
import InvalidTokenModal from '../sport-coach-dashboard/components/presentational/InvalidTokenModal';
import SharedTemplateAboutModal from '../sports-science/components/presentational/sharedTemplateComponents/SharedTemplateAboutModal';
import ViewOnlyPerformanceOverview from './components/presentational/ViewOnlyPerformanceOverview';
import ViewOnlyGroupAnalysis from './components/presentational/ViewOnlyGroupAnalysis';
import MetricInfoModal from './components/presentational/MetricInfoModal';
import LegendInfoModal from './components/presentational/LegendInfoModal';
import { dashboardTracker } from '../../shared/utils/amplitudeHelper';

import {
  fetchPublicFPGroupAnalysisData,
  fetchPublicFPPerformanceCardData,
  fetchPublicFPPerformanceChartData,
  setAboutFPDashModal,
  setForcePlateView,
} from './ducks/forcePlateDashboardActions';

const Container = styled('div')`
  width: 100%;
  overflow-x: auto !important;
`;

const Wrapper = styled('div')`
  height: 100%;
`;

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background: #f5f5f5;
`;

const DashboardBanner = styled('div')`
  background-color: ${(props) => props.bgColor};
  height: 100px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
`;

const UserLogo = styled('img')`
  height: 75px;
`;

const TBuildrLogo = styled('div')`
  background-image: ${(props) => (tinycolor(props.bgColor).isLight()
    ? `url(${DarkLogo})`
    : `url(${LightLogo})`)};
  background-repeat: no-repeat;
  height: 46px;
  width: 200px;
`;

const DashboardHeader = styled('div')`
  display: flex;
  width: 100%;
  background-color: #ffffff;
  padding-top: 30px;
  padding-left: 20px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`;

const TitleAndInfo = styled('div')`
  display: flex;

  button {
    margin-left: 5px;
  }

  svg {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 3px;
  }
`;

const MenuContainer = styled('div')`
  display: flex;
`;

const MenuItem = styled('button')`
  border: none;
  margin-left: 10px;
  margin-right: 12px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-size: 16px;
  border-bottom: ${(props) => (props.isActive ? '3px solid #1787FB!important' : '3px solid white')};
  color: ${(props) => (!props.isActive ? '#9E9E9E' : '#444444')};
`;

const ViewOnlyFPDash = () => {
  const aboutModalShowing = useSelector(
    (state) => state.forcePlateDashboard.ui.aboutModalShowing,
  );
  const dashBGColor = useSelector(
    (state) => state.forcePlateDashboard.data.dashBGColor,
  );
  const dashLogo = useSelector(
    (state) => state.forcePlateDashboard.data.dashLogo,
  );
  const dashTitle = useSelector(
    (state) => state.forcePlateDashboard.data.dashTitle,
  );
  const performanceOverviewData = useSelector(
    (state) => state.forcePlateDashboard.data.performanceOverviewData,
  );
  const metricCardData = useSelector(
    (state) => state.forcePlateDashboard.data.metricCardData,
  );
  const performanceOverviewError = useSelector(
    (state) => state.forcePlateDashboard.data.performanceOverviewError,
  );
  const groupAnalysisAthleteData = useSelector(
    (state) => state.forcePlateDashboard.data.groupAnalysisAthleteData,
  );
  const groupAnalysisAverageData = useSelector(
    (state) => state.forcePlateDashboard.data.groupAnalysisAverageData,
  );
  const flaggedAthletesDataGroup = useSelector(
    (state) => state.forcePlateDashboard.data.flaggedAthletesDataGroup,
  );
  const groupAnalysisError = useSelector(
    (state) => state.forcePlateDashboard.data.groupAnalysisError,
  );
  const forcePlateView = useSelector(
    (state) => state.forcePlateDashboard.ui.forcePlateView,
  );
  const isLegendInfoModalShowing = useSelector(
    (state) => state.forcePlateDashboard.ui.isLegendInfoModalShowing,
  );
  const isMetricInfoModalShowing = useSelector(
    (state) => state.forcePlateDashboard.ui.isMetricInfoModalShowing,
  );
  const isPerformanceLoading = useSelector(
    (state) => state.forcePlateDashboard.ui.isPerformanceLoading,
  );
  const isMetricLoading = useSelector(
    (state) => state.forcePlateDashboard.ui.isMetricLoading,
  );
  const isGroupAnalysisLoading = useSelector(
    (state) => state.forcePlateDashboard.ui.isGroupAnalysisLoading,
  );
  const pageLoadingPerformance = forcePlateView === 'performance' && isPerformanceLoading && isMetricLoading;
  const pageLoadingGroup = forcePlateView === 'group' && isGroupAnalysisLoading;

  const fpDashError = (forcePlateView === 'performance' && performanceOverviewError)
    || (forcePlateView === 'group' && groupAnalysisError);

  const hasData = !!performanceOverviewData.length
    || !!metricCardData.length
    || !!groupAnalysisAthleteData.length
    || !!groupAnalysisAverageData.length
    || !!flaggedAthletesDataGroup.length;

  /** Determine if no data due to token error or actual lack of data
   * Used to determine copy used in modal */
  const noData = !hasData
    && (!fpDashError
      || (fpDashError && fpDashError === 'No tests found for athletes'));

  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');

  const dispatch = useDispatch();

  /** Fetch data on page load with token
   * Separate calls for performance overview chart, kpi cards, and group analysis */
  useEffect(() => {
    dispatch(fetchPublicFPPerformanceChartData(token));
    dispatch(fetchPublicFPPerformanceCardData(token));
    dispatch(fetchPublicFPGroupAnalysisData(token));
  }, []);

  const handleMenuClick = (view) => {
    if (view !== forcePlateView) {
      dispatch(setForcePlateView(view));
    }
    // Amplitude tracking
    dashboardTracker(
      'Force Plate Dashboard',
      view === 'group' ? 'Group Analysis' : 'Performance Overview',
      'Menu item clicked',
    );
  };

  return (
    <Container>
      <Global
        styles={css`
          body {
            overflow-y: hidden;
          }
        `}
      />
      {isMetricInfoModalShowing && <MetricInfoModal />}
      {isLegendInfoModalShowing && <LegendInfoModal />}
      <Helmet>
        <title>Force Plate Dashboard | TeamBuildr</title>
      </Helmet>
      {((fpDashError && fpDashError !== 'No tests found for athletes')
        || (!pageLoadingPerformance && !pageLoadingGroup && !hasData)) && (
        <InvalidTokenModal noData={noData} />
      )}
      {!hasData && (pageLoadingPerformance || pageLoadingGroup) && (
        <Wrapper>
          <Spinner />
        </Wrapper>
      )}
      <PageWrapper>
        <DashboardBanner bgColor={dashBGColor || '#444444'}>
          {dashLogo ? (
            <UserLogo src={dashLogo} alt='user logo' id='userLogo' />
          ) : (
            <div />
          )}
          <TBuildrLogo bgColor={dashBGColor || '#444444'} />
        </DashboardBanner>
        {hasData && (
          <>
            <DashboardHeader>
              <TitleAndInfo>
                <HeaderText fontWeight='300' letterSpacing='normal'>
                  {dashTitle}
                </HeaderText>
                <Button
                  iconOnly
                  icon='info'
                  customColor='#00000000'
                  noBorder
                  onClick={() => dispatch(setAboutFPDashModal(true))}
                  rounded
                />
              </TitleAndInfo>
              <MenuContainer>
                <MenuItem
                  isActive={forcePlateView === 'performance'}
                  onClick={() => handleMenuClick('performance')}
                >
                  Performance Overview
                </MenuItem>
                <MenuItem
                  isActive={forcePlateView === 'group'}
                  onClick={() => handleMenuClick('group')}
                >
                  Group Analysis
                </MenuItem>
              </MenuContainer>
            </DashboardHeader>
            {forcePlateView === 'performance' && (
              <ViewOnlyPerformanceOverview />
            )}
            {forcePlateView === 'group' && <ViewOnlyGroupAnalysis />}
            {aboutModalShowing && (
              <SharedTemplateAboutModal dashboard='Force Plate' />
            )}
          </>
        )}
      </PageWrapper>
    </Container>
  );
};

export default ViewOnlyFPDash;
