/* eslint-disable no-nested-ternary */
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';

import { metricUnits, metricValueDefaults } from '../../metricConstants';
import Button from '../../../../shared/components/Button/Button';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import {
  setIsLegendInfoModalShowing,
  setIsMetricInfoModalShowing,
} from '../../ducks/forcePlateDashboardActions';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const MetricsContainer = styled('div')`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 0px 5px;
  filter: ${(props) => (props.isBlurred ? 'blur(1.2px)' : null)};
  padding-bottom: 10px;
`;

const MetricContainer = styled('div')`
  min-width: 155px;
  height: 150px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px 5px 5px 15px;
  box-shadow: ${BoxShadow};
  flex: 1;
  margin: 10px;
`;

const TitleAndIcon = styled('div')`
  display: flex;
  justify-content: space-between;
  white-space: nowrap;

  svg {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 3px;
  }
`;

const MetricTitle = styled('div')`
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: 'Nunito Sans';
  font-size: 19px;
  font-weight: 400;
`;

const MetricData = styled('div')`
  height: 75px;
  display: flex;
  align-items: center;
  font-size: 28px;
  font-family: 'Nunito Sans';
  font-weight: 700;
`;

const Measurement = styled('div')`
  font-size: 25px;
  font-family: 'Nunito Sans';
  font-weight: 300;
  margin-left: 10px;
  margin-top: 20px;
`;

const PercentageContainer = styled('div')`
  display: flex;
  align-items: center;
  svg {
    fill: ${(props) => (props.color ? `${props.color}` : '#9E9E9E')};
  }
`;

const Percentage = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 19px;
  font-weight: 400;
  margin-left: 5px;
`;

const MetricItem = ({
  metricName, metricData, handleInfoClick, hasData,
}) => (
  <MetricContainer>
    <TitleAndIcon>
      <MetricTitle>{metricName}</MetricTitle>
      <Button
        iconOnly
        icon='info'
        noBorder
        onClick={() => handleInfoClick(metricName)}
        rounded
        disabled={!hasData}
      />
    </TitleAndIcon>
    <MetricData>
      {metricData?.value
        || metricData?.averageValue
        || metricValueDefaults[metricName]}
      <Measurement>{metricData?.units || metricUnits[metricName]}</Measurement>
    </MetricData>
    <PercentageContainer color={metricData?.alertColor || null}>
      <IcomoonReact
        iconSet={iconSet}
        size={13}
        icon={
          metricData?.percentChange > 0
            ? 'alt-up-arrow'
            : metricData?.percentChange < 0
              ? 'alt-down-arrow'
              : 'alt-right-arrow'
        }
      />
      <Percentage>
        {metricData?.percentChange || '0.00'}
        %
      </Percentage>
    </PercentageContainer>
  </MetricContainer>
);

MetricItem.propTypes = {
  metricName: PropTypes.string.isRequired,
  metricData: PropTypes.instanceOf(Object).isRequired,
  handleInfoClick: PropTypes.func.isRequired,
  hasData: PropTypes.bool.isRequired,
};

const MetricsContainers = () => {
  const isAthleteSelectedPerformance = useSelector(
    (state) => state.forcePlateDashboard.ui.isAthleteSelectedPerformance,
  );
  const metricCardData = useSelector(
    (state) => state.forcePlateDashboard.data.metricCardData,
  );
  const selectedAthletePerformance = useSelector(
    (state) => state.forcePlateDashboard.data.selectedAthletePerformance,
  );

  const keyPerformanceMetrics = useSelector(
    (state) => state.forcePlateDashboard.data.keyPerformanceMetrics,
  );

  const hasData = metricCardData.length > 0;
  const data = hasData && [...metricCardData];

  const orderedData = hasData && data.sort((a, b) => new Date(a.date) - new Date(b.date));

  const endDateData = orderedData && orderedData.pop();
  const athleteData = isAthleteSelectedPerformance
    && endDateData
    && endDateData.athleteData.find(
      (obj) => obj.fullName === selectedAthletePerformance.fullName,
    );

  const metricGroupData = (metricName) => hasData
    && endDateData.groupData[0].metrics.find(
      (obj) => obj.metricName === metricName,
    );

  const metricAthleteData = (metricName) => isAthleteSelectedPerformance
    && athleteData
    && athleteData.testResults.find((obj) => obj?.metricName === metricName);

  const metricData = (metricName) => (isAthleteSelectedPerformance
    ? metricAthleteData(metricName)
    : metricGroupData(metricName));

  const dispatch = useDispatch();

  const handleInfoClick = (metric) => {
    // KPI tracking
    dashboardTracker(
      'Force Plate Dashboard',
      'Performance Overview - Metric Legend',
      `Legend info button clicked for ${metric} card`,
    );
    batch(() => {
      dispatch(setIsLegendInfoModalShowing(false));
      dispatch(setIsMetricInfoModalShowing(true, metric));
    });
  };

  return (
    <MetricsContainer isBlurred={!hasData}>
      {keyPerformanceMetrics.map((metric) => (
        <MetricItem
          metricName={metric}
          metricData={metricData(metric)}
          hasData={hasData}
          handleInfoClick={handleInfoClick}
        />
      ))}
    </MetricsContainer>
  );
};

export default MetricsContainers;
