/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import Select from 'react-select';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme } from 'emotion-theming';

import iconSet from '../../shared/images/teambuildr-selection.json';
import {
  fetchExercises, setExercises, setMaxType, setSCDashView,
} from './ducks/sportCoachDashboardActions';
import Button from '../../shared/components/Button/Button';
import { dashboardTracker } from '../../shared/utils/amplitudeHelper';

const NewContainer = styled('div')`
  width: 100%;
  padding: 20px;
  display: flex;
  height: auto;
`;

const BackgroundContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  background: white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
`;

const UpperContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 200px;
  border-bottom: 2px solid #EEEEEE;
`;

const PlusIconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  border: 1px solid black;
`;

const Header = styled('div')`
  font-size: 23px;
  font-family: 'Nunito Sans';
  color: black;
  margin-top: 10px;
`;

const SubHeader = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  color: ${(props) => props.color};
`;

const SubHeaderContainer = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  color: black;
  margin-top: 20px;
  display: flex;
  width: 100%;
`;

const CenterContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 85%;
  height: 100%;
`;

const SelectBoxContainer = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 35px;
`;

const SelectBoxText = styled('div')`
  display: flex;
  color: ${(props) => props.color};
  margin-bottom: 5px;
  font-size: 16px;
`;

const LowerBox = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 500px;
  margin-top: 20px;
`;

const RightBox = styled('div')`
  display: flex;
  width: 48%;
  height: 100%;
  flex-direction: column;
`;

const LeftBox = styled('div')`
  display: flex;
  width: 48%;
  height: 100%;
  flex-direction: column;
`;

const ButtonContainer = styled('div')`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  .load-button {
    background-color: #444444;
    border-radius: 4px;
  }
  width: 100%;
`;

const BoxTitle = styled('div')`
  display: flex;
  height: 25px;
  width: 100%;
  font-size: 16px;
  font-family: 'Nunito Sans';
`;

const SearchAndIcon = styled('div')`
  display: flex;
  align-items: center;
  border: 1px solid #EEEEEE;
  border-radius: 50px;
  background: white;
  transition: max-width 0.5s ease;
  overflow: hidden;
  width: 100%;
`;

const SearchIconContainer = styled('div')`
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
`;

const SearchContainer = styled('input')`
  display: flex;
  height: 40px;
  border: none;
  background: none;
  padding-left: 5px;
  width: 100%;
`;

const SearchAndFilter = styled('div')`
  display: flex;
  width: 100%;
  margin-top: 10px;
`;

const ExercisesBox = styled('div')`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: lightgrey transparent;
  background: #FCFCFC;
  padding: 15px;
  margin-top: 20px;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  height: 100%;
`;

const AvailableExercise = styled('div')`
  display: flex;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.color};
  padding-left: 15px;
`;

const RemoveContainer = styled('div')`
  cursor: pointer;
`;

const maxTypes = [
  { label: 'All maxes and PRs', id: 1 },
  { label: 'Only Manually Entered Maxes and PRs', id: 2 },
  { label: 'Only Generated Maxes and PRs', id: 3 },
];

const NewDashboardComponent = ({
  setIsOverview,
  setNewDash,
}) => {
  const theme = useTheme();

  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const availableExercises = useSelector(
    (state) => state.sportCoachDashboard.data.availableExercises,
  );

  const [searchedList, setSearchedList] = useState([]);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [areChecksDisabled, setAreChecksDisabled] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedExercises, setSelectedExercises] = useState({});

  useEffect(() => {
    const searchedArray = [];
    availableExercises.forEach((exercise) => {
      if (exercise.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        searchedArray.push(exercise);
      }
    });
    if (searchedArray.length) {
      setSearchedList(searchedArray);
    } else {
      setSearchedList([]);
    }
  }, [searchTerm]);

  /**
   * Every time the list of selected exercises changes, the
   * component makes a new determination of whether or not
   * additional exercises can be selected. As we know, we
   * want a maximum of 10 exercises to exist in the selected
   * list. When an exercise is added to the object and then
   * subtracted, its entry is not deleted, but rather set to
   * an empty object. Therefore it's necessary to iterate over
   * a list of values representing that object and figure
   * if that value has length to determine whether or not the
   * exercise actually exists in the list.
   *
   * Once it's submitted, the object once again gets reduced
   * to a list and any entry that has a populated value gets
   * submitted in the list of exercises.
   */
  useEffect(() => {
    const exerciseValues = Object.values(selectedExercises);
    let num = 0;
    exerciseValues.forEach((value) => {
      if (Object.keys(value).length) {
        num += 1;
      }
    });
    if (num >= 10) {
      setAreChecksDisabled(true);
    } else if (num < 10) {
      setAreChecksDisabled(false);
    }
  }, [selectedExercises]);

  useEffect(() => {
    if (Object.keys(currentUser).length) {
      dispatch(fetchExercises(currentUser.accountCode));
    }
  }, [currentUser]);

  const exerciseResolver = (exercise) => {
    if (Object.keys(exercise).length) {
      return (
        <AvailableExercise color={theme.colors.text}>
          <div>{exercise.name}</div>
          <RemoveContainer onClick={() => {
            const newOb = { ...selectedExercises };
            newOb[exercise.id] = {};
            setSelectedExercises(newOb);
          }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon='remove'
              color='#9E9E9E'
            />
          </RemoveContainer>
        </AvailableExercise>
      );
    }
    return null;
  };

  const isLoadDisabled = (selectedExercisesObject) => {
    const exerciseValues = Object.values(selectedExercisesObject);
    let isDisabled = true;
    exerciseValues.forEach((exercise) => {
      if (Object.keys(exercise).length) {
        isDisabled = false;
      }
    });
    return isDisabled;
  };

  const submitExercises = (selectedExercisesObject) => {
    const submissionArray = [];
    const exerciseValues = Object.values(selectedExercisesObject);
    exerciseValues.forEach((exercise) => {
      if (Object.keys(exercise).length) {
        submissionArray.push({
          id: exercise.id,
          name: exercise.name,
          type: exercise.type,
          unitAbbr: exercise.unitAbbr,
          unitName: exercise.unitName,
          inputType: exercise.inputType,
        });
      }
    });
    dispatch(setExercises(submissionArray));
    setNewDash(false);
    setIsOverview();
    setSCDashView('performance');
  };

  return (
    <NewContainer>
      <BackgroundContainer>
        <CenterContainer>
          <UpperContainer>
            <PlusIconContainer>
              <IcomoonReact
                iconSet={iconSet}
                size={25}
                icon='plus'
                color='black'
              />
            </PlusIconContainer>
            <Header>
              New Dashboard
            </Header>
          </UpperContainer>
          <SubHeaderContainer>
            <SubHeader color={theme.colors.darkGray}>
              Select the exercise results and up to 10 exercises to add to this dashboard.
            </SubHeader>
          </SubHeaderContainer>
          <SelectBoxContainer>
            <SelectBoxText color={theme.colors.text}>
              Maxes and PRs
            </SelectBoxText>
            <Select
              className='item-select'
              options={maxTypes}
              defaultValue={maxTypes[0]}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.id}
              onChange={(option) => {
                dispatch(setMaxType(option.id));
              }}
            />
          </SelectBoxContainer>
          <LowerBox>
            <LeftBox>
              <BoxTitle>
                All Exercises
              </BoxTitle>
              <SearchAndFilter>
                <SearchAndIcon id='search-container' onClick={() => setIsSearchFocused(true)} isFocused={isSearchFocused}>
                  <SearchIconContainer id='search-icon-container'>
                    <IcomoonReact
                      iconSet={iconSet}
                      size={20}
                      icon='magnifying-glass-alt'
                      color='#9E9E9E'
                    />
                  </SearchIconContainer>
                  <SearchContainer
                    placeholder='Search'
                    id='search-container-input'
                    value={searchTerm}
                    onFocus={() => setIsSearchFocused(true)}
                    onChange={(inputValue) => {
                      setSearchTerm(inputValue.target.value);
                    }}
                  />
                </SearchAndIcon>
              </SearchAndFilter>
              <ExercisesBox>
                {!searchTerm.length ? availableExercises.map(
                  (exercise) => (
                    <AvailableExercise color={theme.colors.text}>
                      <div>{exercise.name}</div>
                      <div>
                        <Checkbox
                          disabled={
                            areChecksDisabled
                            && (!selectedExercises[exercise.id]
                              || Object.keys(selectedExercises[exercise.id]).length === 0)
                          }
                          key={exercise.id}
                          checked={
                            !!(selectedExercises[exercise.id]
                            && Object.keys(selectedExercises[exercise.id]).length)
                          }
                          onChange={() => {
                            const newOb = { ...selectedExercises };
                            if (!newOb[exercise.id]) {
                              newOb[exercise.id] = exercise;
                            } else if (
                              newOb[exercise.id] && Object.keys(newOb[exercise.id]).length
                            ) {
                              newOb[exercise.id] = {};
                            } else if (
                              newOb[exercise.id] && Object.keys(newOb[exercise.id]).length === 0) {
                              newOb[exercise.id] = exercise;
                            }
                            setSelectedExercises(newOb);
                          }}
                          color='primary'
                        />
                      </div>
                    </AvailableExercise>
                  ),
                ) : searchedList.map(
                  (exercise) => (
                    <AvailableExercise color={theme.colors.text}>
                      <div>{exercise.name}</div>
                      <div>
                        <Checkbox
                          disabled={
                            areChecksDisabled
                            && (!selectedExercises[exercise.id]
                              || Object.keys(selectedExercises[exercise.id]).length === 0)
                          }
                          checked={
                            !!(selectedExercises[exercise.id]
                            && Object.keys(selectedExercises[exercise.id]).length)
                          }
                          onChange={() => {
                            const newOb = { ...selectedExercises };
                            if (!newOb[exercise.id]) {
                              newOb[exercise.id] = exercise;
                            } else if (
                              newOb[exercise.id] && Object.keys(newOb[exercise.id]).length
                            ) {
                              newOb[exercise.id] = {};
                            } else if (
                              newOb[exercise.id] && Object.keys(newOb[exercise.id]).length === 0) {
                              newOb[exercise.id] = exercise;
                            }
                            setSelectedExercises(newOb);
                          }}
                          color='primary'
                        />
                      </div>
                    </AvailableExercise>
                  ),
                )}
              </ExercisesBox>
            </LeftBox>
            <RightBox>
              <BoxTitle>
                Selected Exercises
              </BoxTitle>
              <ExercisesBox>
                {Object.values(selectedExercises).map(
                  (exercise) => exerciseResolver(exercise),
                )}
              </ExercisesBox>
            </RightBox>
          </LowerBox>
          <ButtonContainer>
            <Button
              bottom
              fullWidth
              cta='Load Dashboard'
              large
              customTextColor='#FFFFFF'
              square
              className='load-button'
              disabled={isLoadDisabled(selectedExercises)}
              noBorder
              primary
              onClick={() => {
                submitExercises(selectedExercises);
                // Amplitude tracking
                dashboardTracker('Sport Coach Dashboard', 'New Dashboard', 'Load dashboard clicked');
              }}
            />
          </ButtonContainer>
        </CenterContainer>
      </BackgroundContainer>
    </NewContainer>
  );
};

export default NewDashboardComponent;
