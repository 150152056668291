/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, {
  useState, useEffect, useMemo, useRef,
} from 'react';
import { createPortal } from 'react-dom';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';
import Shimmer from 'react-shimmer-effect';
import { openPopupWidget } from 'react-calendly';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import ssHubHomeIcon from '../../../../shared/images/ss-hub-home-icon.svg';
import amsLiteLogo from '../../../../shared/images/ss-hub/ams-lite-logo.svg';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import {
  setIsUpgradeModalShowing,
  fetchSubscriptionPackage,
  fetchSubscriptionStatus,
  setSelectedPackage,
  setIsSideBarExpandedGlobal,
  setIsLearnMoreModalShowing,
  setIsUpdatedUpgradeModalShowing,
} from '../../ducks/sportsScienceActions';
import corePackage from './packages';
import bhmOnboardingImageOne from '../../../../shared/images/rr-onboarding-one.png';
import bhmOnboardingImageTwo from '../../../../shared/images/rr-onboarding-two.png';
import bhmOnboardingImageThree from '../../../../shared/images/rr-onboarding-three.png';
import bhmOnboardingImageFour from '../../../../shared/images/rr-onboarding-four.png';
import bhmOnboardingImageFive from '../../../../shared/images/rr-onboarding-five.png';
import htOnboardingImageOne from '../../../../shared/images/ht-onboarding-one.png';
import htOnboardingImageTwo from '../../../../shared/images/ht-onboarding-two.png';
import htOnboardingImageThree from '../../../../shared/images/ht-onboarding-three.png';
import htOnboardingImageFour from '../../../../shared/images/ht-onboarding-four.png';
import {
  dashboardTracker,
  subscriptionFlowTracker,
  subscriptionFlowTrackerDemo,
} from '../../../../shared/utils/amplitudeHelper';

const SideBarDiv = styled('div')`
  transition: all 0.3s ease;
  margin-top: 49px;
  @media screen and (max-width: 714px) {
    width: ${(props) => (props.isSideBarActive ? '100%' : '0%')};
  }
  overflow-y: hidden;
  height: 100%;
  z-index: 1;
  width: ${(props) => (props.isExpanded ? '300px' : '106px')};
`;

const SideBarBannerContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.isExpanded ? '20px' : '10px')};
  width: 100%;
  background: #444;
  color: white;
  font-family: "Nunito Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
`;

const TeamBuildrLogo = styled('div')`
  background-image: url(${amsLiteLogo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: ${(props) => (props.isExpanded ? '100px' : '80px')};
  width: ${(props) => (props.isExpanded ? '214px' : '80px')};
  margin-right: ${(props) => (props.isExpanded ? '8px' : 0)};
  transition: all 0.3s ease;
`;

const SSHomeIcon = styled('div')`
  background-image: url(${ssHubHomeIcon});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 40px;
  width: 40px;
`;

const SideBarHeader = styled('div')`
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Nunito Sans';
  font-size: ${(props) => (props.isExpanded ? '16px' : '13px')};
  font-style: normal;
  font-weight: ${(props) => (props.isExpanded ? 700 : 900)};
  line-height: 150%;
  display: flex;
  align-items: center;
  padding: ${(props) => (props.isExpanded ? '24px 16px' : '24px 20px')};
  justify-content: ${(props) => (props.isExpanded ? 'flex-end' : 'center')};
  @media screen and (max-width: 714px) {
    justify-content: flex-end;
    display: none;
  }
`;

const MenuAndButtons = styled('div')`
  gap: 16px;
  margin-bottom: ${(props) => (props.isExpanded ? '175px' : '200px')};
  overflow: auto;
  padding-bottom: 50px;
  flex: 1 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: ${(props) => (props.isExpanded ? 'hidden' : 'clip')};
  overflow-y: auto;
  scrollbar-gutter: stable;
  scrollbar-width: thin;
  padding: 0px 0px 0px ${(props) => (props.isExpanded ? '15px' : '11px')};
  @media screen and (max-width: 714px) {
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }
`;

const MenuTab = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${'' /* padding: 15px; */}
  padding: ${(props) => (props.isExpanded ? '15px' : '0px')};
  gap: 16px;
  min-height: 80px;
  height: 80px;
  width: ${(props) => (props.isExpanded
    ? !props.hasLockedPackage
      ? '100%'
      : 'calc(100% - 10px)'
    : '80px')};  
  background: ${(props) => (props.isAddon
    ? props.unlocked
      ? 'white'
      : 'linear-gradient(270deg, #6A82FB 0%, #FC5C7D 100%)'
    : !props.activeTab
      ? !props.unlocked
        ? 'linear-gradient(90deg, #667EEA 0%, #764BA2 100%)'
        : 'white'
      : 'white')};
  border-radius: 8px;
  color: ${(props) => (!props.activeTab ? (props.unlocked ? '#9E9E9E' : 'white') : '#9E9E9E')};
  position: relative;
  border: ${(props) => (props.activeTab
    ? '1px solid #0DCC8A'
    : props.unlocked
      ? '#9E9E9E'
      : 'white')};
  box-shadow: ${BoxShadow};
  transition: all 0.3s ease;
  cursor: pointer;
  margin-right: ${(props) => (props.hasLockedPackage ? (props.isExpanded ? '10px' : '4px') : '')};
  @media screen and (max-width: 714px) {
    width: 100%;
  }
`;

const MenuTabContent = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
  &:hover {
    opacity: 0.7;
  }
`;

const ShimmerMenuTab = styled('div')`
  width: 270px;
  height: 120px;
  border-radius: 8px;
  box-shadow: ${BoxShadow};
`;

const TabTitle = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.activeTab ? 'black' : props.unlocked ? 'black' : 'white')};
  transition: all 0.3s ease;
  display: ${(props) => (props.isExpanded ? 'flex' : 'none')};
  @media screen and (max-width: 714px) {
    display: flex;
  }
`;

const TitleAndDescription = styled('div')`
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled('div')`
  margin-left: ${(props) => (props.isSideBarExpanded ? '0px' : '21px')};
  transition: all 0.3s ease;
`;

const ModalButtons = styled('div')`
  display: flex;
  padding: 16px 0px;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 50px;
  padding: ${(props) => (props.isSideBarExpanded ? '15px' : '5px')};
`;

const DemoButtonIconContainer = styled('div')`
  margin-right: ${(props) => (props.isSideBarExpanded ? '10px' : '0px')};
  @media screen and (max-width: 714px) {
    margin-right: 10px;
  }
`;

const UpgradeButtonIconContainer = styled('div')`
  margin-right: ${(props) => (props.isSideBarExpanded ? '10px' : '0px')};
  background: ${(props) => (props.isSideBarExpanded ? '#444444' : '#ffffff')};
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 714px) {
    margin-right: 10px;
  }
`;

const SpacingWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background: white;
`;

const LockContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.isExpanded ? '45px' : '20px')};
  width: ${(props) => (props.isExpanded ? '45px' : '20px')};
  background: #444444;
  position: absolute;
  right: ${(props) => (props.isExpanded ? '-10px' : '-5px')};
  top: -10px;
  border-radius: 50%;
  cursor: pointer;
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  transition: opacity 0.3s ease;
`;

const UpgradeButton = styled('div')`
  display: ${(props) => (props.isActive ? 'flex' : 'none')};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => (props.isExpanded ? '10000px' : '8px')};
  border: 3px solid #444444;
  height: ${(props) => (props.isExpanded ? '40px' : '60px')};
  width: ${(props) => (props.isExpanded ? '100%' : '80px')};
  margin-bottom: 5px;
  color: #444444;
  font-family: 'Nunito Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 19.5px */
  letter-spacing: 0.39px;
  line-height: 20px;
  cursor: ${(props) => (props.isActive ? 'pointer' : 'default')};
  background: #ffffff;
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  transition: opacity 0.3s ease;
  padding: ${(props) => (props.isExpanded ? '8px 12px' : '12px 20px')};
  .button-text {
    display: ${(props) => (props.isExpanded ? 'flex' : 'none')};
  }
  @media screen and (max-width: 714px) {
    border-radius: 10000px;
    height: 40px;
    width: 100%;
    padding: 8px 12px;
    .button-text {
      display: flex;
    }
  }

  &:hover {
    opacity: 0.7;
  }
`;

const BookModalButton = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) => (props.isExpanded ? '10000px' : '8px')};
  height: ${(props) => (props.isExpanded ? '40px' : '60px')};
  width: ${(props) => (props.isExpanded ? '100%' : '80px')};
  color: white;
  font-family: 'Nunito Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 19.5px */
  letter-spacing: 0.39px;
  cursor: pointer;
  background: #444444;
  border: 3px solid transparent;
  color: #ffffff;
  transition: all 0.3s ease;
  padding: ${(props) => (props.isExpanded ? '8px 12px' : '12px 20px')};
  .button-text {
    display: ${(props) => (props.isExpanded ? 'flex' : 'none')};
  }
  @media screen and (max-width: 714px) {
    border-radius: 10000px;
    height: 40px;
    width: 100%;
    padding: 8px 12px;
    .button-text {
      display: flex;
    }
  }

  &:hover {
    opacity: 0.7;
  }
`;

const ChevronContainer = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Chevron = styled('div')`
  transform: ${(props) => (props.isRotated ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Badge = styled.button`
  background: ${(props) => (props.isCore
    ? 'linear-gradient(#BBB2EF, #BBB2EF) padding-box, linear-gradient(to right, #764BA2 50%, #667EEA 100%) border-box'
    : 'linear-gradient(white, white) padding-box, linear-gradient(to left, #6A82FB 50%, #FC5C7D 100%) border-box')};

  border-radius: 50em;
  border: 1px solid transparent;
  position: absolute;
  left: 12px;
  top: -8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  color: #444;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Nunito Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 16.5px */
  padding: 2px 12px 0px 12px;
  display: ${(props) => (props.isExpanded ? 'block' : 'none')};
`;

// Styling modeled from TBTooltip
const TooltipContainer = styled('div')`
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #444444;
  color: #ffffff;
  padding: 8px;
  border-radius: 6px;
  box-shadow: 0px 0px 1px rgb(48 49 51 / 5%), 0px 4px 8px rgb(48 49 51 / 10%);
  white-space: pre-line;
  font-family: 'Nunito Sans';
  word-wrap: break-word;
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  max-width: 250px;
  width: ${(props) => (props.isSideBarExpanded ? '250px' : '200px')};
  pointer-events: none;
  transform: translate(
    -50%,
    -100%
  );

  @media screen and (max-width: 714px) {
    width: calc(100% - 32px); // subtracting padding to prevent overflow
    max-width: none;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: ${(props) => (props.isSideBarExpanded ? '50%' : '20%')}; // shift arrow to left when sidebar collapsed
    transform: translateX(-50%);
    border-width: 5px 5px 0;
    border-style: solid;
    border-color: #444444 transparent transparent;
  }
`;

// Portal required for tooltip to render properly outside of SideBar, coordinates used to position tooltip relative to associated menu tab
const Tooltip = ({
  text, x, y, isSideBarExpanded,
}) => {
  if (!text || !x || !y) return null;

  return createPortal(
    <TooltipContainer
      style={{
        left: x,
        top: y,
      }}
      isSideBarExpanded={isSideBarExpanded}
    >
      {text}
    </TooltipContainer>,
    document.body,
  );
};

const SideBar = ({ isSideBarActive, setIsSideBarActive, flags }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const subscriptionStatus = useSelector(
    (state) => state.sportsScience.data.subscriptionStatus,
  );
  const isPackageLoading = useSelector(
    (state) => state.sportsScience.ui.isPackageLoading,
  );
  const addedPackage = useSelector(
    (state) => state.sportsScience.data.addedPackage,
  );
  const isSideBarExpanded = useSelector(
    (state) => state.sportsScience.data.isSideBarExpanded,
  );
  const [activeTab, setActiveTab] = useState('');
  const [tooltipData, setTooltipData] = useState({
    text: '',
    x: null,
    y: null,
  });
  const menuRef = useRef(null);

  const isCorePackageSubscribed = subscriptionStatus?.packageSubscriptionStatus?.isSubscribed;

  /**
   * Temporarily showing modules' legacy title in the tooltip;
   * eventually textToHover can just be packageItem.description in TBTooltip props
   */
  const hoverText = (packageItem) => {
    if (isSideBarExpanded) {
      return packageItem.legacyTitle
        ? `${packageItem.description}\n(Formerly ${packageItem.legacyTitle})`
        : packageItem.description;
    }
    return packageItem.title;
  };

  const handleMenuTabMouseEnter = (packageItem) => (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setTooltipData({
      text: hoverText(packageItem),
      x: isSideBarExpanded ? rect.right - rect.width / 2 : rect.right + 20,
      y: rect.top + (rect.height / 4 - 10),
    });
  };

  const handleMenuTabMouseLeave = () => {
    setTooltipData({ text: '', x: null, y: null });
  };

  const getPackages = () => [
    {
      title: 'Load Monitoring Dashboard',
      legacyTitle: '',
      core: true,
      unlocked: false,
      sections: [
        {
          name: 'Monitoring',
          description:
            'Generate insights based on athlete workload and self-reported questionnaire data.',
          icon: 'chart-line',
        },
        {
          name: 'Readiness',
          description:
            'Determine athlete readiness by viewing data regarding sleep, fatigue, motivation, stress levels, and more.',
          icon: 'heart-rate',
        },
        {
          name: 'Recovery',
          description:
            'See how your athletes are recovering from competition and training sessions.',
          icon: 'circuit',
        },
      ],
      videoUrl: 'https://www.youtube.com/watch?v=soAvDS3obS0',
      description:
        'Compare athlete weight room load to questionnaire responses',
      icon: 'chart-line',
      activeTab: '/ams-lite/load-monitoring-dashboard',
    },
    {
      title: 'KPI Tracker',
      legacyTitle: 'Sport Coach Dashboard',
      core: true,
      unlocked: false,
      sections: [
        {
          name: 'Collaborate',
          description:
            'Share individual athlete workout data with Sport Coaches so they can see how their athletes are progressing.',
          icon: 'fist-bump-full-together',
        },
        {
          name: 'Monitor Progress',
          description:
            'Sport & Performance coaches can easily see when athletes hit a new max or personal best.',
          icon: 'clipboard',
        },
        {
          name: 'Team Trends',
          description:
            'See how each athlete is developing compared to the rest of the team.',
          icon: 'group',
        },
      ],
      videoUrl: 'https://www.youtube.com/watch?v=ZRtmL63e62c',
      description:
        'Group-based athlete performance report in an easy to share format',
      icon: 'clipboard',
      activeTab: '/ams-lite/kpi-tracker',
    },
    ...(flags.bodyHeatMaps
      ? [
        {
          title: 'Body Heat Map',
          legacyTitle: 'Recovery Radar',
          core: true,
          unlocked: false,
          // UPDATE STILL NEEDED FOR SECTIONS
          sections: [
            {
              name: 'Collaborate',
              description:
                  'Share individual athlete workout data with Sport Coaches so they can see how their athletes are progressing.',
              icon: 'fist-bump-full-together',
            },
            {
              name: 'Monitor Progress',
              description:
                  'Sport & Performance coaches can easily see when athletes hit a new max or personal best.',
              icon: 'clipboard',
            },
            {
              name: 'Team Trends',
              description:
                  'See how each athlete is developing compared to the rest of the team.',
              icon: 'group',
            },
          ],
          // CONTENT USED IN UPDATED UPGRADE MODAL
          content: [
            {
              videoUrl: 'https://www.youtube.com/watch?v=iJbmYLH90PE&t=161s',
              image: bhmOnboardingImageOne,
              title: 'Accessing Body Heat Map',
              description:
                  'Welcome to Body Heat Map, where athletes can log any pain or soreness they experience, enabling coaches to monitor needed adjustments. To get started, athletes log data on the TeamBuildr mobile app by navigating to Body Heat Map in the navigation drawer or by selecting “More” in the Workout Entry screen.',
              gradientType: 'primary',
            },
            {
              image: bhmOnboardingImageTwo,
              title: 'Reporting and Tracking Pain and Soreness',
              description:
                  'To log pain or soreness, athletes open Body Heat Map on the mobile app. On the body heat map, athletes tap a specific area to report, selecting either Pain or Soreness and choosing a severity level (1 to 7). Optional fields like Region and Status allow athletes to add context for coaches. Each entry saves automatically in the Areas Selected section, building a structured record that coaches can view and analyze.',
              gradientType: 'primary',
            },
            {
              image: bhmOnboardingImageThree,
              title: 'Overview of Recovery Data',
              description:
                  'In the Overview tab, coaches can view a high-level summary of pain and soreness data at a group and individual level. To begin, select a group from the dropdown menu in the top left corner. Use the date selector in the top right to choose a 7-day or 28-day range, showing trends for each body part. A line graph tracks changes over time, while a body map highlights frequently reported areas. Below, quick stat cards display Total Reported Items, Average Severity, and the number of Athletes to Review.',
              gradientType: 'primary',
            },
            {
              image: bhmOnboardingImageFour,
              title: 'Analyzing Athlete Recovery',
              description:
                  'To review individual reports, open the Group Analysis tab and select a group from the dropdown menu. The table shows each athlete’s reported body part, severity, 28-day average, and any notes. For a detailed view, click on an athlete’s name to open the Athlete History tab, which displays their body heat map and a list of reported issues over time.',
              gradientType: 'primary',
            },
            {
              image: bhmOnboardingImageFive,
              title: 'Athlete Alerts',
              description:
                  'Athlete Alerts help coaches quickly identify athletes with significant increases in average severity, allowing them to prioritize athletes needing immediate attention. Click "Review" in the red alert banner to filter these athletes into the Group Analysis table for closer monitoring.',
              gradientType: 'primary',
            },
          ],
          videoUrl: 'https://www.youtube.com/watch?v=iJbmYLH90PE&t=161s',
          description: 'Monitor athletes experiencing any pain or soreness',
          icon: 'target',
          activeTab: '/ams-lite/body-heat-map',
        },
      ]
      : []),
    ...(flags.habitTracking
      ? [
        {
          title: 'Habit Tracker',
          legacyTitle: 'Consistency Coach',
          core: true,
          unlocked: false,
          sections: [
            {
              name: 'Create Consistency',
              description:
                  'Create, manage, and assign custom habits for athletes to complete throughout the day or week.',
              icon: 'checkmark',
            },
            {
              name: 'Habit Monitoring',
              description:
                  'Keep an eye on daily and weekly progress as athletes check off habits and log data into their app.',
              icon: 'chart',
            },
            {
              name: 'Stakeholder Collaboration',
              description:
                  'Allow habits to be viewed by Sport Coaches, Athletic Trainers, Wellness Professionals, and other stakeholders.',
              icon: 'group',
            },
          ],
          content: [
            {
              videoUrl: 'https://www.youtube.com/watch?v=rTr7DW2eNSI',
              image: htOnboardingImageOne,
              title: 'Create & Categorize Habits',
              description:
                  'To begin habit tracking inside TeamBuildr, start out by creating habits inside the web app so they appear for your athletes. Habits need a category assigned in order to view the collected data in Group & Individual Analysis.',
              gradientType: 'primary',
            },
            {
              image: htOnboardingImageTwo,
              title: 'Completion Overview',
              description:
                  'In the Completion Overview tab, select a group from the dropdown menu in the top left corner to view the collected data, and choose a week range in the top right. It is possible to go back at a max of 12 weeks from a selected day. The date selector will automatically detect the week range you select. Collected data starts on Monday and ends on Sunday.',
              gradientType: 'primary',
            },
            {
              image: htOnboardingImageThree,
              title: 'Group Analysis',
              description:
                  'The Group Analysis tab allows you to view a table of athletes and their assigned habit data, either individually or as a full group. You can select up to 10 habits in this view by filtering them, the filter can be found to the right of the selected group or athlete. In this view, you are also able to dynamically set thresholds for each habit. Thresholds will be removed if the browser is closed or you move out of habit tracking inside the same browser window.',
              gradientType: 'primary',
            },
            {
              image: htOnboardingImageFour,
              title: 'Data Visibility',
              description:
                  'Data will not appear for habits that do not have a category assigned to it. If you delete a category, all associated habits will still be there, but they will need to be assigned a new category in order to view it in the Group and Individual analysis tabs.',
              gradientType: 'primary',
            },
          ],
          videoUrl: 'https://www.youtube.com/watch?v=rTr7DW2eNSI',
          description: 'Create, assign, and monitor habits for users.',
          icon: 'checkmark',
          activeTab: '/ams-lite/habit-tracker',
        },
      ]
      : []),
    {
      title: 'Force Plate Dashboard',
      legacyTitle: '',
      addOnId: 1,
      core: false,
      unlocked: false,
      sections: [
        {
          name: 'Integrated',
          description:
            'Using imported data from our 2 partners - Hawkin Dynamic and VALD - our Force Plate Module rethinks how to display external data.',
          icon: 'force-plate',
        },
        {
          name: 'Summarize',
          description:
            'This module takes the simple approach to viewing Force Plate data by reading fewer metrics and highlighting unique values and trends.',
          icon: 'file',
        },
        {
          name: 'Groups vs. Individual',
          description:
            'Visualize force-velocity trends and profiles for an individual or a team, or position group - with trend analysis.',
          icon: 'conversation-group',
        },
      ],
      videoUrl: 'https://www.youtube.com/watch?v=wpzZlqbBeME',
      description: 'Monitor group and athlete data collected with force plates',
      icon: 'force-plate',
      activeTab: '/ams-lite/force-plate-dashboard',
    },
  ];

  const [packages, setPackages] = useState(getPackages());
  const hasLockedPackage = packages.some(
    (packageItem) => !packageItem.unlocked,
  );

  useEffect(() => {
    setPackages(getPackages());
  }, [flags]);

  useEffect(() => {
    const { pathname } = location;
    // Avoid any unnecessary checks/redirects if the subscription status has not been fetched yet.
    if (!subscriptionStatus) {
      return;
    }

    // Defines a mapping of routes to their required subscription types.
    const allowedPackagePaths = {
      '/ams-lite/wearables-dashboard': { requiredSubscription: 'none' },
      '/ams-lite/load-monitoring-dashboard': {
        requiredSubscription: 'core',
      },
      '/ams-lite/kpi-tracker': { requiredSubscription: 'core' },
      '/ams-lite/force-plate-dashboard': { requiredSubscription: 1 }, // the addon id
    };

    if (flags.habitTracking) {
      allowedPackagePaths['/ams-lite/habit-tracker'] = {
        requiredSubscription: 'core',
      };
    }

    if (flags.bodyHeatMaps) {
      allowedPackagePaths['/ams-lite/body-heat-map'] = {
        requiredSubscription: 'core',
      };
    }

    // Retrieves the required subscription type for the current pathname.
    const requiredSubscription = allowedPackagePaths[pathname]?.requiredSubscription;
    let hasRequiredSubscription = false;

    // For wearables dashboard, no subscription is required.
    if (requiredSubscription === 'none') {
      hasRequiredSubscription = true;
    } else if (requiredSubscription === 'core') {
      // For core packages, checks if the user is subscribed to the core package.
      hasRequiredSubscription = subscriptionStatus?.packageSubscriptionStatus?.isSubscribed === true;
    } else if (typeof requiredSubscription === 'number') {
      // For add-on packages, checks if the user is subscribed to the required add-on package.
      hasRequiredSubscription = subscriptionStatus?.addOnsSubscriptionStatus.some(
        (addon) => addon.addOnId === requiredSubscription && addon.isSubscribed,
      );
    }

    if (hasRequiredSubscription || pathname === '/ams-lite') {
      setActiveTab(pathname);
    } else {
      // If the user does not have the required subscription, redirects to the sport science hub landing page.
      dispatch(push('/ams-lite'));
    }
  }, [subscriptionStatus, currentUser]); // Dependencies: subscriptionStatus and currentUser to re-run the hook when these values change.

  useEffect(() => {
    if (Object.keys(currentUser).length) {
      dispatch(fetchSubscriptionStatus(currentUser.accountCode, 1));
    }
  }, [currentUser, addedPackage]);

  // Function to compare two arrays of packages
  function arePackagesEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].unlocked !== arr2[i].unlocked) {
        return false;
      }
    }
    return true;
  }

  const updatedPackages = useMemo(() => {
    if (!subscriptionStatus || !subscriptionStatus.packageSubscriptionStatus) {
      return packages;
    }

    const result = packages.map((packageItem) => {
      let unlocked = false;
      if (packageItem.core && isCorePackageSubscribed) {
        unlocked = true;
      } else if (!packageItem.core) {
        const packageId = packageItem.addOnId;
        // Find the add-on subscription status by addOnId
        const addOnSubscription = subscriptionStatus.addOnsSubscriptionStatus.find(
          (addOn) => addOn.addOnId === packageId,
        );
        const isPackageSubscribed = addOnSubscription?.isSubscribed;
        unlocked = !!isPackageSubscribed;
      }

      return {
        ...packageItem,
        unlocked,
      };
    });

    return result;
  }, [subscriptionStatus, packages]);

  useEffect(() => {
    // Assuming arePackagesEqual is a deep comparison function
    if (!arePackagesEqual(updatedPackages, packages)) {
      setPackages(updatedPackages);
    }
  }, [updatedPackages]);

  // Tracks which module was active when user clicked upgrade
  const handleAmplitudeUpgrade = () => {
    let title;
    switch (activeTab) {
      case '/ams-lite/wearables-dashboard':
        title = 'Wearables Dashboard';
        break;
      case '/ams-lite/load-monitoring-dashboard':
        title = 'Load Monitoring Dashboard';
        break;
      case '/ams-lite/kpi-tracker':
        title = 'KPI Tracker';
        break;
      case '/ams-lite/force-plate-dashboard':
        title = 'Force Plate Dashboard';
        break;
      case '/ams-lite/habit-tracker':
        title = 'Habit Tracker';
        break;
      case '/ams-lite/body-heat-map':
        title = 'Body Heat Map';
        break;
      default:
        title = 'Sport Science Hub Landing Page';
    }

    subscriptionFlowTracker('Upgrade Sidebar Started', 'none', title);
  };

  // Tracks which module was active when user booked a demo
  const handleAmplitudeDemo = () => {
    let title;
    let core;
    let addOn;
    switch (activeTab) {
      case '/ams-lite/wearables-dashboard':
        addOn = false;
        core = false;
        title = 'Wearables Dashboard';
        break;
      case '/ams-lite/load-monitoring-dashboard':
        addOn = false;
        core = true;
        title = 'Load Monitoring Dashboard';
        break;
      case '/ams-lite/kpi-tracker':
        addOn = false;
        core = true;
        title = 'KPI Tracker';
        break;
      case '/ams-lite/habit-tracker':
        addOn = false;
        core = true;
        title = 'Habit Tracker';
        break;
      case '/ams-lite/force-plate-dashboard':
        addOn = true;
        core = false;
        title = 'Force Plate Dashboard';
        break;
      case '/ams-lite/body-heat-map':
        addOn = false;
        core = true;
        title = 'Body Heat Map';
        break;
      default:
        addOn = false;
        core = false;
        title = 'Sport Science Hub Landing Page';
    }

    const packageData = { title, core, addOn };

    subscriptionFlowTrackerDemo('Sidebar', packageData);
  };

  // Tracks which module/dashboard was opened
  const handleAmplitudeModule = (title) => {
    dashboardTracker(title, null, null);
  };

  // Tracks which module's modal was opened
  const handleAmplitudeModal = (title) => {
    subscriptionFlowTracker('Modal Viewed', title, 'none');
  };

  return (
    <SideBarDiv
      isSideBarActive={isSideBarActive}
      isExpanded={isSideBarExpanded}
    >
      <SpacingWrapper>
        <SideBarBannerContainer>
          <TeamBuildrLogo isExpanded={isSideBarExpanded} />
        </SideBarBannerContainer>
        <SideBarHeader
          isExpanded={isSideBarExpanded}
          isSideBarActive={isSideBarActive}
        >
          <ChevronContainer
            onClick={() => {
              dispatch(setIsSideBarExpandedGlobal(!isSideBarExpanded));
            }}
          >
            <Chevron isRotated={isSideBarExpanded}>
              <IcomoonReact
                iconSet={iconSet}
                size={18}
                icon='altrightarrow'
                color='black'
              />
            </Chevron>
          </ChevronContainer>
        </SideBarHeader>
        <MenuAndButtons
          ref={menuRef}
          isExpanded={isSideBarExpanded}
          hasLocked={packages.some((thing) => thing.unlocked === false)}
        >
          <MenuTab
            isExpanded={isSideBarExpanded}
            activeTab={activeTab === '/ams-lite'}
            unlocked
            hasLockedPackage={hasLockedPackage}
            onClick={() => {
              handleAmplitudeModule('Sport Science Hub Landing Page');
              dispatch(push('/ams-lite'));
              setIsSideBarActive(false);
            }}
          >
            <MenuTabContent>
              <IconContainer
                activeTab={activeTab === '/ams-lite'}
                isSideBarExpanded={isSideBarExpanded}
              >
                <SSHomeIcon />
              </IconContainer>
              <TitleAndDescription>
                <TabTitle
                  isExpanded={isSideBarExpanded}
                  unlocked
                  activeTab={activeTab === '/ams-lite'}
                >
                  View All Apps
                </TabTitle>
              </TitleAndDescription>
            </MenuTabContent>
          </MenuTab>
          {subscriptionStatus && !isPackageLoading ? (
            <MenuTab
              isExpanded={isSideBarExpanded}
              activeTab={activeTab === '/ams-lite/wearables-dashboard'}
              unlocked
              hasLockedPackage={hasLockedPackage}
              onMouseEnter={handleMenuTabMouseEnter({
                title: 'Wearables Dashboard',
                legacyTitle: '',
                description: 'Quickly review biometric data collected by athlete wearables',
              })}
              onMouseLeave={handleMenuTabMouseLeave}
              onClick={() => {
                handleAmplitudeModule('Wearables Dashboard');
                dispatch(push('/ams-lite/wearables-dashboard'));
                setIsSideBarActive(false);
              }}
            >
              <MenuTabContent>
                <IconContainer
                  activeTab={activeTab === '/ams-lite/wearables-dashboard'}
                  isSideBarExpanded={isSideBarExpanded}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={40}
                    icon='watch-heart-rate'
                    color='#444444'
                  />
                </IconContainer>
                <TitleAndDescription>
                  <TabTitle
                    isExpanded={isSideBarExpanded}
                    unlocked
                    activeTab={activeTab === '/ams-lite/wearables-dashboard'}
                  >
                    Wearables Dashboard
                  </TabTitle>
                </TitleAndDescription>
              </MenuTabContent>
            </MenuTab>
          ) : (
            <Shimmer>
              <ShimmerMenuTab />
            </Shimmer>
          )}
          {subscriptionStatus && !isPackageLoading ? (
            packages.map((packageItem) => (
              <MenuTab
                key={packageItem.id}
                isExpanded={isSideBarExpanded}
                activeTab={activeTab === packageItem.activeTab}
                unlocked={packageItem.unlocked}
                hasLockedPackage={hasLockedPackage}
                isAddon={packageItem.core === false}
                onMouseEnter={handleMenuTabMouseEnter(packageItem)}
                onMouseLeave={handleMenuTabMouseLeave}
                onClick={() => {
                  if (!packageItem.unlocked) {
                    handleAmplitudeModal(packageItem.title);
                    if (
                      packageItem.title === 'Body Heat Map'
                      || packageItem.title === 'Habit Tracker'
                    ) {
                      dispatch(setIsUpdatedUpgradeModalShowing(true));
                    } else {
                      dispatch(setIsUpgradeModalShowing(true));
                    }

                    dispatch(setSelectedPackage(packageItem));
                    dispatch(
                      fetchSubscriptionPackage(
                        currentUser.accountCode,
                        1,
                        !packageItem.core ? packageItem.addOnId : null,
                      ),
                    );
                  } else {
                    handleAmplitudeModule(packageItem.title);
                    dispatch(push(packageItem.activeTab));
                    setIsSideBarActive(false);
                  }
                }}
              >
                <MenuTabContent>
                  <IconContainer
                    activeTab={activeTab === packageItem.activeTab}
                    isSideBarExpanded={isSideBarExpanded}
                  >
                    <IcomoonReact
                      iconSet={iconSet}
                      size={40}
                      icon={packageItem.icon}
                      color={
                        activeTab === packageItem.activeTab
                        || packageItem.unlocked
                          ? '#444444'
                          : 'white'
                      }
                    />
                  </IconContainer>
                  <TitleAndDescription>
                    <TabTitle
                      isExpanded={isSideBarExpanded}
                      unlocked={packageItem.unlocked}
                      activeTab={activeTab === packageItem.activeTab}
                    >
                      {packageItem.title}
                    </TabTitle>
                  </TitleAndDescription>
                </MenuTabContent>
                <LockContainer
                  isActive={!packageItem.unlocked}
                  isExpanded={isSideBarExpanded}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={isSideBarExpanded ? 25 : 12}
                    icon={packageItem.core ? 'lock' : 'star'}
                    color='white'
                  />
                </LockContainer>
                {!packageItem?.unlocked
                  && (packageItem.core ? (
                    <Badge isCore isExpanded={isSideBarExpanded}>
                      CORE SUBSCRIPTION
                    </Badge>
                  ) : (
                    <Badge isExpanded={isSideBarExpanded}>ADD ON</Badge>
                  ))}
              </MenuTab>
            ))
          ) : (
            <Shimmer>
              <ShimmerMenuTab />
            </Shimmer>
          )}
        </MenuAndButtons>
        <ModalButtons isSideBarExpanded={isSideBarExpanded}>
          <BookModalButton
            isExpanded={isSideBarExpanded}
            onClick={() => {
              handleAmplitudeDemo();
              openPopupWidget({
                url:
                  'https://calendly.com/teambuildr/15-minute-sports-science-hub',
              });
            }}
          >
            <DemoButtonIconContainer isSideBarExpanded={isSideBarExpanded}>
              <IcomoonReact
                iconSet={iconSet}
                size={isSideBarExpanded ? 16 : 24}
                icon='calendar'
                color='#ffffff'
              />
            </DemoButtonIconContainer>
            <div className='button-text'>BOOK A DEMO</div>
          </BookModalButton>
          {isCorePackageSubscribed ? (
            <UpgradeButton
              isExpanded={isSideBarExpanded}
              isActive={isCorePackageSubscribed}
              onClick={() => {
                if (activeTab !== '/ams-lite') {
                  window.open(
                    'https://www.teambuildr.com/sport-science',
                    '_blank',
                  );
                } else {
                  batch(() => {
                    dispatch(setSelectedPackage(corePackage));
                    dispatch(setIsLearnMoreModalShowing(true));
                  });
                }
              }}
            >
              <UpgradeButtonIconContainer isSideBarExpanded={isSideBarExpanded}>
                <IcomoonReact
                  iconSet={iconSet}
                  size={isSideBarExpanded ? 16 : 24}
                  icon='question-mark'
                  color={isSideBarExpanded ? '#ffffff' : '#444444'}
                />
              </UpgradeButtonIconContainer>
              <div className='button-text'>LEARN MORE</div>
            </UpgradeButton>
          ) : (
            <UpgradeButton
              isExpanded={isSideBarExpanded}
              isActive={!isCorePackageSubscribed}
              onClick={() => {
                if (currentUser?.userSettings?.billing === 0) {
                  // billing access is disabled
                  window.open(
                    `${process.env.PHP_APP_URL
                      || 'https://app.teambuildr.com'}/billing`,
                  );
                } else if (!subscriptionStatus?.isSubscribed) {
                  handleAmplitudeUpgrade();
                  dispatch(setIsUpgradeModalShowing(true));
                  dispatch(setSelectedPackage(corePackage));
                  dispatch(
                    fetchSubscriptionPackage(currentUser.accountCode, 1),
                  );
                } else {
                  dispatch(push('/ams-lite'));
                  setIsSideBarActive(false);
                }
              }}
            >
              <UpgradeButtonIconContainer isSideBarExpanded={isSideBarExpanded}>
                <IcomoonReact
                  iconSet={iconSet}
                  size={isSideBarExpanded ? 16 : 24}
                  icon='star'
                  color={isSideBarExpanded ? '#ffffff' : '#444444'}
                />
              </UpgradeButtonIconContainer>
              <div className='button-text'>UPGRADE</div>
            </UpgradeButton>
          )}
        </ModalButtons>
      </SpacingWrapper>
      <Tooltip
        text={tooltipData.text}
        x={tooltipData.x}
        y={tooltipData.y}
        isSideBarExpanded={isSideBarExpanded}
      />
    </SideBarDiv>
  );
};

SideBar.propTypes = {
  isSideBarActive: PropTypes.bool.isRequired,
  setIsSideBarActive: PropTypes.func.isRequired,
};

export default withLDConsumer()(SideBar);
