/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import TableToExcel from '@linways/table-to-excel';

import { BoxShadow } from '../../../../shared/GlobalStyles';
import Button from '../../../../shared/components/Button/Button';
import GroupFilter from './GroupFilter';
import ForcePlateDatePicker from './ForcePlateDatePicker';
import {
  setSelectedAthleteGroup,
  setShowFlaggedAthletesModalGroup,
} from '../../ducks/forcePlateDashboardActions';
import GroupAnalysisTable from './GroupAnalysisTable';
import ForcePlateDropDown from './ForcePlateDropDown';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const DashboardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 15px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 16px;
  box-shadow: ${BoxShadow};
  height: 100%;
  position: relative;
`;

const OptionsHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const MenuOptionsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

const DateOptionsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  .downloadBtn {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    padding: 3px;
    margin-left: 10px;
  }
`;

const DaySelector = styled('div')`
  display: flex;
  padding: 8px;
  border: solid 1px #979797;
  border-radius: 4px;
  margin-right: 10px;
`;

const DayOption = styled('button')`
  border: none;
  font-family: 'Nunito Sans';
  font-size: 16px;
  color: ${(props) => (!props.isActive ? '#9E9E9E' : null)};
  cursor: ${(props) => (props.readOnly ? 'default !important' : 'pointer')};
`;

const DaySpacer = styled('span')`
  color: #9e9e9e;
  font-family: 'Nunito Sans';
  font-size: 16px;
  margin: 0px 5px;
`;

const ColorNote = styled('div')`
  color: #444444;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: normal;
`;

const ViewOnlyGroupAnalysis = () => {
  const dayRange = useSelector(
    (state) => state.forcePlateDashboard.data.dayRange,
  );
  const endDateCal = useSelector(
    (state) => state.forcePlateDashboard.data.endDateCal,
  );
  const groupAnalysisAthleteData = useSelector(
    (state) => state.forcePlateDashboard.data.groupAnalysisAthleteData,
  );
  const groupAnalysisAverageData = useSelector(
    (state) => state.forcePlateDashboard.data.groupAnalysisAverageData,
  );
  const flaggedAthletesData = useSelector(
    (state) => state.forcePlateDashboard.data.flaggedAthletesData,
  );
  const flaggedAthletesDataGroup = useSelector(
    (state) => state.forcePlateDashboard.data.flaggedAthletesDataGroup,
  );
  const group = useSelector((state) => state.forcePlateDashboard.data.group);
  const isFlaggedAthletesView = useSelector(
    (state) => state.forcePlateDashboard.ui.isFlaggedAthletesView,
  );
  const isGroupAnalysisLoading = useSelector(
    (state) => state.forcePlateDashboard.ui.isGroupAnalysisLoading,
  );
  const selectedAthleteGroup = useSelector(
    (state) => state.forcePlateDashboard.data.selectedAthleteGroup,
  );
  const startDateCal = useSelector(
    (state) => state.forcePlateDashboard.data.startDateCal,
  );
  const isReadOnlyView = useSelector(
    (state) => state.forcePlateDashboard.ui.isReadOnlyView,
  );

  const hasData = groupAnalysisAthleteData?.length > 0;

  const athletes = hasData
    && groupAnalysisAthleteData.map((obj) => ({
      fullName: obj.fullName,
      userId: obj.userId,
    }));
  athletes && athletes.unshift({ fullName: 'View All', userId: 0 });

  const selectedAthleteData = selectedAthleteGroup
    && hasData
    && groupAnalysisAthleteData.find(
      (obj) => obj.fullName === selectedAthleteGroup.fullName,
    );

  const dispatch = useDispatch();

  const setAthlete = (option) => {
    if (option.fullName === 'View All') {
      // KPI tracking
      dashboardTracker(
        'Force Plate Dashboard',
        'View Only Group Analysis - Athlete Picker',
        'Athlete selection reset',
      );
      dispatch(setSelectedAthleteGroup({ fullName: 'Viewing All' }, false));
    } else {
      // KPI tracking
      dashboardTracker(
        'Force Plate Dashboard',
        'View Only Group Analysis - Athlete Picker',
        'Athlete selected',
      );
      dispatch(setSelectedAthleteGroup(option, true));
    }
  };

  const handleDownload = () => {
    // KPI tracking
    dashboardTracker(
      'Force Plate Dashboard',
      'View Only Group Analysis - Download',
      'Table downloaded',
    );
    const tb = document.getElementById('reportTable');
    TableToExcel.convert(tb, {
      name: 'Group_Analysis.xlsx',
      sheet: {
        name: 'Sheet 1',
      },
    });
  };

  useEffect(() => {
    if (flaggedAthletesDataGroup?.length > 0 && !isFlaggedAthletesView) {
      dispatch(setShowFlaggedAthletesModalGroup(true));
    } else {
      dispatch(setShowFlaggedAthletesModalGroup(false));
    }
  }, [flaggedAthletesDataGroup]);

  const emptyFunction = () => undefined;

  const [columnHeaderHeight, setColumnHeaderHeight] = useState(null);
  const containerWidth = 1000;

  return (
    <>
      <DashboardContainer>
        <OptionsHeader>
          <MenuOptionsContainer>
            <ForcePlateDropDown
              disabled
              list={['']}
              label='name'
              selectItemFunction={emptyFunction}
              headerWidthPx={175}
              placeholder='Select a Group'
              defaultOption={group || {}}
              readOnly
            />
            {athletes && (
              <ForcePlateDropDown
                list={athletes}
                label='fullName'
                selectItemFunction={setAthlete}
                headerWidthPx={175}
                placeholder='Select an Athlete'
                defaultOption={selectedAthleteGroup || {}}
              />
            )}
            <GroupFilter />
          </MenuOptionsContainer>
          <DateOptionsContainer>
            <ForcePlateDatePicker
              disabled
              setStartDate={emptyFunction}
              setEndDate={emptyFunction}
              startDate={startDateCal.toDate()}
              endDate={endDateCal.toDate()}
            />
            <Button
              className='downloadBtn'
              disabled={!hasData}
              iconOnly
              icon='download'
              onClick={handleDownload}
            />
          </DateOptionsContainer>
        </OptionsHeader>
        <ColorNote>
          <span style={{ fontWeight: 'bold' }}>NOTE: </span>
          The column background color is determined by the amount of deviation
          from the average. The deviation column will be red if the deviation is
          less than 1 and green if greater than 1.
        </ColorNote>
        {isGroupAnalysisLoading ? (
          <Spinner />
        ) : (
          <>
            {/* TO-DO: Will it ever be flagged athletes view in view-only? */}
            {isFlaggedAthletesView ? (
              <GroupAnalysisTable
                setColumnHeaderHeight={setColumnHeaderHeight}
                containerWidth={containerWidth}
                dataRows={flaggedAthletesData}
                groupAverageData={groupAnalysisAverageData}
                rowIsScrollable
              />
            ) : selectedAthleteData ? (
              <GroupAnalysisTable
                setColumnHeaderHeight={setColumnHeaderHeight}
                containerWidth={containerWidth}
                dataRows={[selectedAthleteData]}
                groupAverageData={groupAnalysisAverageData}
                rowIsScrollable
              />
            ) : (
              <GroupAnalysisTable
                setColumnHeaderHeight={setColumnHeaderHeight}
                containerWidth={containerWidth}
                dataRows={groupAnalysisAthleteData}
                groupAverageData={groupAnalysisAverageData}
                rowIsScrollable
              />
            )}
          </>
        )}
      </DashboardContainer>
    </>
  );
};

export default ViewOnlyGroupAnalysis;
