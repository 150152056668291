/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-plusplus */
import React, { useState, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useSortBy, useTable, usePagination } from 'react-table';
import { useSticky } from 'react-table-sticky';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';

import iconSet from '../../../shared/images/teambuildr-selection.json';
import {
  fetchUserHistoryData,
  setRecoveryRadarActiveModal,
  setRecoveryRadarView,
  setSelectedAthlete,
} from '../ducks/RecoveryRadarActions';
import { dashboardTracker } from '../../../shared/utils/amplitudeHelper';

const Styles = styled.div`
  background: #ffffff;
  font-family: nunito sans;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  align-items: flex-start;

  .noHoverCategoryColumnTitleCell {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  .footerCells {
    position: sticky;
    bottom: 0;
    z-index: 3;
  }

  .paginationContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  .paginationButton {
    font-family: 'Nunito Sans';
    font-weight: bold;
    font-size: 16px;
    padding: 5px;
    display: flex;
    justify-content: center;
    border: none;
  }

  & .sticky {
    overflow-x: auto;
    margin: 25px 0px;
    width: 100%;

    tfoot {
      td:first-child {
        position: sticky;
        left: 0;
        z-index: 4;
        border-bottom-left-radius: 8px;
      }
    }

    [data-sticky-td] {
      position: sticky;
    }
  }

  .athleteColumnHeaderCell {
    border-bottom: none;
    border-right: none;
    display: none;
  }

  .noHoverCategoryColumnTitleCell {
    background-color: #f0f0f0;
    border-right: 1px solid #bdbdbd1a;
    color: #444444;
    font-size: 16px;
    font-weight: 700;
  }

  .rawValueTitle {
    background-color: #f0f0f0;
    color: #444444;
    cursor: pointer;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: 700;
    left: 0;
    position: sticky;
    text-align: left;
    text-wrap: nowrap;
    top: 0;
  }

  .athleteNameTitle {
    background-color: #f0f0f0;
    border-top-left-radius: 8px;
    color: #444444;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: bold;
    left: 0;
    position: sticky;
    text-align: left;
    text-wrap: nowrap;
    top: 0;
    z-index: 4;
  }

  .athleteNameTitleHistory {
    border-top-left-radius: 8px;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    text-wrap: nowrap;
    color: #444444;
    background-color: #f0f0f0;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 4;
  }

  .footerCells {
    font-size: 16px;
    font-family: 'Nunito Sans';
    font-weight: normal;
    color: #444444;
  }

  .clickableText {
    cursor: pointer;
    text-decoration: underline;
    text-wrap: nowrap;
  }

  td:has(div):has(.percentRed) {
    background-color: #ffcdcd;
  }

  td:has(div):has(.percentGreen) {
    background-color: #cff5e8;
  }

  th:last-child {
    border-right: none;
    border-top-right-radius: 8px;
  }

  td.footerCells:last-child {
    border-bottom-right-radius: 8px;
  }

  table {
    border-spacing: 0;
    border-collapse: separate;
    cursor: default;
    table-layout: auto;
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      background: white;
      padding: 15px;
      border-bottom: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      text-align: center;
    }

    tfoot {
      tr:first-child {
        td {
          border-top: 1px solid #e0e0e0;
          height: 61px;
          min-height: 61px;
          border-bottom: none;
          background: #f0f0f0;
        }
      }
      td:first-child {
        color: #444444;
        font-size: 16px;
        font-weight: normal;
        text-align: left;
        font-family: 'Nunito Sans';
      }
      tr:last-child {
        display: none;
      }
    }
  }
`;

const Table = ({
  columns, data, getCellProps, getHeaderProps,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 50 },
    },
    useSticky,
    useSortBy,
    usePagination,
  );

  const renderPageButtons = () => {
    const buttons = [];
    for (let i = 0; i < pageCount; i++) {
      buttons.push(
        <button
          className='paginationButton'
          type='button'
          key={i}
          onClick={() => gotoPage(i)}
          style={{ color: pageIndex === i ? '#FF6600' : '#616161' }}
        >
          {i + 1}
        </button>,
      );
    }
    return buttons;
  };

  return (
    <>
      <div
        id='reportTable'
        style={{ display: 'flex', width: '100%', zIndex: 0 }}
        className='sticky'
      >
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} data-a-wrap>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className: column.className,
                      },
                      getHeaderProps(column),
                    ])}
                    data-cols-width='30'
                    data-a-wrap
                    data-f-bold
                    data-fill-color={column?.style?.fillColor || 'FF424242'}
                    data-b-a-s='thin'
                    data-b-a-c='FF000000'
                    data-f-color={
                      column?.style?.fillColor ? 'FF000000;' : 'FFFFFFFF'
                    }
                    data-a-h='center'
                    data-a-v='center'
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <IcomoonReact
                            iconSet={iconSet}
                            size={10}
                            color='black'
                            icon='down-arrow'
                            style={{ marginLeft: '5px' }}
                          />
                        ) : (
                          <IcomoonReact
                            iconSet={iconSet}
                            size={10}
                            color='black'
                            icon='up-arrow'
                            style={{ marginLeft: '5px' }}
                          />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps([
                        {
                          style: cell.column.style,
                        },
                        getCellProps(cell),
                      ])}
                      data-fill-color={
                        getCellProps(cell)?.style?.fillColor || null
                      }
                      data-f-color={getCellProps(cell)?.style?.fontColor}
                      data-b-a-s='thin'
                      data-b-a-c='FF000000'
                      data-a-wrap
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td
                    data-a-wrap
                    {...column.getFooterProps([{ className: 'footerCells' }])}
                  >
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
      {pageCount > 1 ? (
        <div className='paginationContainer'>
          <div className='pagination'>
            <button
              className='paginationButton'
              type='button'
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {'<'}
            </button>
            {' '}
            {renderPageButtons()}
            {' '}
            <button
              className='paginationButton'
              type='button'
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {'>'}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

const RRAnalysisTable = ({
  averageData,
  containerWidth,
  dataRows,
  isHistory,
  isHistoryTable,
  rowIsScrollable,
  setColumnHeaderHeight,
}) => {
  const accountCode = useSelector(
    (state) => state.auth.data.currentUser.accountCode,
  );
  const activeFilters = useSelector(
    (state) => state.recoveryRadar.data.activeFilters,
  );
  const activeTypeFilters = useSelector(
    (state) => state.recoveryRadar.data.activeTypeFilters,
  );
  const selectedDateData = useSelector(
    (state) => state.recoveryRadar.data.selectedDateData,
  );

  const filterIds = activeFilters.map((obj) => obj.id);
  const typeIds = activeTypeFilters.map((obj) => obj.id);

  const chartRef = useRef(null);
  const [headerAndSubHeaderHeight, setHeaderAndSubHeaderHeight] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);

  const dispatch = useDispatch();

  const handleScroll = (e) => {
    /** targetting the sticky container div scroll properties */
    const scrollWidth = chartRef.current?.childNodes[0]?.scrollWidth;
    const offsetWidth = chartRef.current?.childNodes[0]?.offsetWidth;
    const scrollLeft = chartRef.current?.childNodes[0]?.scrollLeft;
    setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
  };

  const handleAthleteClick = (userId, name) => {
    batch(() => {
      dispatch(setSelectedAthlete({ id: userId, name }));
      dispatch(setRecoveryRadarView('history'));
      dispatch(
        fetchUserHistoryData(
          accountCode,
          userId,
          moment(selectedDateData)
            .subtract(27, 'd')
            .format('YYYY-MM-DD'),
          selectedDateData,
          typeIds,
          filterIds,
        ),
      );
    });
  };

  const handleNoteClick = (note) => {
    dispatch(setRecoveryRadarActiveModal('Additional-notes', note));
  };

  React.useEffect(() => {
    if (chartRef.current) {
      const scrollWidth = chartRef.current.childNodes[0]?.scrollWidth;
      const offsetWidth = chartRef.current.childNodes[0]?.offsetWidth;
      const scrollLeft = chartRef.current.childNodes[0]?.scrollLeft;
      setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
    }
  }, []);

  React.useEffect(() => {
    if (chartRef.current) {
      setHeaderAndSubHeaderHeight(
        chartRef.current.childNodes[0].childNodes[0].childNodes[0].clientHeight,
      );
      setColumnHeaderHeight(
        chartRef.current.childNodes[0].childNodes[0].childNodes[0].childNodes[0]
          .clientHeight,
      );
    }
  }, []);

  const athleteNameColumn = [
    {
      Header: '',
      Footer: () => null,
      id: 'name',
      className: 'athleteColumnHeaderCell',
      columns: [
        {
          Header: isHistory ? 'Date' : 'Name',
          accessor: isHistory ? 'date' : 'name',
          Footer: isHistory ? 'Average' : 'Group Average',
          className: isHistory ? 'athleteNameTitleHistory' : 'athleteNameTitle',
          style: {
            /** style for the whole column
             * className is style for just the header
             */
            borderRight: '1px solid #E0E0E0',
            color: '#444444',
            fontFamily: 'Nunito Sans',
            fontSize: '16px',
            fontWeight: 'normal',
            height: '58px',
            left: '0',
            position: 'sticky',
            textAlign: 'left',
            textWrap: 'nowrap',
          },
          Cell: ({ row }) => {
            if (isHistory) {
              const date = moment(row.original.date).format('MMM DD YYYY');
              return <div>{date ?? '-'}</div>;
            }
            return (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div
                  style={{
                    borderRadius: '50%',
                    height: '35px',
                    marginRight: '5px',
                    overflow: 'hidden',
                    width: '35px',
                  }}
                >
                  <img
                    alt={`${row.original.name} icon`}
                    src={row.original.pic}
                    style={{ height: '100%' }}
                  />
                </div>
                <text
                  className='clickableText'
                  onClick={() => {
                    handleAthleteClick(row.original.userId, row.original.name);
                    // Amplitude tracking
                    dashboardTracker(
                      'Recovery Radar',
                      'Group Analyis - Table',
                      'Athlete name clicked. User taken to history screen with the athlete selected in dropdown',
                    );
                  }}
                >
                  {row.original.name}
                </text>
              </div>
            );
          },
        },
      ],
    },
  ];

  const DataColumns = [
    {
      Header: '',
      Footer: () => null,
      id: 'empty',
      className: 'athleteColumnHeaderCell',
      columns: [
        {
          Header: 'Area Selected',
          Footer: '-',
          accessor: (row) => row.data.bodyPartName,
          Cell: ({ row }) => <div>{row.original.data.bodyPartName ?? '-'}</div>,
          id: 'areaSelected',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textAlign: 'left',
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Severity',
          accessor: (row) => row.data.severity,
          Cell: ({ row }) => <div>{row.original.data.severity ?? '-'}</div>,
          Footer: () => averageData.dailyAverageSeverity ?? '-',
          id: 'severity',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
        {
          Header: '28 Day Avg.',
          accessor: (row) => row.data.previousAverageSeverity,
          Cell: ({ row }) => (
            <div>{row.original.data.previousAverageSeverity ?? '-'}</div>
          ),
          Footer: () => averageData.previousAverageSeverity ?? '-',
          id: '28Avg',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Change',
          accessor: (row) => row.data.severityPercentChange,
          Cell: ({ row }) => {
            if (row.original.data.isSeverityPercentChangePositive) {
              return (
                <div className='percentRed'>
                  {`${row.original.data.severityPercentChange}%`}
                </div>
              );
            }
            return (
              <div>
                {row.original.data.severityPercentChange !== null
                && row.original.data.severityPercentChange !== undefined
                  ? `${row.original.data.severityPercentChange}%`
                  : '-'}
              </div>
            );
          },
          Footer: () => (averageData.severityPercentChange !== null
            && averageData.severityPercentChange !== undefined
            ? `${averageData.severityPercentChange}%`
            : '-'),
          id: 'change',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Region',
          accessor: (row) => row.data.region,
          Cell: ({ row }) => <div>{row.original.data.region ?? '-'}</div>,
          Footer: '-',
          id: 'region',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Status',
          accessor: (row) => row.data.status,
          Cell: ({ row }) => <div>{row.original.data.status ?? '-'}</div>,
          Footer: '-',
          id: 'status',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textAlign: 'left',
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Notes',
          accessor: (row) => row.data.notes,
          Cell: ({ row }) => {
            if (row.original.data.notes) {
              return (
                <text
                  className='clickableText'
                  onClick={() => {
                    handleNoteClick(row.original.data.notes);
                    // Amplitude tracking
                    dashboardTracker(
                      'Recovery Radar',
                      'Group Analysis - Table',
                      'Additional notes clicked',
                    );
                  }}
                  style={{ color: '#77A7FF' }}
                >
                  View
                </text>
              );
            }
            return <div>-</div>;
          },
          Footer: '-',
          id: 'notes',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
      ],
    },
  ];

  const DataColumnsArea = [
    {
      Header: '',
      Footer: () => null,
      id: 'empty',
      className: 'athleteColumnHeaderCell',
      columns: [
        {
          Header: 'Area Selected',
          Footer: '-',
          accessor: (row) => row.bodyPartName,
          Cell: ({ row }) => <div>{row.original.bodyPartName ?? '-'}</div>,
          id: 'areaSelected',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textAlign: 'left',
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Severity',
          accessor: (row) => row.severity,
          Cell: ({ row }) => <div>{row.original.severity ?? '-'}</div>,
          Footer: () => averageData.dailyAverageSeverity ?? '-',
          id: 'severity',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
        {
          Header: '28 Day Avg.',
          accessor: (row) => row.previousAverageSeverity,
          Cell: ({ row }) => (
            <div>{row.original.previousAverageSeverity ?? '-'}</div>
          ),
          Footer: () => averageData.previousAverageSeverity ?? '-',
          id: '28Avg',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Change',
          accessor: (row) => row.severityPercentChange,
          Cell: ({ row }) => {
            if (row.original.isPreviousSeverityPercentChangePositive) {
              return (
                <div className='percentRed'>
                  {`${row.original.severityPercentChange}%`}
                </div>
              );
            }
            return (
              <div>
                {row.original.severityPercentChange !== null
                && row.original.severityPercentChange !== undefined
                  ? `${row.original.severityPercentChange}%`
                  : '-'}
              </div>
            );
          },
          Footer: () => (averageData.severityPercentChange !== null
            && averageData.severityPercentChange !== undefined
            ? `${averageData.severityPercentChange}%`
            : '-'),
          id: 'change',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Region',
          accessor: (row) => row.region,
          Cell: ({ row }) => <div>{row.original.region ?? '-'}</div>,
          Footer: '-',
          id: 'region',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Status',
          accessor: (row) => row.status,
          Cell: ({ row }) => <div>{row.original.status ?? '-'}</div>,
          Footer: '-',
          id: 'status',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textAlign: 'left',
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Notes',
          accessor: (row) => row.notes,
          Cell: ({ row }) => {
            if (row.original.notes) {
              return (
                <text
                  className='clickableText'
                  onClick={() => {
                    handleNoteClick(row.original.notes);
                    // Amplitude tracking
                    dashboardTracker(
                      'Recovery Radar',
                      'Athlete History - Area Selected Table',
                      'Additional notes clicked',
                    );
                  }}
                  style={{ color: '#77A7FF' }}
                >
                  View
                </text>
              );
            }
            return <div>-</div>;
          },
          Footer: '-',
          id: 'notes',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
      ],
    },
  ];

  const DataColumnsHistory = [
    {
      Header: '',
      Footer: () => null,
      id: 'empty',
      className: 'athleteColumnHeaderCell',
      columns: [
        {
          Header: 'Area Selected',
          Footer: '-',
          accessor: (row) => row.bodyPartName,
          Cell: ({ row }) => <div>{row.original.bodyPartName ?? '-'}</div>,
          id: 'areaSelected',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textAlign: 'left',
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Severity',
          accessor: (row) => row.severity,
          Cell: ({ row }) => <div>{row.original.severity ?? '-'}</div>,
          Footer: () => averageData.averageSeverity || '-',
          id: 'severity',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Region',
          accessor: (row) => row.region,
          Cell: ({ row }) => <div>{row.original.region ?? '-'}</div>,
          Footer: '-',
          id: 'region',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Status',
          accessor: (row) => row.status,
          Cell: ({ row }) => <div>{row.original.status ?? '-'}</div>,
          Footer: '-',
          id: 'status',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textAlign: 'left',
            textWrap: 'nowrap',
          },
        },
        {
          Header: 'Notes',
          accessor: (row) => row.notes,
          Cell: ({ row }) => {
            if (row.original.notes) {
              return (
                <text
                  className='clickableText'
                  onClick={() => {
                    handleNoteClick(row.original.notes);
                    // Amplitude tracking
                    dashboardTracker(
                      'Recovery Radar',
                      'Athlete History - History Table',
                      'Additional notes clicked',
                    );
                  }}
                  style={{ color: '#77A7FF' }}
                >
                  View
                </text>
              );
            }
            return <div>-</div>;
          },
          Footer: '-',
          id: 'notes',
          className: 'rawValueTitle',
          style: {
            fontWeight: 400,
            fillColor: 'FFBDBDBD1A',
            flexGrow: 1,
            textWrap: 'nowrap',
          },
        },
      ],
    },
  ];

  const columns = isHistory && isHistoryTable
    ? [...athleteNameColumn, ...DataColumnsHistory]
    : isHistory
      ? [...athleteNameColumn, ...DataColumnsArea]
      : [...athleteNameColumn, ...DataColumns];

  const memoizedColumns = React.useMemo(() => columns, [
    dataRows,
    containerWidth,
  ]);
  const memoizedDataRows = React.useMemo(() => dataRows, [
    dataRows,
    containerWidth,
  ]);

  const handleHeaderClick = (headerInfo) => {
    if (headerInfo.Header !== '') {
      headerInfo.toggleSortBy();
    }
  };

  return (
    <Styles
      ref={chartRef}
      onScroll={handleScroll}
      isScrollable={isScrollable}
      rowIsScrollable={rowIsScrollable}
      headerAndSubHeaderHeight={headerAndSubHeaderHeight}
      containerWidth={containerWidth}
    >
      <Table
        containerWidth={containerWidth}
        columns={memoizedColumns}
        data={memoizedDataRows}
        getCellProps={() => ({
          style: {
            backgroundColor: 'FFFFFFFF',
            color: 'FF444444',
            fillColor: 'FFFFFFFF',
            fontColor: 'FF444444',
          },
        })}
        getHeaderProps={(headerInfo) => ({
          onClick: () => handleHeaderClick(headerInfo),
        })}
      />
    </Styles>
  );
};

export default RRAnalysisTable;
