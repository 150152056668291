import React from 'react';
import styled from '@emotion/styled';
import { batch, useDispatch, useSelector } from 'react-redux';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import {
  fetchSubscriptionPackage,
  setIsUpdatedUpgradeModalShowing,
  setIsUpgradeModalShowing,
  setSelectedPackage,
} from '../../ducks/sportsScienceActions';
import { subscriptionFlowTracker } from '../../../../shared/utils/amplitudeHelper';

const CardWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  align-items: flex-start;
  justify-content: center;
  width: 344px;
  min-width: 260px;
  height: ${(props) => (props.hasButton ? '350px' : '300px')};
  cursor: pointer;
  box-shadow: 0px 1px 8px 0px rgba(8, 5, 10, 0.1);
  position: relative;
  background-color: #ffffff;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    padding: 4px;
    background: ${(props) => props.gradient};
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    transition: background 0.3s ease;
  }

  &:hover::after {
    background: rgba(0, 0, 255, 0.2);
  }
`;

const CardImage = styled('div')`
  display: flex;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  width: 100%;
  background: url(${(props) => props.bgImage}) lightgray 50% / cover no-repeat;
  background-position: center;
  height: 200px;
  opacity: 0.8;
`;

const InnerContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background: white;
  width: 100%;
  padding: 20px;
  gap: 12px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  height: ${(props) => (props.hasButton ? '175px' : '125px')};
  max-height: ${(props) => (props.hasButton ? '175px' : '125px')};
`;

const CardText = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: white;
  width: 100%;
  gap: 12px;
`;

const CardHeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const CardHeader = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10000px;
  padding: 8px 12px;
  gap: 8px;
  background: #eeeeee;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: -0.32px;
`;

const ModuleIcon = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModuleTitle = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
`;

const ModuleSubtitle = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: '#9e9e9e';
  font-family: 'Nunito Sans';
  font-size: 11px;
  line-height: 14px;
`;

const CardDescription = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const PurchaseButton = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  padding: 12px 20px;
  width: 100%;
  border-radius: 10000px;
  border: none;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.12);
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.96px;
  text-transform: uppercase;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(270deg, #6a82fb 0%, #fc5c7d 100%);
`;

const ModuleCardLocked = ({
  cardData,
  purchaseButton = false,
  gradient = '',
}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const {
    addOnId,
    backgroundImage,
    icon,
    title,
    legacyTitle,
    description,
  } = cardData;

  return (
    <CardWrapper
      bgImage={backgroundImage}
      hasButton={purchaseButton}
      gradient={gradient}
      onClick={() => {
        subscriptionFlowTracker(
          'Modal Viewed',
          cardData.title,
          'Sport Science Hub Landing Page',
        );
        batch(() => {
          dispatch(
            title === 'Body Heat Map' || title === 'Habit Tracker'
              ? setIsUpdatedUpgradeModalShowing(true)
              : setIsUpgradeModalShowing(true),
          );
          dispatch(setSelectedPackage(cardData));
          dispatch(
            fetchSubscriptionPackage(
              currentUser.accountCode,
              1,
              addOnId || null,
            ),
          );
        });
      }}
    >
      <CardImage bgImage={backgroundImage} />
      <InnerContent hasButton={purchaseButton}>
        <CardText>
          <CardHeaderContainer>
            <CardHeader>
              <ModuleIcon>
                <IcomoonReact
                  iconSet={iconSet}
                  color='#444444'
                  size={20}
                  icon={icon}
                />
              </ModuleIcon>
              <ModuleTitle>{title}</ModuleTitle>
            </CardHeader>
            {legacyTitle && (
              <ModuleSubtitle>
                (Formerly
                {legacyTitle}
                )
              </ModuleSubtitle>
            )}
          </CardHeaderContainer>
          <CardDescription>{description}</CardDescription>
        </CardText>
        {purchaseButton && (
          <PurchaseButton
            onClick={() => {
              subscriptionFlowTracker(
                'Modal Viewed',
                'none',
                'Sport Science Hub Landing Page',
              );
              batch(() => {
                dispatch(setIsUpgradeModalShowing(true));
                dispatch(setSelectedPackage(cardData));
                dispatch(
                  fetchSubscriptionPackage(
                    currentUser.accountCode,
                    1,
                    addOnId || null,
                  ),
                );
              });
            }}
          >
            PURCHASE ADD ON
          </PurchaseButton>
        )}
      </InnerContent>
    </CardWrapper>
  );
};

ModuleCardLocked.propTypes = {
  cardData: PropTypes.instanceOf(Object).isRequired,
  purchaseButton: PropTypes.bool,
  gradient: PropTypes.string,
};

ModuleCardLocked.defaultProps = {
  purchaseButton: false,
  gradient: '',
};

export default ModuleCardLocked;
